import React, { useEffect, useState } from 'react';
import {
  useForm,
  useWatch,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { errorHandler } from '../utils';
import {
  GoodBadRadioGroup,
  Loading,
  LoadingBackdrop,
  RadioControl,
  SelectControl,
  YesNoRadioGroup,
} from 'components/MaterialUI';
import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import TrimSelector from 'modules/inventory/components/vehicle/vehicle_info/TrimSelector';
import useYearMakeModel from 'components/hooks/useYearMakeModel';
import useDecode from 'components/hooks/useDecode';

const GridRow = ({ children, alignItems = 'center', ...rest }) => (
  <Grid container alignItems={alignItems} spacing={2} {...rest}>
    {children}
  </Grid>
);

const SelectGridItem = ({
  label,
  name,
  options,
  xs = 4,
  optionValueKey = 'id',
  ...rest
}) => (
  <Grid item xs={xs}>
    <SelectControl
      control={useFormContext().control}
      fullWidth
      label={label}
      options={options}
      optionValueKey={optionValueKey}
      name={name}
      size="small"
      error={Boolean(useFormContext().formState.errors[name])}
      helperText={useFormContext().formState.errors[name]?.message}
      {...rest}
    />
  </Grid>
);

const TextGridItem = ({ label, name, xs = 4, ...rest }) => (
  <Grid item xs={xs}>
    <TextField
      control={useFormContext().control}
      fullWidth
      label={label}
      size="small"
      error={Boolean(useFormContext().formState.errors[name])}
      helperText={useFormContext().formState.errors[name]?.message}
      {...(name ? useFormContext().register(name) : {})}
      {...rest}
    />
  </Grid>
);

const RadioGridItem = ({ label, name, xs = 2, ...rest }) => (
  <Grid item xs={xs}>
    <RadioControl
      FormControlProps={{ sx: { margin: '8px' } }}
      control={useFormContext().control}
      fullWidth
      label={label}
      name={name}
      errors={useFormContext().formState.errors}
      {...rest}
    />
  </Grid>
);

const EditCustomerOfferedAppraisalDialog = ({
  appraisal,
  errors,
  onClose,
  onSave,
  snackIt,
}) => {
  const [showTrimSelect, setShowTrimSelect] = useState(false);
  const {
    codeTables: {
      bodyTypes,
      driveTypes,
      engineCompressors,
      engineConfigs,
      exteriorColours,
      fuelTypes,
      interiorColours,
      transmissions,
    },
    loading: codeTablesLoading,
    error: codeTablesError,
  } = useCodeTablesContext();
  const {
    contactEmail,
    contactName,
    contactPhone,
    customerAskingPrice,
    customerMotive,
    engineCylinders,
    engineLitres,
    greaseBodyTypeId,
    greaseDriveTypeId,
    greaseEngineCompressorId,
    greaseEngineConfigId,
    greaseExteriorColourId,
    greaseFuelTypeId,
    greaseInteriorColourId,
    greaseMakeId,
    greaseModelId,
    greaseTransmissionId,
    hasAccidents,
    hasEnhancements,
    hasLien,
    hasOpi,
    isGlassGood,
    isTiresGood,
    notes,
    odometer,
    requiresRepairs,
    styleId,
    trim,
    trimVariation,
    value,
    vin,
    year,
  } = appraisal ?? {};
  const defaultValues = {
    contactEmail,
    contactName,
    contactPhone,
    engineCylinders,
    engineLitres,
    greaseBodyTypeId,
    greaseDriveTypeId,
    greaseEngineCompressorId,
    greaseEngineConfigId,
    greaseExteriorColourId,
    greaseFuelTypeId,
    greaseInteriorColourId,
    greaseMakeId,
    greaseModelId,
    greaseTransmissionId,
    hasAccidents,
    hasEnhancements,
    hasLien,
    hasOpi,
    isGlassGood,
    isTiresGood,
    notes,
    odometer,
    requiresRepairs,
    styleId,
    trim,
    trimVariation,
    value,
    vin,
    year,
  };

  const formMethods = useForm({ defaultValues });
  const {
    formState: { isDirty },
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [appraisal]);

  const {
    decode,
    loading: decoding,
    error: decodeError,
    data: decodeData,
  } = useDecode();

  const selectedMake = useWatch({ control, name: 'greaseMakeId' });
  const selectedYear = useWatch({ control, name: 'year' });
  const selectedStyleId = useWatch({ control, name: 'styleId' });
  const selectedVin = useWatch({ control, name: 'vin' });

  const setFromStyle = style => {
    setValue('styleId', style.style_id);
    setValue('engineCylinders', style.engines?.[0]?.engine_cylinders);
    setValue('engineLitres', parseFloat(style.engines?.[0]?.engine_litres));
    setValue('greaseBodyTypeId', style.body_type_id);
    setValue('greaseDriveTypeId', style.drive_type_id);
    setValue(
      'greaseEngineCompressorId',
      style.engines?.[0]?.engine_compressor_id,
    );
    setValue('greaseEngineConfigId', style.engines?.[0]?.engine_config_id);
    setValue('greaseFuelTypeId', style.engines?.[0]?.fuel_type_id);
    setValue('greaseMakeId', style.make_id);
    setValue('greaseModelId', style.model_id);
    setValue('greaseTransmissionId', style.transmissions[0].id);
    setValue('trim', style.trim);
    setValue('trimVariation', style.trim_variation);
    setValue('year', style.year);
  };
  useEffect(() => {
    setMakeId(selectedMake);
  }, [selectedMake]);

  useEffect(() => {
    setYear(selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    if (decodeData?.styles?.length === 1) {
      setFromStyle(decodeData.styles[0]);
    } else if (decodeData?.styles?.length > 1) {
      setShowTrimSelect(true);
    }
  }, [decodeData]);

  useEffect(() => {
    if (errors) errorHandler(snackIt, setError)(errors);
  }, [errors]);

  const {
    makes,
    models,
    years,
    setYear,
    setMakeId,
    loading: ymmLoading,
  } = useYearMakeModel({
    selectedYear,
    selectedMake,
  });

  const onSubmit = data => {
    data.bodyType =
      bodyTypes.find(x => x.id === data.greaseBodyTypeId)?.name ?? '';
    data.driveType =
      driveTypes.find(x => x.id === data.greaseDriveTypeId)?.name ?? '';
    data.engineCompressor =
      engineCompressors.find(x => x.id === data.greaseEngineCompressorId)
        ?.name ?? '';
    data.engineConfig =
      engineConfigs.find(x => x.id === data.greaseEngineConfigId)?.name ?? '';
    data.exteriorColour =
      exteriorColours.find(x => x.id === data.greaseExteriorColourId)?.name ??
      '';
    data.fuelType =
      fuelTypes.find(x => x.id === data.greaseFuelTypeId)?.name ?? '';
    data.interiorColour =
      interiorColours.find(x => x.id === data.greaseInteriorColourId)?.name ??
      '';
    data.make = makes.find(x => x.id === data.greaseMakeId)?.name ?? '';
    data.model = models.find(x => x.id === data.greaseModelId)?.name ?? '';
    data.transmission =
      transmissions.find(x => x.id === data.greaseTransmissionId)?.name ?? '';

    onSave(data);
  };

  const handleClose = () =>
    (!isDirty ||
      window.confirm(
        'You have unsaved changes.  Are you sure you want to close?',
      )) &&
    onClose();

  const handleStyleSelect = style => {
    setShowTrimSelect(false);
    setFromStyle(style);
  };
  const decodeButtonLabel = selectedStyleId ? 'Re-Decode' : 'Decode';
  const yearsOpts = years?.map(x => ({ id: x, name: x })) ?? [];

  if (!appraisal) return null;
  if (codeTablesLoading) return <Loading text="Loading code tables" />;

  return (
    <Dialog
      open={Boolean(appraisal)}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <FormProvider {...formMethods}>
        <LoadingBackdrop open={decoding}>Decoding VIN</LoadingBackdrop>
        <LoadingBackdrop open={ymmLoading}>
          Fetching Years/Makes/Models
        </LoadingBackdrop>
        <Dialog open={showTrimSelect}>
          <TrimSelector
            decodeStyles={decodeData?.styles}
            selectedStyleId={selectedStyleId}
            handleStyleSelect={handleStyleSelect}
          />
        </Dialog>
        <DialogTitle>Edit Appraisal {appraisal.id}</DialogTitle>
        {/* Note that this doesn't clear the errors so if we get a generic kind of error (like a server 
        error or something), the submit button won't work without refreshing.  Tried to use clearErrors, but
        it didn't work. */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box mb={2}>
              <Typography variant="h6">Contact Information</Typography>
              <GridRow>
                <TextGridItem label="Name" name="contactName" xs={4} />
                <TextGridItem label="Email" name="contactEmail" xs={4} />
                <TextGridItem label="Phone" name="contactPhone" xs={4} />
              </GridRow>
            </Box>

            <Box mb={2}>
              <Typography variant="h6">VIN</Typography>
              <GridRow alignItems="center">
                <TextGridItem label="VIN" name="vin" xs={3} />
                <Grid item xs={3}>
                  <Button
                    onClick={() => decode({ variables: { vin: selectedVin } })}
                    variant="contained"
                    color="secondary"
                    disabled={decoding}
                  >
                    {decodeButtonLabel}
                  </Button>
                </Grid>
                <Grid item xs={2} />
                <TextGridItem disabled label="Style ID" name="styleId" xs={4} />
              </GridRow>
            </Box>

            <Box mb={2}>
              <Typography variant="h6">Year/Make/Model/Trim</Typography>
              <GridRow>
                <SelectGridItem label="Year" name="year" options={yearsOpts} />
                <SelectGridItem
                  label="Make"
                  name="greaseMakeId"
                  options={makes}
                />
                <SelectGridItem
                  label="Model"
                  name="greaseModelId"
                  options={models}
                />
              </GridRow>
              <GridRow>
                <TextGridItem label="Trim" name="trim" xs={2} />
                <Grid item xs={2}>
                  <Button
                    onClick={() => setShowTrimSelect(true)}
                    color="secondary"
                    variant="contained"
                    disabled={!decodeData || decodeData?.styles?.length < 2}
                  >
                    Select Trim
                  </Button>
                </Grid>
                <TextGridItem
                  label="Trim Variation"
                  name="trimVariation"
                  xs={4}
                />
              </GridRow>
            </Box>

            <Box mb={2}>
              <Typography variant="h6">Body & Colour</Typography>
              <GridRow>
                <SelectGridItem
                  label="Body Type"
                  name="greaseBodyTypeId"
                  options={bodyTypes}
                />
                <SelectGridItem
                  label="Exterior Colour"
                  name="greaseExteriorColourId"
                  options={exteriorColours}
                />
                <SelectGridItem
                  label="Interior Colour"
                  name="greaseInteriorColourId"
                  options={interiorColours}
                />
              </GridRow>
            </Box>

            <Box mb={2}>
              <Typography variant="h6">Vehicle Details</Typography>
              <GridRow>
                <TextGridItem label="Odometer" name="odometer" />
                <SelectGridItem
                  label="Drive Type"
                  name="greaseDriveTypeId"
                  options={driveTypes}
                />
                <SelectGridItem
                  label="Transmission"
                  name="greaseTransmissionId"
                  options={transmissions}
                />
              </GridRow>
              <GridRow>
                <TextGridItem label="Engine Litres" name="engineLitres" />
                <SelectGridItem
                  label="Engine Config"
                  name="greaseEngineConfigId"
                  options={engineConfigs}
                />
                <TextGridItem label="Engine Cylinders" name="engineCylinders" />
              </GridRow>
              <GridRow>
                <SelectGridItem
                  label="Engine Compressor"
                  name="greaseEngineCompressorId"
                  options={engineCompressors}
                />
                <SelectGridItem
                  label="Fuel Type"
                  name="greaseFuelTypeId"
                  options={fuelTypes}
                />
              </GridRow>
            </Box>

            <Box mb={2}>
              <Typography variant="h6">Condition</Typography>
              <GridRow>
                <RadioGridItem
                  as={<YesNoRadioGroup />}
                  label="Has Lien"
                  name="hasLien"
                />
                <RadioGridItem
                  as={<YesNoRadioGroup />}
                  label="Has OPI"
                  name="hasOpi"
                />
                <RadioGridItem
                  as={<GoodBadRadioGroup />}
                  label="Tires"
                  name="isTiresGood"
                />
                <RadioGridItem
                  as={<GoodBadRadioGroup />}
                  label="Glass"
                  name="isGlassGood"
                />
                <RadioGridItem
                  as={<YesNoRadioGroup />}
                  label="Enhancements"
                  name="hasEnhancements"
                />
                <RadioGridItem
                  as={<YesNoRadioGroup />}
                  label="Accidents"
                  name="hasAccidents"
                />
              </GridRow>
              <GridRow>
                <TextGridItem
                  label="Notes/Damage Disclosure"
                  multiline
                  name="notes"
                  rows={2}
                  xs={12}
                />
              </GridRow>
              <GridRow>
                <TextGridItem
                  disabled
                  label="Trade/Sell"
                  name="customerMotive"
                  value={customerMotive}
                  InputLabelProps={{ shrink: true }}
                />
                <TextGridItem
                  disabled
                  InputProps={{ startAdornment: '$' }}
                  label="Asking Price"
                  type="number"
                  value={customerAskingPrice}
                />
                <TextGridItem
                  InputProps={{ startAdornment: '$' }}
                  label="Value"
                  name="value"
                  type="number"
                />
              </GridRow>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

EditCustomerOfferedAppraisalDialog.fragments = {
  appraisal: `
    fragment EditCustomerOfferedAppraisalDialogAppraisal on Appraisal {
      id
      contactEmail,
      contactName,
      contactPhone,
      customerMotive,
      customerAskingPrice,
      engineCylinders,
      engineLitres,
      greaseBodyTypeId,
      greaseDriveTypeId,
      greaseEngineCompressorId,
      greaseEngineConfigId,
      greaseExteriorColourId,
      greaseFuelTypeId,
      greaseInteriorColourId,
      greaseMakeId,
      greaseModelId,
      greaseTransmissionId,
      hasAccidents,
      hasEnhancements,
      hasLien,
      hasOpi,
      isGlassGood,
      isTiresGood,
      notes,
      odometer,
      requiresRepairs,
      styleId,
      trim,
      trimVariation,
      value,
      vin,
      year,
    }
  `,
};

export default EditCustomerOfferedAppraisalDialog;
