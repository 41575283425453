import React, { useEffect, useState } from 'react';

/* external */
import { isEmpty, round, startCase } from 'lodash';
import { useForm, FormProvider, useWatch } from 'react-hook-form';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

/* internal */
import {
  CheckboxControl,
  RadioControl,
  RenderableRadioGroup,
  TextFieldControl,
  SelectControl,
} from 'components/MaterialUI';
import { Finance, formatPrice } from 'utils';
import {
  PAYMENT_TYPE,
  PAYMENT_TYPES,
  FREQUENCY_NAMES,
  FREQUENCY_OPTIONS,
  TERM_OPTIONS,
} from 'modules/pitches/const';

const { FINANCE, LEASE, CASH } = PAYMENT_TYPE;

const DEFAULT_TERM = 48;
const DEFAULT_FREQUENCY = 12;
const DEFAULT_RATE = 0;
const DEFAULT_CASH_DOWN = 0;
const DEFAULT_RESIDUAL_AMOUNT = 0;
const DEFAULT_PAYMENT_TYPE = 'finance';
const DEFAULT_DUE_AT_DELIVERY = 0;
const DEFAULT_ALLOWED_MILEAGE = 0;
const DEFAULT_CASH_AMOUNT = 0;

const PaymentOptionDialog = ({
  onClose,
  onConfirm,
  open,
  paymentOption,
  salePrice,
  ...rest
}) => {
  // If we're going to allow editing, we'll need to change this so that we
  // check the current paymentOption.amount with the calculated amount from the
  // rest of the paymentOption data.  If they match (within 0.01), then we can
  // default autoRecalculate to true, otherwise false.
  const [autoRecalculate, setAutoRecalculate] = useState(true);
  const [paymentHelperText, setPaymentHelperText] = useState(null);

  let _defaultValues = {
    allowedMileage: DEFAULT_ALLOWED_MILEAGE,
    cashAmount: salePrice ?? DEFAULT_CASH_AMOUNT,
    cashDown: DEFAULT_CASH_DOWN,
    dueAtDelivery: DEFAULT_DUE_AT_DELIVERY,
    frequency: DEFAULT_FREQUENCY,
    hideRate: false,
    paymentType: DEFAULT_PAYMENT_TYPE,
    rate: DEFAULT_RATE,
    residualAmount: DEFAULT_RESIDUAL_AMOUNT,
    term: DEFAULT_TERM,
  };

  _defaultValues.amount = round(
    Finance.payment(
      salePrice,
      DEFAULT_RATE / 12.0,
      (DEFAULT_TERM / 12.0) * DEFAULT_FREQUENCY,
      0,
      0,
      DEFAULT_FREQUENCY,
    ),
    2,
  );

  const defaultValues = !isEmpty(paymentOption)
    ? paymentOption
    : _defaultValues;

  // Note that this is a subForm within the existing pitch form context.
  // Submitting _this_ form will add the payment option to the pitch form.

  const formMethods = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { control, handleSubmit, register, reset, setValue } = formMethods;

  const handleClose = e => {
    e.stopPropagation();
    onClose();
  };

  const onSubmit = data => {
    onConfirm(data);
    onClose();
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, paymentOption]);

  // Disable submission on enter here, since it will cause the parent form to
  // also submit.  I tried overriding onSubmit as such:
  // onSubmit={e => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   handleSubmit(onSubmit)(e);
  // }}
  // For some reason it ONLY submitted this nested form when pressing
  // ENTER in the cashAmount field, but no others.
  onkeypress = e =>
    e.key === 'Enter' ? e.preventDefault() && e.stopPropagation() : null;

  const paymentType = useWatch({
    control,
    name: 'paymentType',
    defaultValue: paymentOption?.paymentType ?? FINANCE,
  });
  register('price');

  const frequency = useWatch({
    control,
    name: 'frequency',
    defaultValue: paymentOption?.frequency ?? DEFAULT_FREQUENCY,
  });

  const term = useWatch({
    control,
    name: 'term',
    defaultValue: paymentOption?.term ?? DEFAULT_TERM,
  });

  const rate = useWatch({
    control,
    name: 'rate',
    defaultValue: paymentOption?.rate ?? DEFAULT_RATE,
  });

  const cashDown = useWatch({
    control,
    name: 'cashDown',
    defaultValue: paymentOption?.cashDown ?? DEFAULT_CASH_DOWN,
  });

  const residualAmount = useWatch({
    control,
    name: 'residualAmount',
    defaultValue: paymentOption?.residualAmount ?? DEFAULT_RESIDUAL_AMOUNT,
  });

  const amount = useWatch({
    control,
    name: 'amount',
    defaultValue: paymentOption?.amount ?? 0,
  });

  const cashAmount = useWatch({
    control,
    name: 'cashAmount',
    defaultValue: paymentOption?.cashAmount ?? salePrice ?? DEFAULT_CASH_AMOUNT,
  });

  const price = useWatch({
    control,
    name: 'price',
  });

  useEffect(() => {
    if (price !== salePrice) setValue('price', salePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, salePrice]);

  const financeOrLease = paymentType === FINANCE || paymentType === LEASE;

  const showFrequency = financeOrLease;
  const showTerm = financeOrLease;
  const showRate = financeOrLease;
  const showDown = financeOrLease;
  const showResidual = paymentType === LEASE;
  const showDueAtDelivery = paymentType === LEASE;
  const showMileageAllowance = paymentType === LEASE;
  const showCashAmount = paymentType === CASH;

  useEffect(() => {
    let newValue;
    if (open) {
      setPaymentHelperText(null);
      if (paymentType === FINANCE) {
        newValue = Finance.payment(
          salePrice - cashDown ?? 0,
          rate / 100.0,
          (term / 12.0) * frequency,
          0,
          0,
          frequency,
        );
      } else if (paymentType === LEASE) {
        newValue = Finance.leasePayment(
          salePrice - cashDown ?? 0,
          rate / 100.0,
          term,
          residualAmount ?? 0,
          frequency,
        );
      } else if (paymentType === CASH) {
        newValue = cashAmount;
      }
      newValue = round(newValue, 2);
      newValue = newValue.toFixed(2);
      if (amount !== newValue) {
        if (autoRecalculate)
          setValue('amount', newValue, { shouldDirty: true });
        else
          setPaymentHelperText(
            `Calculated payment: ${(formatPrice(newValue), { cents: true })}`,
          );
      }
    }
  }, [
    autoRecalculate,
    cashAmount,
    cashDown,
    amount,
    open,
    paymentType,
    rate,
    residualAmount,
    term,
    frequency,
    setValue,
    salePrice,
  ]);

  const PaymentTypeRadioGroup = props => (
    <RenderableRadioGroup
      {...props}
      options={PAYMENT_TYPES.map(paymentType => ({
        value: paymentType,
        name: startCase(paymentType),
      }))}
    />
  );

  return (
    <Dialog open={open} maxWidth="xs" fullWidth {...rest}>
      <DialogTitle style={{ fontWeight: 'bold' }}>
        <Typography style={{ fontWeight: 'bold' }} variant="h5">
          Add a payment option:
        </Typography>
      </DialogTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ paddingTop: 0 }}>
            <input {...register('id')} type="hidden" />
            <input {...register('index')} type="hidden" />

            <RadioControl name="paymentType" as={<PaymentTypeRadioGroup />} />

            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {startCase(paymentType)}
            </Typography>
            <Grid container spacing={2}>
              {showFrequency && (
                <Grid item xs={6}>
                  <SelectControl
                    optionValueKey={0}
                    optionNameKey={1}
                    options={FREQUENCY_OPTIONS}
                    name="frequency"
                    control={control}
                    fullWidth
                    label="Payment Schedule"
                  />
                </Grid>
              )}
              {showTerm && (
                <Grid item xs={6}>
                  <SelectControl
                    optionValueKey={0}
                    optionNameKey={1}
                    options={TERM_OPTIONS}
                    name="term"
                    control={control}
                    fullWidth
                    label="Term in Months"
                  />
                </Grid>
              )}
              {showRate && (
                <Grid item xs={6}>
                  <TextFieldControl
                    variant="percentRate"
                    label="Interest Rate"
                    name="rate"
                    control={control}
                  />
                </Grid>
              )}

              {showDown && (
                <Grid item xs={6}>
                  <TextFieldControl
                    variant="currency"
                    control={control}
                    label="Down Payment"
                    name="cashDown"
                  />
                </Grid>
              )}

              {showResidual && (
                <Grid item xs={6}>
                  <TextFieldControl
                    type="number"
                    control={control}
                    inputProps={{ step: '0.01' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    label="Residual"
                    name="residualAmount"
                  />
                </Grid>
              )}

              {showDueAtDelivery && (
                <Grid item xs={6}>
                  <TextFieldControl
                    type="number"
                    inputProps={{ step: '0.01' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    label="Due at Delivery"
                    name="dueAtDelivery"
                    control={control}
                  />
                </Grid>
              )}

              {showMileageAllowance && (
                <Grid item xs={6}>
                  <TextFieldControl
                    type="number"
                    label="Mileage Allowance (km/yr)"
                    name="allowedMileage"
                    /* Set width to 133% to compensate for 0.75 scale factor
                     for shrink */
                    InputLabelProps={{ style: { width: '133%' } }}
                    control={control}
                  />
                </Grid>
              )}

              {showCashAmount && (
                <Grid item xs={6}>
                  <TextFieldControl
                    type="number"
                    control={control}
                    inputProps={{ step: '0.01' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    label="Payment Amount"
                    name="cashAmount"
                  />
                </Grid>
              )}

              {showRate && (
                <Grid item xs={6}>
                  <CheckboxControl
                    control={control}
                    label="Hide Rate"
                    name={'hideRate'}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Box fontSize="16px" margin={1}>
                  Price: {formatPrice(price, { cents: true })}
                </Box>
                <Box display={paymentType === CASH ? 'none' : 'flex'}>
                  <TextFieldControl
                    FormHelperTextProps={{
                      style: {
                        color: 'blue',
                      },
                    }}
                    helperText={paymentHelperText}
                    type="number"
                    control={control}
                    inputProps={{ step: '0.01' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    label={`${FREQUENCY_NAMES[frequency]} Payment`}
                    name="amount"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={autoRecalculate}
                        onChange={e => setAutoRecalculate(e.target.checked)}
                      />
                    }
                    label="Auto Recalculate"
                  />
                </Box>

                {paymentType === CASH && (
                  <Box fontSize="16px" margin={1} pb={5}>
                    Total Payment:
                    <Box
                      component="span"
                      fontSize="20px"
                      fontWeight="bold"
                      paddingLeft="1em"
                    >
                      {formatPrice(cashAmount, { cents: true })}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#e8e8e8' }}>
            <Button onClick={handleClose} style={{ color: 'blue' }}>
              Cancel
            </Button>
            <Button style={{ color: 'blue' }} onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default PaymentOptionDialog;
