import React from 'react';

/* external */
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useForm, useWatch } from 'react-hook-form';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* internal */
import { AutocompleteControl, SelectControl } from 'components/MaterialUI';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role, URL } from 'constants.js';

const generatePhotoPreview = photoId =>
  `${URL.photoService}/image/show/72x72/${photoId}.jpg`;

const FRAGMENT = gql`
  fragment AssignedSalespeopleCardFragment on Customer {
    assigned_salespeople {
      dealer_id
      dealer {
        dealer_id
        dealer_name
      }
      user {
        profile {
          image_id
        }
      }
      username
    }
  }
`;
const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
      ]
    ) {
      display_name
      username
      role
      profile {
        image_id
      }
    }
  }
`;

const ASSIGN_SALESPERSON = gql`
  mutation assignSalesperson($_id: ID!, $input: AssignSalespersonInput!) {
    assignSalespersonToCustomer(_id: $_id, input: $input) {
      _id
      ...AssignedSalespeopleCardFragment
    }
  }
  ${FRAGMENT}
`;

const UNASSIGN_SALESPERSON = gql`
  mutation unassignSalesperson($_id: ID!, $dealer_id: ID!) {
    unassignSalespersonFromCustomer(_id: $_id, dealer_id: $dealer_id) {
      _id
      ...AssignedSalespeopleCardFragment
    }
  }
  ${FRAGMENT}
`;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
  sizeAvatar: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
}));

const UsernameAutocomplete = ({ control, dealerId }) => {
  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [dealerId],
    },
  });
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <AutocompleteControl
      control={control}
      forcePopupIcon
      label="Username"
      name="username"
      options={userData?.users ?? []}
      optionValueKey="username"
      optionLabelKey="display_name"
      readOnly={!dealerId}
      required
      style={{ width: desktop ? 300 : 250 }}
    />
  );
};

const AssignSalespersonModal = ({ customer, enqueueSnackbar }) => {
  const { currentUser } = useUserContext() || {};
  const { dealers, dealerId } = useDealerContext();
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const theme = useTheme();

  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const { control, handleSubmit } = useForm();
  const dealer_id = useWatch({ control, name: 'dealer_id' });
  const [open, setOpen] = React.useState(false);
  const [assignSalesperson] = useMutation(ASSIGN_SALESPERSON, {
    onCompleted: () => {
      enqueueSnackbar(`Successfully assigned salesperson`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error in assigning salesperson`, {
        variant: 'error',
      }),
  });
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      callback(onSubmit);
    }
  };

  const onSubmit = input => {
    assignSalesperson({
      variables: {
        _id: customer._id,
        input,
      },
    }).then(handleClose);
  };

  return (
    <Box>
      {!isHidden && (
        <IconButton
          size="small"
          onClick={e => {
            e.stopPropagation();
            handleOpen();
          }}
        >
          <AddIcon style={theme.actions.add} />
        </IconButton>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add salesperson to customer</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={e => {
            handleKeyDown(e, handleSubmit);
          }}
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
        >
          <DialogContent>
            <SelectControl
              fullWidth
              noNull
              defaultValue={dealerId ?? dealers?.[0]} // default to dealerId from dealerContext
              control={control}
              options={dealers}
              required
              label="Dealer"
              name="dealer_id"
              optionNameKey="dealer_name"
              optionValueKey="dealer_id"
              style={{ width: desktop ? 300 : 250 }}
            />
            <UsernameAutocomplete
              control={control}
              dealerId={dealer_id ?? dealerId}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

const AssignedSalespeopleHeader = ({ customer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography className={classes.title} gutterBottom>
            Assigned Salespeople
          </Typography>
        </Grid>
        <Grid item>
          <AssignSalespersonModal
            customer={customer}
            enqueueSnackbar={enqueueSnackbar}
          />
        </Grid>
      </Grid>
      {customer.assigned_salespeople?.length === 0 &&
        'There are no assigned salesperson for this customer.'}
    </>
  );
};

const AssignedSalesPeopleList = ({
  customer,
  unassignSalesperson,
  isHidden,
}) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));
  return (
    <List
      style={{
        width: '100%',
        position: 'relative',
        overflowX: 'hidden',
        maxHeight: 200,
        paddingTop: desktop ? 10 : 0,
      }}
    >
      <Grid container spacing={2}>
        {customer.assigned_salespeople.map(
          ({ username, user, dealer, dealer_id }) =>
            username ? (
              <Grid item xs={medium ? 12 : desktop ? 6 : 12} key={username}>
                <ListItem>
                  <ListItemAvatar>
                    {
                      <Avatar
                        className={classes.sizeAvatar}
                        src={
                          user?.profile?.image_id
                            ? generatePhotoPreview(user.profile.image_id)
                            : null
                        }
                      >
                        {(user?.display_name ?? username)
                          ?.split(' ')
                          .map(x => x.slice(0, 1))
                          .join('') ?? ''}
                      </Avatar>
                    }
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      style: { fontSize: 14, fontWeight: 'normal' },
                    }}
                    primary={user?.display_name ?? username}
                    secondary={dealer?.dealer_name}
                  />
                  {!isHidden && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        unassignSalesperson({
                          variables: {
                            _id: customer._id,
                            dealer_id,
                          },
                        })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItem>
              </Grid>
            ) : null,
        )}
      </Grid>
    </List>
  );
};

const AssignedSalespeopleCard = ({ customer }) => {
  const { currentUser } = useUserContext() || {};
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [unassignSalesperson] = useMutation(UNASSIGN_SALESPERSON, {
    onCompleted: () => {
      enqueueSnackbar(`Successfully unassigned salesperson`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error in unassigning salesperson`, {
        variant: 'error',
      }),
  });

  return (
    <>
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <AssignedSalespeopleHeader customer={customer} />
            <AssignedSalesPeopleList
              customer={customer}
              unassignSalesperson={unassignSalesperson}
              isHidden={isHidden}
            />
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <AssignedSalespeopleHeader customer={customer} />
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <AssignedSalesPeopleList
              customer={customer}
              unassignSalesperson={unassignSalesperson}
              isHidden={isHidden}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

AssignedSalespeopleCard.fragments = {
  customer: FRAGMENT,
};

export default AssignedSalespeopleCard;
