/* External */
import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';

/* Material UI */
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Loading,
  RadioControl,
  RenderableRadioGroup,
  TextFieldControl,
} from 'components/MaterialUI';

/* Internal */
import { UPDATE_SETTINGS } from './GeneralSettings';
import { snackBarSettings } from 'constants.js';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  titleLine: {
    borderBottom: '1px solid' + theme.colours.greys.grey3,
    marginBottom: '15px',
  },
  textField: {
    width: '90%',
    margin: '5px 0',
  },
  settingsContainer: {
    width: '80%',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  blackoutLabel: {
    fontWeight: 600,
    margin: '5px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  saveButton: {
    width: '100%',
    backgroundColor: theme.actions.confirm.backgroundColor,
    color: theme.actions.confirm.color,
  },
}));

const EnableCASLRadioGroup = props => (
  <RenderableRadioGroup
    {...props}
    options={[
      { name: 'Enabled', value: 'true' },
      { name: 'Disabled', value: 'false' },
    ]}
  />
);

const CASLCompliance = ({ gateway }) => {
  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  // makes it less of a PITA to grab the data in the fields below
  const gatewayData = gateway?.sms.gateway;
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
    onCompleted: () => {
      snackIt('Updated successfully!', {
        ...snackBarSettings,
        variant: 'success',
      });
    },
    onError: error => {
      snackIt(error.message, {
        ...snackBarSettings,
        variant: 'error',
      });
    },
  });

  // RHF Stuff
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      enable_casl: gatewayData.enable_casl,
      casl_stop_word: gatewayData.casl_stop_word,
      casl_append: gatewayData.casl_append,
    },
  });

  const onSubmit = settings => {
    const { enable_casl, ...rest } = settings;
    updateSettings({
      variables: {
        gatewayId: gatewayData.id,
        settings: {
          enable_casl: enable_casl === 'true',
          ...rest,
        },
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <Box>
      <Box className={classes.titleLine}>
        <Typography className={classes.h6}>CASL Compliance</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className={classes.settingsContainer}>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <RadioControl
              control={control}
              name="enable_casl"
              as={<EnableCASLRadioGroup />}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldControl
              control={control}
              name="casl_stop_word"
              label="Stop Word"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldControl
              control={control}
              name="casl_append"
              label="CASL Message"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={mobile ? 'center' : 'flex-end'}
            alignItems="center"
            spacing={mobile ? 2 : 4}
            wrap="wrap"
            style={{ marginTop: '25px' }}
          >
            <Grid item xs={12} sm={5} md={4} xl={2}>
              <Button
                variant="outlined"
                style={{ width: '100%' }}
                onClick={() => reset()}
              >
                Discard Changes
              </Button>
            </Grid>
            <Grid item xs={12} sm={5} md={4} xl={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={!isDirty}
                className={classes.saveButton}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

CASLCompliance.fragments = {
  gateway: gql`
    fragment CASLComplianceGatewayData on Gateway {
      enable_casl
      casl_stop_word
      casl_append
    }
  `,
};

export default CASLCompliance;
