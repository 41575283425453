import React from 'react';

import gql from 'graphql-tag';
import { capitalize } from 'lodash';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import { StaticDateTimePicker } from '@mui/x-date-pickers';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';

import {
  AutocompleteControl,
  DealerPicker,
  SelectControl,
  TextFieldControl,
} from 'components/MaterialUI';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role } from 'constants.js';

const TaskStatusType = ['email', 'call', 'other', 'opportunity'];

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
      ]
    ) {
      display_name
      username
      role
      profile {
        image_id
      }
    }
  }
`;

const ADD_TASK = gql`
  mutation createTask($input: TaskInput!) {
    createTask(input: $input) {
      _id
    }
  }
`;

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput) {
    createNote(input: $input) {
      _id
    }
  }
`;

const UsernameAutocomplete = ({ control, dealer_id }) => {
  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [dealer_id],
    },
  });

  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <AutocompleteControl
      control={control}
      forcePopupIcon
      label="Assigned To"
      name="assignee"
      optionLabelKey="display_name"
      options={userData?.users ?? []}
      optionValueKey="username"
      readOnly={!dealer_id}
      required
      style={{ width: desktop ? 300 : 250 }}
    />
  ); // Re-render on dealer_id change
};

const AddTaskModal = ({ open, setOpen, customerId, refetch }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();
  const { dealerId } = useDealerContext();
  const { control, handleSubmit } = useForm({});

  const handleClose = () => setOpen(false);

  const [addTask] = useMutation(ADD_TASK, {
    onCompleted: () => {
      handleClose();
      refetch();
      enqueueSnackbar('Task added', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not add task error: ${err}`, {
        variant: 'error',
      });
    },
  });
  const [addNote] = useMutation(ADD_NOTE);

  const onSubmit = data => {
    const payload = {
      input: {
        assignee: data.assignee,
        customer_id: customerId,
        due_date: data.due_date,
        description: data.description,
        type: data.type,
        organization_id: currentUser.organization_id,
        status: 0,
        dealer_id: dealerId,
      },
    };

    addTask({ variables: payload }).then(() => {
      const noteData = {
        body: data.note,
        visibility: 'store_only',
        customer_id: customerId,
        dealer_id: dealerId,
      };
      if (noteData.body) {
        addNote({
          variables: {
            input: noteData,
          },
        });
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>New Task</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container direction="row" justifyContent="space-evenly">
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
                style={{ marginBottom: '1rem' }}
              >
                <Grid item>
                  <TextFieldControl
                    label="Task"
                    name="description"
                    control={control}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue=""
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextFieldControl
                    label="Notes"
                    name="note"
                    control={control}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue=""
                    multiline
                    minRows={3}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <InputLabel shrink>Type</InputLabel>
                  <SelectControl
                    control={control}
                    defaultValue=""
                    name="type"
                    noNull
                    options={TaskStatusType.map(x => ({
                      name: capitalize(x),
                      value: x,
                    }))}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <DealerPicker style={{ width: '100%' }} variant="standard" />
                </Grid>
                <Grid item>
                  <UsernameAutocomplete
                    control={control}
                    dealer_id={dealerId}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name={'due_date'}
                defaultValue={moment()}
                render={({ field: { ref, ...field } }) => (
                  <StaticDateTimePicker
                    autoOk
                    variant="static"
                    format="YYYY-MM-dd hh:mm a"
                    {...field}
                  />
                )}
                style={{ width: '250px' }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            style={theme.actions.confirm}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTaskModal;
