import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';
import moment from 'moment-timezone';

/* Material UI */
import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

/* internal */
import {
  AppraisalStatus,
  AppraisalStatusColours,
  OfferStatus,
} from 'modules/used_vehicles/const';
import { errorHandler } from '../utils';

const CLAIM_OFFER = gql`
  mutation claimOffer($offerId: Int!) {
    appraisals {
      claimOffer(id: $offerId) {
        id
        claimedBy
        claimedByUser {
          display_name
        }
      }
    }
  }
`;

const StatusChip = ({ color, statusText, ...rest }) => {
  return (
    <Chip
      variant="outlined"
      style={{ color, borderColor: color }}
      label={statusText}
      {...rest}
    />
  );
};

const DashboardCardStatus = ({
  appraisal: {
    appraisalStatus,
    auctionItem,
    boughtNowAt,
    inTransit,
    isCustomerAppraisal,
    isQuestionsPending,
    deliveredAt,
    soldAt,
  },
  dealer, // dealer or wholesaler - still not sure if I should just split the
  // DashboardCard component into dealer and wholesaler?
  showIcon,
  showChip,
  statusChipProps = {},
  offer,
  ...rest
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { auctionId } = auctionItem ?? {};
  let statusText = appraisalStatus;
  let color = AppraisalStatusColours[appraisalStatus];
  let icon = null;
  let iconColour = 'black';
  let onClick = null;
  const customerOfferedStatus = 'CUSTOMER OFFERED';

  const [claimOffer] = useMutation(CLAIM_OFFER);

  const handleClaimOffer = e => {
    e.stopPropagation();
    claimOffer({
      variables: { offerId: offer.id },
    }).then(
      () => {
        enqueueSnackbar('Offer claimed successfully', { variant: 'success' });
      },
      err => errorHandler(enqueueSnackbar, () => {})(err),
    );
  };

  const deliveryDelayed =
    !deliveredAt &&
    soldAt &&
    moment().subtract(7, 'days').isAfter(moment(soldAt));

  if (offer?.isQuestionsPending || (dealer && isQuestionsPending)) {
    icon = 'fas fa-question-circle';
    iconColour = 'blue';
  }

  if (dealer) {
    if (appraisalStatus === AppraisalStatus.SOLD)
      if (isCustomerAppraisal) statusText = `BOUGHT FROM CUSTOMER`;
      else statusText = `BOUGHT${boughtNowAt ? ' (NOW)' : ''}`;
    if (appraisalStatus === AppraisalStatus.AUCTIONED)
      statusText = 'BOUGHT AT AUCTION';
    if (
      appraisalStatus === AppraisalStatus.PENDING_OFFER &&
      offer.offerStatus === OfferStatus.CUSTOMER_OFFERED
    )
      statusText = 'CUSTOMER OFFERED';
  } else {
    if (appraisalStatus === AppraisalStatus.PENDING_AUCTION) {
      icon = 'fas fa-gavel';
      onClick = e => {
        if (auctionId) history.push(`/auctions/${auctionId}`);
        e.stopPropagation();
        e.preventDefault();
      };
    } else if (appraisalStatus === AppraisalStatus.SOLD)
      statusText = `${appraisalStatus}${boughtNowAt ? ' (NOW)' : ''}`;
  }

  // Show delivery status if sold or auctioned
  if (
    [AppraisalStatus.SOLD, AppraisalStatus.AUCTIONED].includes(appraisalStatus)
  ) {
    if (deliveredAt) {
      icon = 'fas fa-check-circle';
      iconColour = 'mediumseagreen';
    } else {
      icon = 'fas fa-exclamation-triangle';
      if (deliveryDelayed || !dealer) iconColour = 'red';
      else iconColour = 'orange';
    }
  }

  return (
    <Box
      paddingBottom="0.5rem"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {showChip && statusText && (
          <Box>
            <StatusChip
              statusText={statusText}
              color={color}
              {...statusChipProps}
            />
          </Box>
        )}
        {offer?.offerStatus === customerOfferedStatus &&
          !offer?.claimedByUser && (
            <Button
              variant="contained"
              sx={{ backgroundColor: theme.colours.add, marginLeft: '10px' }}
              onClick={e => handleClaimOffer(e)}
            >
              Claim
            </Button>
          )}
        {offer?.claimedByUser && (
          <Typography variant="body2" sx={{ marginLeft: '10px' }}>
            Claimed by: {offer.claimedByUser.display_name}
          </Typography>
        )}
      </Box>
      {showIcon && (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {icon && (
            <Box fontSize="20px" color={iconColour || color}>
              {onClick ? (
                <IconButton onClick={onClick} size="large">
                  <i className={icon} />
                </IconButton>
              ) : (
                <i className={icon} />
              )}
            </Box>
          )}
          {inTransit &&
            [
              AppraisalStatus.OWNED,
              AppraisalStatus.SOLD,
              AppraisalStatus.AUCTIONED,
              AppraisalStatus.PENDING_OFFER,
            ].includes(appraisalStatus) && (
              /* pad if the icon is clickable to ensure the right edges line up */
              <Box paddingRight={onClick ? '12px' : ''}>
                <i
                  className="fas fa-shipping-timed"
                  style={{ fontSize: '16px', paddingRight: '5px' }}
                />
                <span style={{ fontSize: '12px' }}>In Transit</span>
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
};

// not used:
// arrived_at
// is_customer_appraisal
// offered_to_dealer_id
// purchasing_dealer_id
DashboardCardStatus.fragments = {
  appraisal: gql`
    fragment DashboardCardStatusAppraisal on Appraisal {
      appraisalStatus
      arrivedAt
      auctionItem {
        id
        auctionId
      }
      boughtNowAt
      inTransit
      isCustomerAppraisal
      isQuestionsPending
      offeredToDealerId
      purchasingDealerId
      deliveredAt
      soldAt
    }
  `,
  offer: gql`
    fragment DashboardCardStatusOffer on Offer {
      id
      claimedByUser {
        display_name
      }
      offerStatus
    }
  `,
};
export default DashboardCardStatus;
