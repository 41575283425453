import React from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material';

/* internal */
import { SelectControl } from 'components/MaterialUI';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role } from 'constants.js';

const PREFERENCES = [
  { value: 'Implied', text: 'Implied' },
  { value: 'Expressed', text: 'Expressed' },
  { value: 'Opted Out', text: 'Opted Out' },
];

const UPDATE_OPT_IN_PREFS = gql`
  mutation updateOptInPrefs($_id: ID!, $prefs: OptInPreferencesInput!) {
    updateCustomerOptInPrefs(_id: $_id, prefs: $prefs) {
      opt_in_preferences {
        global {
          text
          mail
          email
          phone
        }
      }
    }
  }
`;

const OptInPreferencesModal = ({ customer, name }) => {
  const defaultValues = {
    opt_in_preferences: {
      global: {
        text: customer.opt_in_preferences.global.text,
        mail: customer.opt_in_preferences.global.mail,
        email: customer.opt_in_preferences.global.email,
        phone: customer.opt_in_preferences.global.phone,
      },
    },
  };
  const { control, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { currentUser } = useUserContext();
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const [caslStatus, setCASLStatus] = React.useState(
    name.split('.').reduce((traversedObj, key) => traversedObj[key], customer),
  );
  const { enqueueSnackbar } = useSnackbar();
  const [updateOptInPrefs] = useMutation(UPDATE_OPT_IN_PREFS);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      callback(onSubmit);
    }
  };
  const displayName = name.includes('email') ? 'Email' : 'Text';
  const checkboxControlName = 'checkbox'.concat(displayName);

  const onSubmit = data => {
    if (
      data.opt_in_preferences.global[displayName.toLowerCase()] === 'Opted Out'
    ) {
      setValue(checkboxControlName, false);
    }
    const nameShort = name.split('.')[2].toLowerCase();
    updateOptInPrefs({
      variables: {
        _id: customer._id,
        prefs: {
          [nameShort]: data.opt_in_preferences.global[nameShort],
        },
      },
    })
      .then(r => {
        enqueueSnackbar(`Successfully updated ${customer.fullname}`, {
          variant: 'success',
        });
        setCASLStatus(
          r.data.updateCustomerOptInPrefs.opt_in_preferences.global[
            displayName.toLowerCase()
          ],
        );
      })
      .catch(e => {
        enqueueSnackbar(`Error in updating ${customer.fullname}`, {
          variant: 'error',
        });
      });
    handleClose();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Controller
            control={control}
            name={checkboxControlName}
            defaultValue={['Implied', 'Expressed'].includes(
              name
                .split('.')
                .reduce((traversedObj, key) => traversedObj[key], customer),
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                color="secondary"
                disabled={isHidden}
                onBlur={onBlur}
                onChange={e => {
                  onChange(e.target.checked);
                  if (e.target.checked) {
                    handleOpen();
                  } else {
                    updateOptInPrefs({
                      variables: {
                        _id: customer._id,
                        prefs: {
                          [displayName.toLowerCase()]: 'Opted Out',
                        },
                      },
                    })
                      .then(r => {
                        enqueueSnackbar(
                          `Successfully updated ${customer.fullname}`,
                          {
                            variant: 'success',
                          },
                        );
                        setCASLStatus('Revoked');
                      })
                      .catch(e => {
                        enqueueSnackbar(
                          `Error in updating ${customer.fullname}`,
                          {
                            variant: 'error',
                          },
                        );
                      });
                  }
                }}
                checked={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <ListItemText
            primary={displayName}
            secondary={caslStatus === 'Opted Out' ? 'Revoked' : caslStatus}
          />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Marketing Preferences: {displayName}</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={e => {
            handleKeyDown(e, handleSubmit);
          }}
        >
          <DialogContent>
            <SelectControl
              control={control}
              defaultValue="Implied"
              style={{ width: 300 }}
              options={PREFERENCES}
              optionValueKey="value"
              optionNameKey="text"
              name={name}
              noNull
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

OptInPreferencesModal.fragments = {
  customer: gql`
    fragment OptInPreferencesModalFragment on Customer {
      fullname
      do_not_contact
      opt_in_preferences {
        global {
          text
          mail
          email
          phone
        }
      }
    }
  `,
};

export default OptInPreferencesModal;
