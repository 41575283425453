// External
import React, { useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';

// Material UI
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Internal
import {
  ConfirmDialog,
  SelectControl,
  TextFieldControl,
} from 'components/MaterialUI';

export const DAYOFWEEK = [
  { value: 0, text: 'Monday' },
  { value: 1, text: 'Tuesday' },
  { value: 2, text: 'Wednesday' },
  { value: 3, text: 'Thursday' },
  { value: 4, text: 'Friday' },
  { value: 5, text: 'Saturday' },
  { value: 6, text: 'Sunday' },
];

const SchedulePeriod = ({ canUpdate, timeOptions }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'schedules',
    keyName: 'key',
  });

  const [confirmRemoveItemIndex, setConfirmRemoveItemIndex] = useState(null);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>Schedule</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={isMobile ? 3 : 1}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              onClick={() =>
                append({
                  weekday: DAYOFWEEK[0].value,
                  first_slot: timeOptions[0],
                  last_slot: timeOptions[0],
                  appointments_per_slot: 1,
                  max_appointments: 1,
                })
              }
              style={canUpdate ? theme.actions.confirm : theme.actions.disabled}
              disabled={!canUpdate}
            >
              Add Schedule
            </Button>
          </Grid>
          {fields.map((schedule, index) => (
            <Grid item xs={12} key={schedule.key}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={10} md={11}>
                  <Grid container spacing={3} alignItems="flex-end">
                    <Grid item xs={6} md={3}>
                      <SelectControl
                        defaultValue={DAYOFWEEK[0].value}
                        label="Weekday"
                        name={`schedules.${index}.weekday`}
                        nullDisplay=""
                        options={DAYOFWEEK.map(x => ({
                          name: x.text,
                          value: x.value,
                        }))}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SelectControl
                        defaultValue={timeOptions[0]}
                        label="First Slot"
                        name={`schedules.${index}.first_slot`}
                        nullDisplay=""
                        options={timeOptions.map(x => ({ name: x, value: x }))}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SelectControl
                        defaultValue={timeOptions[0]}
                        label="Last Slot"
                        name={`schedules.${index}.last_slot`}
                        nullDisplay=""
                        options={timeOptions.map(x => ({ name: x, value: x }))}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <TextFieldControl
                            label="Appointments Per Slot"
                            name={`schedules.${index}.appointments_per_slot`}
                            control={control}
                            defaultValue=""
                            required
                            disabled={!canUpdate}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextFieldControl
                            label="Max Appointments"
                            name={`schedules.${index}.max_appointments`}
                            required
                            disabled={!canUpdate}
                            type="number"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} md={1} style={{ margin: 'auto' }}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      schedule.id
                        ? setConfirmRemoveItemIndex(index)
                        : remove(index)
                    }
                    style={{
                      backgroundColor: 'red',
                      minWidth: '36px',
                      padding: '10px',
                    }}
                    disabled={!canUpdate}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <ConfirmDialog
          isOpen={confirmRemoveItemIndex !== null}
          onConfirm={() => {
            remove(confirmRemoveItemIndex);
          }}
          onClose={() => setConfirmRemoveItemIndex(null)}
          text="Are you sure you want to remove that schedule period?"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SchedulePeriod;
