import React, { useEffect } from 'react';

import gql from 'graphql-tag';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
} from '@mui/material';

import { useMutation } from '@apollo/react-hooks';
import useYearMakeModel from 'components/hooks/useYearMakeModel';
import { useForm, useWatch } from 'react-hook-form';
import {
  LoadingBackdrop,
  SelectControl,
  useDealerContext,
} from 'components/MaterialUI';

import { AppraisalType } from 'modules/appraisals/const';

const CREATE_APPRAISAL = gql`
  mutation createAppraisal($appraisal: AppraisalInput!) {
    appraisals {
      createAppraisal(appraisal: $appraisal) {
        id
        paveSessionId
        isPaveSessionComplete
        paveSMSGatewayId
        paveSMSPhoneNumber
      }
    }
  }
`;

const CreateVehicleWithPaveDialog = ({ open, onClose, onCreateAppraisal }) => {
  const { dealerId } = useDealerContext();
  const [createAppraisal, { loading }] = useMutation(CREATE_APPRAISAL);
  const defaultValues = {
    appraisalType: AppraisalType.USED_VEHICLE,
    dealerId,
    greaseMakeId: null,
    greaseModelId: null,
    includePave: true,
    year: null,
    make: '',
    model: '',
  };
  const { control, register, reset, formState, handleSubmit, setValue } =
    useForm({
      defaultValues,
    });
  const { errors } = formState;

  const year = useWatch({ control, name: 'year' });
  const make = useWatch({ control, name: 'make' });
  const model = useWatch({ control, name: 'model' });
  const greaseMakeId = useWatch({ control, name: 'greaseMakeId' });
  const greaseModelId = useWatch({ control, name: 'greaseModelId' });

  const {
    makes,
    models,
    years,
    loading: loadingYMM,
    setYear,
    setMakeId,
  } = useYearMakeModel({ selectedYear: year, selectedMakeId: greaseMakeId });

  const onSubmit = appraisal =>
    createAppraisal({ variables: { appraisal } }).then(
      () => onCreateAppraisal(appraisal),
      () => {},
    );

  // For the ymm drop downs
  useEffect(() => {
    if (year) setYear(year);
  }, [year, setYear]);

  // sets makeId for drop downs and sets 'make' in form data with the text
  // value associated with greaseMakeId
  useEffect(() => {
    if (greaseMakeId) {
      setMakeId(greaseMakeId);
      const _make = makes.find(({ id }) => id === greaseMakeId)?.name;
      if (_make && make !== _make) setValue('make', _make);
    }
  }, [greaseMakeId, setMakeId, setValue, make, makes]);

  // sets 'model' in form data with the text value associated with greaseModelId
  useEffect(() => {
    if (greaseModelId) {
      const _model = models.find(({ id }) => id === greaseModelId)?.name;
      if (_model && model !== _model) setValue('model', _model);
    }
  }, [greaseModelId, setValue, model, models]);

  useEffect(() => {
    if (open) reset(defaultValues);
  }, [open, reset]);

  return (
    <Dialog open={open} onClose={onClose}>
      <LoadingBackdrop open={loadingYMM}>
        Loading vehicle dropdowns
      </LoadingBackdrop>
      <LoadingBackdrop open={loading}>Creating appraisal</LoadingBackdrop>
      <DialogTitle>Create Appraisal with PAVE</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        {['appraisalType', 'make', 'model', 'dealerId', 'includePave'].map(
          name => (
            <input key={name} type="hidden" {...register(name)} />
          ),
        )}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectControl
                control={control}
                errors={errors}
                fullWidth
                label="Year"
                name="year"
                options={years
                  .sort((a, b) => b - a)
                  .map(y => ({ value: y, name: y }))}
                rules={{ required: 'Year required!' }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectControl
                control={control}
                errors={errors}
                fullWidth
                label="Make"
                name="greaseMakeId"
                options={makes}
                optionValueKey="id"
                rules={{ required: 'Make required!' }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectControl
                control={control}
                errors={errors}
                fullWidth
                label="Model"
                name="greaseModelId"
                options={models}
                optionValueKey="id"
                rules={{ required: 'Model required!' }}
                variant="filled"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateVehicleWithPaveDialog;
