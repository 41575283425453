export const AppraisalStatus = {
  AUCTIONED: 'SOLD AT AUCTION',
  ABANDONED: 'ABANDONED',
  BIDDING: 'BIDDING',
  CUSTOMER_OFFERED: 'CUSTOMER OFFERED',
  DECLINED: 'OFFER DECLINED',
  OWNED: 'OWNED',
  PENDING_AUCTION: 'PENDING AUCTION',
  PENDING_OFFER: 'PENDING OFFER',
  SOLD: 'SOLD',
  NEW: 'NEW',
};

export const AppraisalDeliveryStatus = {
  ARRIVED: 'Arrived',
  AWAITING_DELIVERY: 'Awaiting Delivery',
  DELIVERED: 'Delivered',
  IN_TRANSIT: 'In Transit',
  NEW: 'Unknown',
};

export const AppraisalStatusColours = {
  [AppraisalStatus.AUCTIONED]: 'mediumseagreen',
  [AppraisalStatus.ABANDONED]: '#5717A6',
  [AppraisalStatus.BIDDING]: 'black', // wasn't specified before
  [AppraisalStatus.CUSTOMER_OFFERED]: 'limegreen',
  [AppraisalStatus.DECLINED]: 'firebrick',
  [AppraisalStatus.OWNED]: 'goldenrod',
  [AppraisalStatus.PENDING_AUCTION]: 'lightskyblue',
  [AppraisalStatus.PENDING_OFFER]: '#008080',
  [AppraisalStatus.SOLD]: 'mediumseagreen',
  [AppraisalStatus.NEW]: 'black',
};

export const OfferStatus = {
  ACCEPTED: 'ACCEPTED',
  AUCTIONED: 'AUCTIONED',
  BUY_NOW_REQUEST: 'BUY NOW REQUEST',
  CUSTOMER_OFFERED: 'CUSTOMER OFFERED',
  DECLINED: 'DECLINED',
  NEW: 'NEW',
  OFFERED: 'OFFERED',
  QUEUED: 'QUEUED',
  RESCINDED: 'RESCINDED',
};

export const Provinces = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];

export const PlaceholderVin = '00000000000000000';
// Placeholder vin used by mobile app (and website?) if the user does not enter a vin.

export const DECLINE_REASONS = {
  tooOld: 'Vehicle Too Old',
  cbbValueOnly: 'Customer Interested in CBB Value Only',
  tooMuch: 'Customer Wants Too Much',
  mileageTooHigh: 'Mileage Too High',
  outOfServiceArea: 'Out of Service Area',
};
