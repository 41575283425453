// Constants for opportunities
import { Role } from 'constants.js';

export const DATE_RANGE = [
  { display: 'Today', value: 'today' },
  { display: 'This Week', value: 'week' },
  { display: 'This Month', value: 'month' },
  { display: 'This Quarter', value: 'quarter' },
];

export const STOCK_TYPES = [
  { display: 'New', value: 'new' },
  { display: 'Used', value: 'used' },
  { display: 'Unknown', value: '' },
];

export const LEAD_CHANNELS = [
  { display: 'All Inbound', value: 'inbound' },
  { display: 'All Outbound', value: 'outbound' },
  { display: 'Inbound Chat', value: 'inbound chat' },
  { display: 'Inbound Email', value: 'inbound email' },
  { display: 'Inbound Event', value: 'inbound event' },
  { display: 'Inbound Phone', value: 'inbound phone' },
  { display: 'Inbound Service', value: 'inbound service' },
  { display: 'Inbound Social', value: 'inbound social' },
  { display: 'Inbound Text', value: 'inbound sms' },
  { display: 'Inbound Walk', value: 'inbound walk' },
  { display: 'Inbound Web', value: 'inbound web' },
  { display: 'Outbound Email', value: 'outbound email' },
  { display: 'Outbound Phone', value: 'outbound phone' },
  { display: 'Outbound Text', value: 'outbound sms' },
];

export const TASK_ROLES = [
  Role.SALES_REP,
  Role.INTERNET_SALES_REP,
  Role.BDC_REP,
  Role.BDC_MANAGER,
  Role.SALES_MANAGER,
  Role.FINANCE_MANAGER,
  Role.FINANCE_DIRECTOR,
];

export const ASSIGNEE_ROLES = [
  { value: 'sales_reps', display: 'Sales Person' },
  { value: 'sales_managers', display: 'Sales Manager' },
  { value: 'customer_reps', display: 'Customer Sales Rep' },
  { value: 'bdc_reps', display: 'BDC Rep' },
  { value: 'finance_managers', display: 'Finance Producer' },
];

export const NAME = 'market-mobile/opportunities';

export const CREATE_TRADE_IN_SUCCESS_NAME =
  'opportunities/CREATE_TRADE_IN_SUCCESS_NAME';
export const CREATE_TRADE_IN_FAILURE_NAME =
  'opportunities/CREATE_TRADE_IN_FAILURE_NAME';
export const CREATE_OPPORTUNITY_SUCCESS_NAME =
  'opportunities/CREATE_OPPORTUNITY_SUCCESS_NAME';
export const LINK_OPPORTUNITY_TO_LEAD_SUCCESS_NAME =
  'opportunities/LINK_OPPORTUNITY_TO_LEAD_SUCCESS_NAME';
export const TASK_FORM_NAME = 'opportunities/task-form';
export const CREATE_OPPORTUNITY_TASK_SUCCESS_NAME =
  'opportunities/CREATE_OPPORTUNITY_TASK_SUCCESS_NAME';
export const CREATE_OPPORTUNITY_TASK_FAILURE_NAME =
  'opportunities/CREATE_OPPORTUNITY_TASK_FAILURE_NAME';
export const APPOINTMENT_FORM_NAME = 'opportunities/appointment-form';
export const CREATE_OPPORTUNITY_APPOINTMENT_SUCCESS_NAME =
  'opportunities/CREATE_OPPORTUNITY_APPOINTMENT_SUCCESS_NAME';
export const CREATE_OPPORTUNITY_APPOINTMENT_FAILURE_NAME =
  'opportunities/CREATE_OPPORTUNITY_APPOINTMENT_FAILURE_NAME';
export const NOTE_FORM_NAME = 'opportunities/note-form';
export const CREATE_OPPORTUNITY_NOTE_SUCCESS_NAME =
  'opportunities/CREATE_OPPORTUNITY_NOTE_SUCCESS_NAME';
export const CREATE_OPPORTUNITY_NOTE_FAILURE_NAME =
  'opportunities/CREATE_OPPORTUNITY_NOTE_FAILURE_NAME';

export const sessionKeys = {
  // TODO: oppFilters has the same value as in src/modules/dashboard/constants.js.
  // TODO: Investigate if that's a problem or move to src/constants.js if it's the same.
  oppFilters: 'opportunities.search.filters',
  oppKeywords: 'opportunities.search.keywords',
};

export const ATTACHMENT_TOKEN_TTL = 60 * 30; // 30 minutes
