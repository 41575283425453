import React, { useMemo } from 'react';

import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material';

import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';

/* internal */
import {
  Loading,
  LoadingBackdrop,
  useDealerContext,
} from 'components/MaterialUI';

const USERS_QUERY = gql`
  query UsersQuery($dealerId: Int!) {
    users(dealer_ids: [$dealerId]) {
      username
      role
      status
      organization_id
      display_name
    }
  }
`;

const RECIPIENT_FRAGMENT = gql`
  fragment RecipientFragment on AppraisalNotificationsRecipient {
    id
    username
    user {
      display_name
    }
  }
`;

const RECIPIENTS_MUTATION = gql`
  mutation CreateRecipient($recipient: AppraisalNotificationsRecipientInput!) {
    appraisals {
      createNotificationsRecipient(recipient: $recipient) {
        id
        ...RecipientFragment
      }
    }
  }
  ${RECIPIENT_FRAGMENT}
`;

const DELETE_RECIPIENT_MUTATION = gql`
  mutation DeleteRecipient($id: Int!) {
    appraisals {
      deleteNotificationsRecipient(id: $id) {
        id
        ...RecipientFragment
      }
    }
  }
  ${RECIPIENT_FRAGMENT}
`;
const RECIPIENTS_QUERY = gql`
  query RecipientsQuery(
    $filters: [QueryFilter]
    $sort: [QuerySortElement]
    $page: Int
    $pageSize: Int
  ) {
    appraisals {
      notificationsRecipients(
        filters: $filters
        sort: $sort
        page: $page
        pageSize: $pageSize
      ) {
        pagination {
          page
          pageSize
          total
        }
        results {
          id
          ...RecipientFragment
        }
      }
    }
  }
  ${RECIPIENT_FRAGMENT}
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const UsedVehiclesNotificationsRecipients = () => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const usersQuery = useQuery(USERS_QUERY, {
    variables: { dealerId },
  });
  const filters = [
    {
      model: 'NotificationsRecipient',
      field: 'dealerId',
      op: 'eq',
      value: dealerId,
    },
  ];

  const recipientsQuery = useQuery(RECIPIENTS_QUERY, {
    variables: {
      filters,
      page: 1,
      pageSize: 100, // For simplicity, going to make this a hard limit in the back end (per dealer)
    },
  });

  const [createRecipient, createRecipientMutation] =
    useMutation(RECIPIENTS_MUTATION);
  const [deleteRecipient, deleteRecipientMutation] = useMutation(
    DELETE_RECIPIENT_MUTATION,
  );

  const recipients =
    recipientsQuery.data?.appraisals.notificationsRecipients.results;

  const recipientUsernames = recipients?.map(({ username }) => username);

  const users =
    usersQuery.data?.users
      .filter(x => x.status === 'active')
      .sort((a, b) => a.display_name.localeCompare(b.display_name)) ?? [];

  const selectedUsers = useMemo(
    () => users.filter(({ username }) => recipientUsernames.includes(username)),
    [users, recipientUsernames],
  );

  const successHandler = message => () => {
    recipientsQuery.refetch();
    enqueueSnackbar(message, { variant: 'success' });
  };

  const errorHandler = error =>
    enqueueSnackbar(error.message, { variant: 'error' });

  const handleChange = (_, users, reason) =>
    reason === 'selectOption'
      ? createRecipient({
          variables: {
            recipient: {
              dealerId,
              username: users.find(
                x => !selectedUsers.map(y => y.username).includes(x.username),
              )?.username,
            },
          },
        }).then(successHandler('Recipient added successfully'), errorHandler)
      : reason === 'removeOption'
      ? deleteRecipient({
          variables: {
            id: recipients.find(
              x => !users.map(y => y.username).includes(x.username),
            ).id,
          },
        }).then(successHandler('Recipient removed successfully'), errorHandler)
      : reason === 'clear'
      ? Promise.all(
          recipients.map(({ id }) => deleteRecipient({ variables: { id } })),
        ).then(
          successHandler('All recipients removed successfully'),
          errorHandler,
        )
      : null;

  const loading =
    usersQuery.loading ||
    recipientsQuery.loading ||
    createRecipientMutation.loading ||
    deleteRecipientMutation.loading;

  return (
    <Box mt={3}>
      <LoadingBackdrop open={loading} />
      <Typography variant="h5">
        Customer Appraisals Notifications Recipients
      </Typography>
      <Autocomplete
        style={{ width: '100%' }}
        limitTags={5}
        multiple
        options={users}
        getOptionLabel={({ display_name, username }) =>
          `${display_name} (${username})`
        }
        value={selectedUsers}
        renderOption={(props, { display_name, username }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={recipientUsernames.includes(username)}
              />
              {display_name} ({username})
            </li>
          );
        }}
        onChange={handleChange}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            label="Add Recipient"
            placeholder="Type to Find Recipients"
          />
        )}
      />
    </Box>
  );
};

export default UsedVehiclesNotificationsRecipients;
