import React from 'react';

/* Material UI */
import { Box, Typography } from '@mui/material';

/* internal */
import { DealerPicker, useDealerContext } from 'components/MaterialUI';
import UsedVehiclesNotificationsRecipients from './UsedVehiclesNotificationsRecipients';
const UsedVehiclesAdmin = () => {
  return (
    <Box m={3}>
      <Box mb={2}>
        <DealerPicker />
      </Box>

      <Typography variant="h4">Used Vehicles Settings</Typography>
      <UsedVehiclesNotificationsRecipients />
    </Box>
  );
};

export default UsedVehiclesAdmin;
