/* external */
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import useYearMakeModel from 'components/hooks/useYearMakeModel';
import {
  ConfirmDialog,
  LoadingBackdrop,
  SelectControl,
  TextFieldControl,
  VehicleSearchDialog,
} from 'components/MaterialUI';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { NO_DISCOUNTS_DEALERS } from 'modules/pitches/const';
import {
  errorHandler,
  pitchStyles,
  processPitchData,
} from 'modules/pitches/utils';

import { formatPrice, titleize } from 'utils';

import PitchVehicleSummaryCard from './PitchVehicleSummaryCard';
import {
  GOAUTO_GROUP_ID,
  GOAUTO_ORG_ID,
  Role,
  TEST_DEALERS_GROUP_ID,
  TEST_ORG_ID,
} from 'constants.js';

const PITCH_FRAGMENT = gql`
  fragment VehicleDetailsStepPitch on Pitch {
    companyName
    customerPromises
    dealNumber
    fullname
    promises
    vehicles {
      id
      dealerId
      inventoryVehicleId
      regularPrice
      salePrice
      stockNumber
      stockType
      vin
      year
      make
      model
      odometer
      type
      vehicleName
      accessories {
        id
        title
        price
      }
      discounts {
        id
        title
        price
      }
    }
    ...PitchVehicleSummaryCardPitch
  }
  ${PitchVehicleSummaryCard.fragments.pitch}
`;
const CREATE_PITCH = gql`
  mutation createPitch($pitch: PitchInput!) {
    pitches {
      createPitch(pitch: $pitch) {
        id
        ...VehicleDetailsStepPitch
      }
    }
  }
  ${PITCH_FRAGMENT}
`;

const UPDATE_PITCH = gql`
  mutation updatePitch($id: Int!, $data: PitchUpdate!) {
    pitches {
      updatePitch(id: $id, data: $data) {
        id
        ...VehicleDetailsStepPitch
      }
    }
  }
  ${PITCH_FRAGMENT}
`;

const STOCK_NUMBER_QUERY = gql`
  query getVehicles($stock_number: JSON!, $group_id: JSON!) {
    inventory {
      getGroupVehicles(
        filters: [
          {
            model: "Vehicle"
            field: "stock_number"
            op: "ilike"
            value: $stock_number
          }
          { model: "StockStatus", field: "name", op: "==", value: "In Stock" }
          {
            model: "DealerGroupMember"
            field: "group_id"
            op: "=="
            value: $group_id
          }
        ]
      ) {
        results {
          id
          dealer_id
          list_price
          make_id
          model_id
          msrp
          odometer
          regular_price
          stock_number
          stock_type
          trim
          trim_variation
          vin
          year
        }
      }
    }
  }
`;

const CDK_DEAL_QUERY = gql`
  query getDeal($dealNumber: Int!, $dealerId: Int!) {
    cdk {
      deal(dealNumber: $dealNumber, dealerId: $dealerId) {
        dealStatus
        dealType
        vehicle {
          stockType
          vehicleStock
          vin
        }
      }
    }
  }
`;

const SYNC_TO_DMS_DEAL = gql`
  mutation syncToDMSDeal($pitchId: Int!) {
    pitches {
      syncToDMSDeal(id: $pitchId) {
        id
        dealNumber
      }
    }
  }
`;

const UPDATE_OPPORTUNITY_STOCK_TYPE = gql`
  mutation updateOpportunityStockType(
    $_id: ID!
    $input: UpdateOpportunityStockTypeInput!
  ) {
    updateOpportunityStockType(_id: $_id, input: $input) {
      _id
      stock_type
      deal_type
    }
  }
`;

const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.actions.create.color,
  },
  actionButton: {
    backgroundColor: theme.actions.create.color,
    color: 'white',
  },
  button: pitchStyles.responsiveButton(theme),
  cancelButton: {
    margin: theme.spacing(1),
    ...theme.actions.cancel,
  },
  nextButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.actions.add.backgroundColor,
    color: 'white',
  },
  mainBox: pitchStyles.stepBox(theme),
  width50Mobile: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  width75Mobile: {
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  sectionSubTitle: pitchStyles.sectionSubTitle(theme),
  sectionTitle: pitchStyles.sectionTitle(theme),
  dealNumberBox: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
    display: 'flex',
    alignItems: 'flex-end',
  },
  discountsBox: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: '95%',
    },
    [theme.breakpoints.up('xm')]: {
      margin: theme.spacing(3),
      width: '100%',
    },
  },
}));

const useInputLabelStyles = makeStyles(theme => ({
  root: {
    '&.Mui-focused': {
      color: theme.actions.create.color,
    },
  },
  focused: {},
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    '&$underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.actions.create.color,
    },
    '&$underline:after': {
      borderBottomColor: theme.actions.create.color,
    },
  },
  underline: {},
}));

// TODO:
// - ensure error handling works, and that we're setting errors on the form
//   fields correctly (note that we may need to manually call clear error before
//   attempting resubmitting, since we'll be manually setting the errors on
//   react hook form state)
const VehicleDetailsStep = ({
  onCancel,
  onCreate,
  onNext,
  onUpdate,
  opportunity,
  pitch,
}) => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles(theme);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const {
    currentUser: { organization_id: orgId, role },
  } = useUserContext();
  const isManagerOrAbove =
    Role.ALL_SALES_MANAGERS.includes(role) || role === Role.ORGANIZATION_ADMIN;
  const noDiscounts = NO_DISCOUNTS_DEALERS.includes(opportunity.dealer_id);

  const inputLabelClasses = useInputLabelStyles(theme);
  const inputClasses = useInputStyles(theme);
  const methods = useForm({
    defaultValues: pitch ?? {
      fullname: opportunity?.customer?.fullname || '',
      companyName: opportunity?.customer?.company_name || '',
      vehicles: [{}],
    },
    shouldUnregister: true,
  });
  const {
    clearErrors,
    control,
    formState: { errors },
    formState,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = methods;

  const { isDirty, dirtyFields } = formState;
  const shouldDirty = true;

  const { id } = pitch ?? {};
  const [vehicleSearchOpen, setVehicleSearchOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmCreateCDK, setShowConfirmCreateCDK] = useState(false);
  const [verifiedDealNumber, setVerifiedDealNumber] = useState('');
  const [oppStockTypeMismatch, setOppStockTypeMismatch] = useState();
  const [onCloseStockTypeDialog, setOnCloseStockTypeDialog] = useState(
    () => {},
  );

  const accessory = useFieldArray({
    control,
    name: 'vehicles.0.accessories',
    keyName: 'key', // important so that we can have the accessory id submitted
    // with the form (in case we're adding to an already existing pitch vehicle
    // that already has some accessories)
    // shouldUnregister: true,
  });

  const accessoryFields = watch('vehicles.0.accessories', []);

  const totalAccessories = accessoryFields.reduce(
    (total, { price }) => total + (price ? parseFloat(price) : 0),
    0,
  );

  const discount = useFieldArray({
    control,
    name: 'vehicles.0.discounts',
    keyName: 'key',
    shouldUnregister: true,
  });

  const discountFields = watch('vehicles.0.discounts', []);
  const totalDiscounts = discountFields.reduce(
    (total, { price }) => total + (price ? parseFloat(price) : 0),
    0,
  );

  register('vehicles.0.id');
  register('vehicles.0.make');
  register('vehicles.0.model');
  register('vehicles.0.inventoryVehicleId');
  register('vehicles.0.dealerId');
  register('vehicles.0.regularPrice');
  register('vehicles.0.odometer');
  register('vehicles.0.trim');
  register('vehicles.0.trimVariation');

  const year = useWatch({ control, name: 'vehicles.0.year' });
  const makeId = useWatch({ control, name: 'vehicles.0.makeId' });
  const modelId = useWatch({ control, name: 'vehicles.0.modelId' });
  const inventoryVehicleId = useWatch({
    control,
    name: 'vehicles.0.inventoryVehicleId',
  });
  const regularPrice = useWatch({ control, name: 'vehicles.0.regularPrice' });
  const vehiclePitchType = useWatch({ control, name: 'vehicles.0.type' });
  register('vehicles.0.vin', {
    required:
      vehiclePitchType === 'stock'
        ? 'You must have a VIN for an in-stock vehicle.'
        : null,
  });
  const make = useWatch({ control, name: 'vehicles.0.make' });
  const model = useWatch({ control, name: 'vehicles.0.model' });
  const stockNumber = useWatch({ control, name: 'vehicles.0.stockNumber' });
  const vin = useWatch({ control, name: 'vehicles.0.vin' });
  const odometer = useWatch({
    control,
    name: 'vehicles.0.odometer',
    defaultValue: '',
  });
  const dealNumber = useWatch({ control, name: 'dealNumber' });

  const [createPitch, createPitchMutation] = useMutation(CREATE_PITCH);
  const [updatePitch, updatePitchMutation] = useMutation(UPDATE_PITCH);
  const [syncToDMSDeal, { loading: syncing }] = useMutation(SYNC_TO_DMS_DEAL);
  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY_STOCK_TYPE);

  const {
    makes,
    models,
    years,
    loading: loadingYMM,
    setYear,
    setMakeId,
  } = useYearMakeModel({ selectedYear: year, selectedMakeId: makeId });

  useEffect(() => {
    if (year) setYear(year);
  }, [year, setYear]);

  useEffect(() => {
    if (makeId) {
      setMakeId(makeId);
      const _make = makes.find(({ id }) => id === makeId)?.name;
      if (_make && _make !== make)
        setValue('vehicles.0.make', _make, { shouldDirty });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeId, setMakeId, makes, setValue]);

  useEffect(() => {
    if (modelId) {
      const _model = models.find(({ id }) => id === modelId)?.name;
      if (_model && _model !== model)
        setValue('vehicles.0.model', _model, { shouldDirty });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, setValue, models]);

  useEffect(() => {
    if (vehiclePitchType === 'factory' || vehiclePitchType === 'locate') {
      if (odometer !== null)
        setValue('vehicles.0.odometer', null, { shouldDirty });
      if (stockNumber !== null)
        setValue('vehicles.0.stockNumber', null, { shouldDirty });
      if (vin !== null) setValue('vehicles.0.vin', null, { shouldDirty });
      if (inventoryVehicleId !== null)
        setValue('vehicles.0.inventoryVehicleId', null, { shouldDirty });
      if (regularPrice !== null)
        setValue('vehicles.0.regularPrice', null, { shouldDirty });
      if (dealerId !== null)
        setValue('vehicles.0.dealerId', null, { shouldDirty });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, vehiclePitchType]);

  const firstRun = useRef(true);

  useEffect(() => {
    // when loading a new stock or locate vehicle, prevent the effect from changing the type to used
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    const defaultStockTypeValue =
      vehiclePitchType === 'factory' ? 'NEW' : 'USED';
    setValue('vehicles.0.stockType', defaultStockTypeValue, { shouldDirty });
    clearErrors('vehicles.0.stockNumber'); // Clear stock number errors when switching pitch type
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclePitchType]);

  useEffect(() => {
    if (make && makes && !makeId) {
      // set makeId from make.  Should only matter when loading in an existing
      // pitch to edit
      const newMakeId = makes.find(({ name }) => name === make)?.id;
      if (newMakeId) setValue('vehicles.0.makeId', newMakeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [make, makes, makeId]);

  useEffect(() => {
    if (model && models && !modelId) {
      // set modelId from model.  Should only matter when loading in an existing
      // pitch to edit
      const newModelId = models.find(({ name }) => name === model)?.id;
      if (newModelId) setValue('vehicles.0.modelId', newModelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, models, modelId]);

  const handleClickAccessory = () => {
    accessory.append({
      title: '',
      price: '',
    });
  };

  const handleClickCancel = () =>
    // TODO: There should be no need to check dirtyFields, but for some reason
    // factory and locate pitches are becoming dirty on load.  I'm sure it has to
    // do with the useEffect for vehiclePitchType above, but I'm not 100% sure why.
    // Not worth dealing with at the moment.
    isDirty && !isEmpty(dirtyFields) ? setShowConfirmDialog(true) : onCancel();

  const handleClickDiscount = () => {
    discount.append({});
  };

  const handleNext = () => {
    if ((isDirty && !isEmpty(dirtyFields)) || errors || !id) {
      // Create or update the pitch.  if successful, call onNext if updating,
      // call onCreate if creating.
      clearErrors(); // This SHOULD clear all errors, but it doesn't seem to clear the
      // 'error' error.
      clearErrors('error');
      handleSubmit(
        data => {
          if (id) {
            if (discount.fields.length === 0) data.vehicles[0].discounts = [];
            if (accessory.fields.length === 0)
              data.vehicles[0].accessories = [];

            updatePitch({
              variables: { id, data: processPitchData(data) },
            }).then(({ data }) => {
              const pitchStockType =
                data.pitches.updatePitch.vehicles?.[0]?.stockType;
              if (
                pitchStockType?.toLowerCase() !==
                opportunity.stock_type?.toLowerCase()
              ) {
                setOppStockTypeMismatch(titleize(pitchStockType));
                setOnCloseStockTypeDialog(() => () => {
                  onUpdate();
                  onNext();
                }); // () => () => cause the state update function will think it's the prev form with just () =>
              } else {
                onUpdate();
                onNext();
              }
            }, errorHandler(snackIt, setError));
          } else
            createPitch({
              variables: {
                pitch: {
                  ...processPitchData(data),
                  customerId: opportunity.customer._id,
                  opportunityId: opportunity._id,
                  dealerId: opportunity.dealer_id,
                },
              },
            }).then(({ data }) => {
              // if they put in a deal number, and populate from CDK then it favorites it for them
              if (verifiedDealNumber) {
                syncToDMSDeal({
                  variables: {
                    pitchId: data.pitches.createPitch.id,
                  },
                }).then(() => onCreate(data.pitches.createPitch.id));
              } else {
                const pitchStockType =
                  data.pitches.createPitch.vehicles?.[0]?.stockType;
                if (
                  pitchStockType?.toLowerCase() !==
                  opportunity.stock_type?.toLowerCase()
                ) {
                  setOppStockTypeMismatch(titleize(pitchStockType));
                  setOnCloseStockTypeDialog(
                    () => () => onCreate(data.pitches.createPitch.id),
                  ); // () => () => cause the state update function will think it's the prev form with just () =>
                } else onCreate(data.pitches.createPitch.id);
              }
            }, errorHandler(snackIt, setError));
        },
        () => {},
      )();
    } else if (id) {
      onNext();
    }
  };

  // // We only need a deal number to move on.
  // UPDATE: We don't need a deal number, since we will get a deal number from
  // CDK for a new deal.
  // UPDATE: Require at least a year/make/model before moving on.
  // useEffect(() => {
  //   const _stepValid =
  //     year &&
  //     makeId &&
  //     modelId &&
  //     (vehiclePitchType !== 'stock' || (stockNumber && stockNumber !== ''));
  //   if (!stepValid && _stepValid) setStepValid(true);
  //   if (stepValid && !_stepValid) setStepValid(false);
  // }, [year, makeId, modelId, stockNumber, stepValid, setStepValid]);

  const InputLabelProps = { shrink: true, classes: inputLabelClasses };
  const InputProps = { classes: inputClasses };

  const setFromInventoryVehicle = vehicle => {
    clearErrors('vehicles.0.dealerId');
    clearErrors('vehicles.0.inventoryVehicleId');
    clearErrors('vehicles.0.year');
    clearErrors('vehicles.0.makeId');
    clearErrors('vehicles.0.modelId');
    clearErrors('vehicles.0.stockNumber');
    clearErrors('vehicles.0.stockType');
    clearErrors('vehicles.0.odometer');
    clearErrors('vehicles.0.vin');
    clearErrors('vehicles.0.regularPrice');
    setValue('vehicles.0.dealerId', vehicle.dealer_id, { shouldDirty });
    setValue('vehicles.0.inventoryVehicleId', vehicle.id, { shouldDirty });
    setValue('vehicles.0.year', vehicle.year, { shouldDirty });
    setValue('vehicles.0.makeId', vehicle.make_id, { shouldDirty });
    setValue('vehicles.0.modelId', vehicle.model_id, { shouldDirty });
    setValue('vehicles.0.stockNumber', vehicle.stock_number, { shouldDirty });
    setValue('vehicles.0.stockType', vehicle.stock_type, { shouldDirty });
    setValue('vehicles.0.odometer', vehicle.odometer, { shouldDirty });
    setValue('vehicles.0.vin', vehicle.vin, { shouldDirty });
    setValue('vehicles.0.regularPrice', vehicle.list_price, { shouldDirty });
    setValue('vehicles.0.trim', vehicle.trim);
    setValue('vehicles.0.trimVariation', vehicle.trim_variation);
  };

  const [getVehicleByStockNumber, getVehicleByStockNumberQuery] = useLazyQuery(
    STOCK_NUMBER_QUERY,
    {
      onCompleted: results =>
        results?.inventory.getGroupVehicles.results.filter(
          obj => obj.dealer_id === dealerId,
        ).length === 1
          ? setFromInventoryVehicle(
              results.inventory.getGroupVehicles.results.filter(
                obj => obj.dealer_id === dealerId,
              )[0],
            )
          : results?.inventory.getGroupVehicles.results.filter(
              obj => obj.dealer_id === dealerId,
            ).length === 0
          ? setError('vehicles.0.stockNumber', { message: 'No results' })
          : setError('vehicles.0.stockNumber', {
              message: 'Multiple results',
            }),
      // TODO: check if on deal log
      onError: () => {},
    },
  );

  const [getCDKDeal] = useLazyQuery(CDK_DEAL_QUERY, {
    onCompleted: results => {
      const { vehicleStock } = results.cdk.deal?.vehicle || {};
      // Got a stock number?  Look up the vehicle in inventory!
      if (vehicleStock) {
        setValue('vehicles.0.stockNumber', vehicleStock, { shouldDirty });
        getVehicleByStockNumber({
          variables: {
            stock_number: vehicleStock,
            group_id:
              orgId === GOAUTO_ORG_ID
                ? GOAUTO_GROUP_ID
                : orgId === TEST_ORG_ID
                ? TEST_DEALERS_GROUP_ID
                : null,
          },
        });
      }
      // get deal number from form value and save in a state
      // dealNumber is using watch
      setVerifiedDealNumber(dealNumber);
    },
  });

  const handleStockNumberKeypress = ({ target: { value }, key }) => {
    if (key === 'Enter') searchByStockNumber(value);
  };

  const searchByStockNumber = value => {
    clearErrors('vehicles.0.stockNumber');
    getVehicleByStockNumber({
      variables: {
        stock_number: `${value}%`,
        group_id:
          orgId === GOAUTO_ORG_ID
            ? GOAUTO_GROUP_ID
            : orgId === TEST_ORG_ID
            ? TEST_DEALERS_GROUP_ID
            : null,
      },
    });
  };

  const handlePopulateFromCDK = () => {
    if (dealNumber && dealNumber !== '')
      getCDKDeal({
        variables: { dealerId, dealNumber: parseInt(dealNumber, 10) },
      });
  };

  const handleCreateCDK = () => setShowConfirmCreateCDK(true);

  const createDeal = pitchId =>
    syncToDMSDeal({ variables: { pitchId } }).then(({ data }) => {
      setValue('dealNumber', data.pitches.syncToDMSDeal.dealNumber, {
        shouldDirty,
      });
      snackIt(`Deal created`);
    }, errorHandler(snackIt, setError));

  const createPitchAndDeal = () => {
    // create or update the pitch if necessary, then sync to cdk.
    if ((isDirty && !isEmpty(dirtyFields)) || errors) {
      clearErrors();
      clearErrors('error');
      handleSubmit(
        data => {
          if (id)
            updatePitch({
              variables: { id, data: processPitchData(data) },
            }).then(() => {
              createDeal(id);
              onUpdate();
            }, errorHandler(snackIt, setError));
          else
            createPitch({
              variables: {
                pitch: {
                  ...processPitchData(data),
                  customerId: opportunity.customer._id,
                  opportunityId: opportunity._id,
                  dealerId,
                },
              },
            }).then(({ data }) => {
              createDeal(data.pitches.createPitch.id).then(() =>
                onCreate(data.pitches.createPitch.id, { noNext: true }),
              );
            }, errorHandler(snackIt, setError));
        },
        () => {},
      )();
    } else if (id) {
      createDeal(id).then(onUpdate);
    }

    setShowConfirmCreateCDK(false);
  };

  const handleUpdateStockType = () => {
    updateOpportunity({
      variables: {
        _id: opportunity._id,
        input: {
          stock_type: oppStockTypeMismatch,
        },
      },
    }).then(
      () => snackIt('Updated opportunity stock type', { variant: 'success' }),
      err => snackIt(`Error updating opportunity: ${JSON.stringify(err)}`),
    );
  };

  const factoryOrLocate =
    vehiclePitchType === 'factory' || vehiclePitchType === 'locate';

  // console.dir(accessory.fields);

  return (
    <Box className={classes.mainBox}>
      {/* For some reason, setting m={3} causes the scrollbar to have
  a fucking seizure whenever you hover over one of the inputs.  In fact, any value
  between 24-31px does the same thing.  W.  T.  actual.  F. */}
      <LoadingBackdrop
        open={
          syncing || createPitchMutation.loading || updatePitchMutation.loading
        }
      >
        Saving
      </LoadingBackdrop>
      <VehicleSearchDialog
        onClose={() => setVehicleSearchOpen(false)}
        onConfirm={vehicle => {
          setFromInventoryVehicle(vehicle);
          setVehicleSearchOpen(false);
          clearErrors('vehicles.0.stockNumber');
        }}
        open={vehicleSearchOpen}
      />
      <ConfirmDialog
        isOpen={showConfirmDialog}
        onConfirm={onCancel}
        onClose={() => setShowConfirmDialog(false)}
        text={
          'The data on this page has changed - are you sure you want to cancel?  \
           You will lose any unsaved changes.'
        }
      />
      <ConfirmDialog
        isOpen={showConfirmCreateCDK}
        onConfirm={createPitchAndDeal}
        onClose={() => setShowConfirmCreateCDK(false)}
        text="This will save the pitch data and create a new deal in CDK.  It will also set the primary pitch on the opportunity to this one, and advance the opportunity to 'Sent to Desk'.  Are you sure?"
      />
      <ConfirmDialog
        isOpen={Boolean(oppStockTypeMismatch)}
        text={`The vehicle stock type on this pitch is ${oppStockTypeMismatch} but the opportunity stock type is set to ${
          opportunity.stock_type ?? 'Unknown'
        }. Do you want to update the opportunity stock type?`}
        onConfirm={handleUpdateStockType}
        onClose={() => {
          setOppStockTypeMismatch(null);
          onCloseStockTypeDialog();
          setOnCloseStockTypeDialog(() => {});
        }}
      />
      <Box marginTop="2em" marginBottom="2em">
        <Typography>
          If you know the stock number of the interested vehicle, enter it below
          and press enter to populate the details.
        </Typography>
      </Box>
      <Grid container direction="row" spacing={mobile ? 2 : 8}>
        <Grid
          alignContent="flex-start"
          alignItems="stretch"
          container
          direction="row"
          item
          sm={6}
          spacing={2}
          xs={12}
        >
          {/* <Stuff beside vehicle summary thingy> */}
          <Grid item xs={12}>
            <Controller
              control={control}
              defaultValue="stock"
              name="vehicles.0.type"
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup
                  exclusive
                  onChange={(e, val) => onChange(val)}
                  size="small"
                  style={{
                    borderBottom: '1px solid grey',
                    display: 'flex',
                    width: '100%',
                  }}
                  value={value}
                >
                  <ToggleButton style={{ width: '100%' }} value="stock">
                    IN STOCK
                  </ToggleButton>
                  <ToggleButton style={{ width: '100%' }} value="factory">
                    FACTORY
                  </ToggleButton>
                  <ToggleButton style={{ width: '100%' }} value="locate">
                    LOCATE
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Grid>
          <Grid container item xs={12} alignItems="flex-end" spacing={2}>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <TextFieldControl
                disabled={factoryOrLocate}
                helperText={
                  getVehicleByStockNumberQuery.loading
                    ? 'Searching...'
                    : errors?.vehicles?.[0]?.stockNumber?.message
                }
                InputLabelProps={InputLabelProps}
                InputProps={InputProps}
                control={control}
                label="Stock Number"
                name="vehicles.0.stockNumber"
                onKeyPress={handleStockNumberKeypress}
              />
              <Button
                style={{ marginLeft: theme.spacing(1) }}
                className={`${classes.actionButton} ${classes.button}`}
                disabled={factoryOrLocate}
                onClick={() => searchByStockNumber(stockNumber)}
                variant="contained"
                size={mobile ? 'small' : 'medium'}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={1}>
              or
            </Grid>
            <Grid item xs={5} style={{ alignItems: 'flex-end' }}>
              <Button
                className={`${classes.actionButton} ${classes.button}`}
                disabled={factoryOrLocate}
                onClick={() => setVehicleSearchOpen(true)}
                style={{ whiteSpace: 'nowrap' }}
                variant="contained"
                size={mobile ? 'small' : 'medium'}
              >
                Find a Vehicle
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectControl
              control={control}
              disabled={!factoryOrLocate}
              InputLabelProps={InputLabelProps}
              InputProps={InputProps}
              label="Stock Type"
              name="vehicles.0.stockType"
              className={classes.width50Mobile}
              options={[
                { name: 'Used', value: 'USED' },
                { name: 'New', value: 'NEW' },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={vin || ''}
              disabled
              InputLabelProps={InputLabelProps}
              InputProps={{ ...InputProps }}
              label="VIN"
              className={classes.width75Mobile}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectControl
              control={control}
              defaultValue=""
              disabled={!factoryOrLocate}
              fullWidth
              InputLabelProps={InputLabelProps}
              InputProps={InputProps}
              label="Year"
              name="vehicles.0.year"
              required={!factoryOrLocate}
              rules={{
                required: factoryOrLocate ? false : 'You must enter a year',
              }}
              options={years
                .sort((a, b) => b - a)
                .map(year => ({
                  name: year,
                  value: year,
                }))}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectControl
              control={control}
              defaultValue=""
              disabled={!factoryOrLocate || loadingYMM}
              fullWidth
              InputLabelProps={InputLabelProps}
              InputProps={InputProps}
              label="Make"
              name="vehicles.0.makeId"
              required={!factoryOrLocate}
              rules={{
                required: factoryOrLocate
                  ? false
                  : 'Inventory error - no make set.',
              }}
              optionValueKey="id"
              options={makes.sort((a, b) => a.name.localeCompare(b.name))}
            />
          </Grid>

          <Grid item xs={6}>
            <SelectControl
              control={control}
              defaultValue=""
              disabled={!factoryOrLocate || loadingYMM}
              fullWidth
              InputLabelProps={InputLabelProps}
              InputProps={InputProps}
              label="Model"
              name="vehicles.0.modelId"
              required={!factoryOrLocate}
              rules={{
                required: factoryOrLocate
                  ? false
                  : 'Inventory error - no model set.',
              }}
              optionValueKey="id"
              options={models.sort((a, b) => a.name.localeCompare(b.name))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              InputLabelProps={InputLabelProps}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <InputAdornment position="end">km</InputAdornment>
                ),
              }}
              value={odometer || ''}
              label="Odometer"
            />
          </Grid>

          {/* </Stuff beside vehicle summary thingy > */}
        </Grid>
        <Grid container item xs={12} sm={6}>
          <FormProvider {...methods}>
            <PitchVehicleSummaryCard style={{ width: '100%' }} />
          </FormProvider>
        </Grid>
      </Grid>
      <Box className={classes.dealNumberBox}>
        <Box component="span" paddingRight="2em">
          {/* Note: dealNumber is set directly on the pitch, but the rest of this form
      is vehicle values.  In the future if we allow more than one vehicle per pitch
      this will need to be changed.*/}
          <TextFieldControl
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            control={control}
            label="Deal Number"
            name="dealNumber"
          />
        </Box>
        <Button
          variant="contained"
          size={mobile ? 'small' : 'medium'}
          className={classes.button}
          onClick={handlePopulateFromCDK}
          color="default"
        >
          Populate From CDK
        </Button>
        <Button
          variant="contained"
          size={mobile ? 'small' : 'medium'}
          className={classes.button}
          onClick={handleCreateCDK}
          disabled={(!id && !isDirty) || Boolean(dealNumber)}
          style={{ marginLeft: theme.spacing(1) }}
          color="default"
        >
          Create Deal in CDK
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextFieldControl
            control={control}
            fullWidth
            helperText={
              errors?.vehicles?.[0]?.vehicleName?.message ??
              'Used on pitch sheet if no year/make/model'
            }
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            rules={{ maxLength: 50 }}
            label="Vehicle Name"
            name="vehicles.0.vehicleName"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldControl
            control={control}
            fullWidth
            helperText={errors?.fullname?.message ?? 'Displayed on pitch sheet'}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            rules={{ maxLength: 100 }}
            label="Customer Name"
            name="fullname"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldControl
            control={control}
            fullWidth
            helperText={
              errors?.companyName?.message ?? 'Displayed on pitch sheet'
            }
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            rules={{ maxLength: 50 }}
            label="Company Name"
            name="companyName"
          />
        </Grid>
      </Grid>

      <Box marginTop={3} marginBottom={3}>
        <Box className={classes.sectionTitle}>Promises</Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              control={control}
              fullWidth
              InputLabelProps={InputLabelProps}
              InputProps={InputProps}
              label="Our Promises"
              name="promises"
              multiline
              minRows={4}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              control={control}
              fullWidth
              InputLabelProps={InputLabelProps}
              InputProps={InputProps}
              label="Customer Promises"
              name="customerPromises"
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={3} marginBottom={3}>
        <Box className={classes.sectionTitle}>Pricing Information</Box>
        <TextFieldControl
          control={control}
          disabled={!isManagerOrAbove}
          InputLabelProps={InputLabelProps}
          InputProps={{
            ...InputProps,
            type: 'number',
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          label="Price"
          name="vehicles.0.salePrice"
        />
      </Box>
      <Box>
        {!noDiscounts && (
          <Box
            display="flex"
            alignItems="center"
            className={classes.sectionTitle}
          >
            <Box>Accessories and Discounts</Box>
          </Box>
        )}
        <Box className={classes.discountsBox}>
          <Box className={classes.sectionSubTitle}>
            Accessories
            <IconButton onClick={handleClickAccessory} size="large">
              <AddBoxIcon className={classes.addButton} />
            </IconButton>
          </Box>
          {accessory.fields.map((item, index) => (
            <Box key={item.key}>
              <input
                {...register(`vehicles.0.accessories.${index}.id`)}
                type="hidden"
                defaultValue={item.id}
              />
              <Grid container spacing={1}>
                <Grid item xs={5} md={2}>
                  <TextField
                    {...register(`vehicles.0.accessories.${index}.title`)}
                    label="Accessory"
                    error={Boolean(
                      errors?.vehicles?.[0]?.accessories?.[index]?.title,
                    )}
                    helperText={
                      errors?.vehicles?.[0]?.accessories?.[index]?.title
                        ?.message
                    }
                  />
                </Grid>
                <Grid item xs={5} md={2}>
                  <TextField
                    {...register(`vehicles.0.accessories.${index}.price`)}
                    label="Price"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      errors?.vehicles?.[0]?.accessories?.[index]?.price,
                    )}
                    helperText={
                      errors?.vehicles?.[0]?.accessories?.[index]?.price
                        ?.message
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    size="small"
                    onClick={() => accessory.remove(index)}
                    style={{ backgroundColor: 'red', color: 'white' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Box className={classes.discountsBox} mb={2} mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={5} md={2} />
              <Grid item xs={5} md={2} style={{ fontWeight: 'bold' }}>
                {formatPrice(totalAccessories, { cents: true })}
              </Grid>
            </Grid>
          </Box>
          {!noDiscounts && (
            <Box className={classes.sectionSubTitle}>
              Discounts
              <IconButton onClick={handleClickDiscount} size="large">
                <AddBoxIcon className={classes.addButton} />
              </IconButton>
            </Box>
          )}
          {noDiscounts
            ? null
            : discount.fields.map((item, index) => (
                <Box key={item.key}>
                  <input
                    {...register(`vehicles.0.discounts.${index}.id`)}
                    type="hidden"
                    defaultValue={item.id}
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={5} md={2}>
                      <TextField
                        {...register(`vehicles.0.discounts.${index}.title`)}
                        label="Discount"
                        error={Boolean(
                          errors?.vehicles?.[0]?.discounts?.[index]?.title,
                        )}
                        helperText={
                          errors?.vehicles?.[0]?.discounts?.[index]?.title
                            ?.message
                        }
                      />
                    </Grid>
                    <Grid item xs={5} md={2}>
                      <TextField
                        {...register(`vehicles.0.discounts.${index}.price`)}
                        label="Price"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        error={Boolean(
                          errors?.vehicles?.[0]?.discounts?.[index]?.price,
                        )}
                        helperText={
                          errors?.vehicles?.[0]?.discounts?.[index]?.price
                            ?.message
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        onClick={() => discount.remove(index)}
                        style={{ backgroundColor: 'red', color: 'white' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          {!noDiscounts && (
            <Box className={classes.discountsBox} mb={2} mt={2}>
              <Grid container spacing={1}>
                <Grid item xs={5} md={2} />
                <Grid
                  item
                  xs={5}
                  md={2}
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  {formatPrice(totalDiscounts, { cents: true })}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent={mobile ? 'flex-start' : 'flex-end'}
        m={1}
      >
        <Button
          className={`${classes.cancelButton} ${classes.button}`}
          variant="contained"
          color="secondary"
          onClick={handleClickCancel}
        >
          Cancel
        </Button>
        <Button
          className={`${classes.nextButton} ${classes.button}`}
          disabled={
            createPitchMutation.loading ||
            updatePitchMutation.loading ||
            syncing
          }
          onClick={handleNext}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

VehicleDetailsStep.fragments = {
  pitch: PITCH_FRAGMENT,
  opportunity: gql`
    fragment VehicleDetailsStepOpportunity on Opportunity {
      _id
      stock_type
      customer {
        company_name
        fullname
        _id
      }
    }
  `,
};
VehicleDetailsStep.label = 'Interested Vehicle Details';

export default VehicleDetailsStep;
