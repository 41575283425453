import React from 'react';

/* external */
import { Controller, useFormContext } from 'react-hook-form';

/* Material UI */
import {
  FormControl,
  FormHelperText,
  FormLabel,
  useTheme,
} from '@mui/material';

const RadioControl = ({
  as,
  control,
  errors,
  name,
  label,
  FormControlProps = {},
  RadioProps,
  render,
  ...rest
}) => {
  const theme = useTheme();
  const formContext = useFormContext();
  return (
    <FormControl error={Boolean(errors?.[name])} {...FormControlProps}>
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control ?? formContext.control}
        name={name}
        render={({ field: { ref, ...field } }) =>
          React.cloneElement(as, {
            ...field,
            RadioProps: {
              // TODO RadioProps causes an error in console. It suggests radioprops
              style: { color: theme.actions.add.backgroundColor },
              size: 'small',
              ...RadioProps,
            },
          })
        }
        {...rest}
      />
      <FormHelperText>{errors?.[name]?.message}</FormHelperText>
    </FormControl>
  );
};

export default RadioControl;
