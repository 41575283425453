import React from 'react';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { snackBarSettings } from '../../../../constants';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import cloudinary from 'cloudinary-core';
import CardMedia from '@mui/material/CardMedia';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelperAppVideo from '../HelperAppVideo';
import VideoUploader from '../VideoUploader';
import { useUserContext } from 'components/MaterialUI/UserContext';
import ROVideoCard from './ROVideoCard';
import { cloudinaryConfig } from '../../../../constants';
import { cloudinaryVideoParams } from '../../const';
import { getFullDateTime } from 'utils';
import gql from 'graphql-tag';

const RODetailsVideos = ({
  isDesktop,
  ro,
  removeVideo,
  shareVideo,
  addVideo,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useUserContext();

  const handleDelete = video => {
    if (window.confirm('Delete video?')) {
      removeVideo({
        variables: {
          dealerId: ro.dealer_id,
          roNumber: ro.ro_number,
          videoId: video.id,
        },
      }).then(r => enqueueSnackbar('Video removed', snackBarSettings));
    }
  };

  const handleShare = video => {
    if (
      !video.shared &&
      !window.confirm('Share video with customer via SMS?')
    ) {
      return;
    }
    if (ro.permissions.allow.includes('share_media')) {
      shareVideo({
        variables: {
          dealerId: ro.dealer_id,
          roNumber: ro.ro_number,
          videoId: video.id,
        },
      })
        .then(r =>
          video.shared
            ? enqueueSnackbar(`Link revoked`, snackBarSettings)
            : enqueueSnackbar(`Link sent`, snackBarSettings),
        )
        .catch(e => {
          // should only occur in the case of GSL or DNC errors when sharing a video
          enqueueSnackbar(
            !video.shared
              ? 'Failed to share video. Is the customer on global suppression or "do not contact"?'
              : 'An error occurred',
            { variant: 'error' },
          );
        });
    }
  };

  const cloudinaryCore = new cloudinary.Cloudinary({
    cloud_name: cloudinaryConfig.cloud_name,
  });

  const columns = [
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'Actions',
      field: 'actions',
      filtering: false,
      render: rowData => (
        <>
          <Tooltip title={'Delete Video'}>
            <IconButton onClick={() => handleDelete(rowData)} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Share Video'}>
            <IconButton
              aria-label="share"
              onClick={() => handleShare(rowData)}
              color={rowData.shared ? 'secondary' : 'default'}
              size="large"
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Added',
      field: 'created_at',
      filtering: true,
      render: rowData =>
        getFullDateTime(
          rowData.created_at,
          me?.goUserProfile?.settings?.timezone,
        ),
      defaultSort: 'desc',
    },
    {
      title: 'Video',
      field: 'cloudinary_public_id',
      filtering: false,
      render: rowData => (
        <CardMedia
          src={cloudinaryCore.url(
            rowData.cloudinary_public_id,
            cloudinaryVideoParams,
          )}
          component="video"
          height={180}
          controls
          bgcolor="text.secondary"
        />
      ),
    },
    {
      title: 'Added by',
      field: 'created_by_display_name',
      filtering: false,
    },
  ];

  const tableOptions = {
    pageSize: ro.videos.length,
    pageSizeOptions: [{ value: -1, label: 'All' }],
    filtering: false,
  };

  return (
    <Container maxWidth="xl">
      {isDesktop && (
        <MaterialTable
          title={'Video Details'}
          data={ro.videos.filter(v => !v.deleted)}
          columns={columns}
          options={tableOptions}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <Box style={{ padding: '1rem' }}>
                  <h1>There are no videos for this vehicle.</h1>
                </Box>
              ),
            },
          }}
        />
      )}
      {!isDesktop && (
        <>
          {ro.videos.length === 0 && (
            <Box style={{ fontSize: '20px', padding: '1rem' }}>
              There are no videos for this vehicle.
            </Box>
          )}
          {ro.videos
            .filter(v => !v.deleted)
            .sort((a, b) => moment(b.created_at) - moment(a.created_at))
            .map(video => (
              <Grid
                item
                key={video.cloudinary_public_id}
                xs={12}
                style={{ paddingTop: '1rem' }}
              >
                <ROVideoCard
                  video={video}
                  onDelete={() =>
                    removeVideo({
                      variables: {
                        dealerId: ro.dealer_id,
                        roNumber: ro.ro_number,
                        videoId: video.id,
                      },
                    }).then(r =>
                      enqueueSnackbar('Video removed', snackBarSettings),
                    )
                  }
                  onShare={
                    ro.permissions.allow.includes('share_media') &&
                    (() =>
                      shareVideo({
                        variables: {
                          dealerId: ro.dealer_id,
                          roNumber: ro.ro_number,
                          videoId: video.id,
                        },
                      }).then(r =>
                        video.shared
                          ? enqueueSnackbar(`Link revoked`, snackBarSettings)
                          : enqueueSnackbar(`Link sent`, snackBarSettings),
                      ))
                  }
                />
              </Grid>
            ))}
        </>
      )}
      <Grid item xs={12} style={{ display: 'flex', paddingTop: '2rem' }}>
        <Grid container direction="row">
          <Grid item xs={6} sm={3} p={1}>
            <HelperAppVideo ro={ro} />
          </Grid>
          <Grid
            item
            xs={1}
            p={1}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Box>{` or `}</Box>
          </Grid>
          <Grid item xs={5} sm={3} p={1}>
            <VideoUploader
              params={ro.videoUploadParams}
              onUploaded={cloudinaryId =>
                addVideo({
                  variables: {
                    dealerId: ro.dealer_id,
                    roNumber: ro.ro_number,
                    cloudinaryId,
                  },
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

RODetailsVideos.fragments = {
  ro: gql`
    fragment RODetailsVideosRO on ROTrackerRO {
      dealer_id
      ro_number
      permissions {
        allow
      }
      videos {
        id
        deleted
        created_at
        cloudinary_public_id
        shared
      }
    }
  `,
};

export default RODetailsVideos;
