// External
import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

// Material UI
import {
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Dialog,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { SelectControl } from 'components/MaterialUI';

// Internal
import { LEAD_TYPES } from 'modules/leads/constants';

const SEND_LEAD = gql`
  mutation sendLead($conversationId: Int!, $lead: ConversationLeadInput!) {
    sms {
      sendLead(conversationId: $conversationId, lead: $lead)
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 25px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

const SendToDealerDialog = ({ conversationId, onClose, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { dealers, dealerId } = useDealerContext();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dealerId,
      leadType: '',
      leadMessage: '',
    },
  });

  const availableDealers = dealers?.sort((a, b) =>
    a.dealer_name.localeCompare(b.dealer_name),
  );

  const leadTypes = LEAD_TYPES?.sort((a, b) => a.name.localeCompare(b.name));

  const classes = useStyles();

  const [sendLead] = useMutation(SEND_LEAD, {
    onCompleted: () => {
      onClose();
      enqueueSnackbar('Lead sent!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Unable to send lead: ${err}`, {
        variant: 'error',
      });
    },
  });

  const onSubmit = ({ dealerId, leadType, leadMessage }) => {
    const selectedDealer = availableDealers.find(d => d.dealer_id === dealerId);

    sendLead({
      variables: {
        conversationId,
        lead: {
          message: leadMessage,
          dealer_id: dealerId,
          dealer_name: selectedDealer.dealer_name,
          lead_type: leadType,
        },
      },
    });
  };
  return (
    <Dialog maxWidth="sm" fullWidth {...rest} onClose={onClose}>
      <DialogTitle>Send Lead to Dealer</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '0 10px' }}>
        <DialogContent className={classes.root}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={10}>
              <SelectControl
                control={control}
                label="Dealer"
                name="dealerId"
                options={availableDealers}
                optionValueKey="dealer_id"
                optionNameKey="dealer_name"
                defaultValue={dealerId}
                nullDisplay=""
                variant="outlined"
                required
                rules={{
                  required: 'Required',
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <SelectControl
                control={control}
                label="Department (Lead Type)"
                name="leadType"
                options={leadTypes}
                optionValueKey="value"
                optionNameKey="name"
                nullDisplay=""
                variant="outlined"
                required
                rules={{
                  required: 'Required',
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                control={control}
                label="Message"
                {...register('leadMessage', {
                  required: { value: true, message: 'Required' },
                })}
                name="leadMessage"
                variant="outlined"
                size="medium"
                error={Boolean(errors.leadMessage?.message)}
                helperText={errors.leadMessage?.message}
                multiline
                minRows={3}
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button type="submit">Send</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SendToDealerDialog;
