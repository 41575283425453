import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useQuery } from '@apollo/react-hooks';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import { LoadingBackdrop } from 'components/MaterialUI';

const GET_SMS = gql`
  query getSMS($id: ID!) {
    getAttachmentVerificationSMS(_id: $id)
  }
`;

const SEND_CODE = gql`
  mutation sendCode($id: ID!) {
    verifyIdentityForAttachment(_id: $id)
  }
`;

const AttachmentDownloadDialog = ({
  id,
  attachmentKey,
  onClose,
  onSubmit,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [code, setCode] = useState('');
  const [sendCode, sendCodeMutation] = useMutation(SEND_CODE, {
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(gqlError =>
        enqueueSnackbar(
          gqlError.extensions?.response?.body?.message ?? gqlError.message,
          { variant: 'error' },
        ),
      ),
  });
  const getSMS = useQuery(GET_SMS, { variables: { id }, skip: !id });
  const cellPhone = getSMS.data?.getAttachmentVerificationSMS;

  useEffect(() => {
    setCode('');
    sendCodeMutation.reset();
  }, [attachmentKey]);

  const codeSent = sendCodeMutation.data?.verifyIdentityForAttachment;

  const handleSubmit = () => onSubmit({ code, attachmentKey });
  return (
    <Dialog open={Boolean(attachmentKey)} {...rest}>
      <LoadingBackdrop open={sendCodeMutation.loading}>
        Sending code...
      </LoadingBackdrop>
      <LoadingBackdrop open={getSMS.loading}>
        Verifying your GoAuto account SMS...
      </LoadingBackdrop>
      <DialogTitle>Download Attachment</DialogTitle>
      <DialogContent>
        {codeSent ? (
          <>
            Enter the code sent to your cell phone to download the attachment.
            <TextField
              label="Code"
              onChange={e => setCode(e.target.value)}
              value={code}
            />
          </>
        ) : cellPhone ? (
          <>
            Click{' '}
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendCode({ variables: { id } })}
            >
              Here
            </Button>{' '}
            to send a code to {cellPhone} to verify your identity.
          </>
        ) : (
          'No cell phone number on profile.'
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {codeSent && code.length === 6 && (
          <Button onClick={handleSubmit}>Submit</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AttachmentDownloadDialog;
