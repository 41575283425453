import React, { useState } from 'react';
import Button from '@mui/material/Button';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useUserContext } from 'components/MaterialUI/UserContext';

const RO_VIDEO_PARAMS = gql`
  query ro($dealerId: Int!, $roNumber: String!) {
    rotracker {
      ro(dealer_id: $dealerId, ro_number: $roNumber) {
        id
        videoUploadParams {
          signature
          upload_preset
          timestamp
          api_key
          folder
          url
        }
        videoUploadParamsWithTranscript {
          signature
        }
      }
    }
  }
`;

const makeVideoAppLink = (params, callback, token) => {
  const encodedParams = btoa(
    JSON.stringify({
      upload_to: 'https://api.cloudinary.com/v1_1/goauto-images/video/upload',
      upload_params: params,
    }),
  );
  const encodedCallback = window.encodeURIComponent(callback);
  return `io.goauto.markethelper://recordVideo/${encodedParams}/${encodedCallback}/${token}`;
};

const HelperAppVideo = ({ ro }) => {
  const [disabled, setDisabled] = useState(false);
  const { token } = useUserContext();
  const [getVideoParams] = useLazyQuery(RO_VIDEO_PARAMS, {
    variables: {
      dealerId: ro.dealer_id,
      roNumber: ro.ro_number,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      let params = data.rotracker.ro.videoUploadParams;
      params['transcript_signature'] =
        data.rotracker.ro.videoUploadParamsWithTranscript.signature;
      const url = makeVideoAppLink(
        params,
        window.location.href.replace('/overview', '/videos'),
        token,
      );
      setDisabled(false);
      window.location = url;
    },
  });

  const generateParams = () => {
    setDisabled(true);
    getVideoParams();
  };

  const urlParams = new URLSearchParams(window.location.search);
  const cloudinaryId = urlParams.get('public_id');
  window.history.replaceState({}, '', window.location.pathname);
  if (cloudinaryId) {
    setDisabled(false);
  }

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        onClick={() => generateParams()}
      >
        Open Video Recorder App
      </Button>
    </>
  );
};

HelperAppVideo.fragments = {
  ro: gql`
    fragment HelperAppVideoRO on ROTrackerRO {
      dealer_id
      ro_number
    }
  `,
};

export default HelperAppVideo;
