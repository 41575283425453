import React from 'react';

import LazySelect from './LazySelect';

const EARLIEST_YEAR = 1975;

const years = () => {
  const year = new Date().getFullYear() + 1;
  return Array.from(
    new Array(year - EARLIEST_YEAR + 1),
    (val, index) => year - index,
  );
};

export default function YearSelect(props) {
  return (
    <LazySelect
      label="Year"
      options={years().map(year => ({ id: year, name: year }))}
      {...props}
    />
  );
}
