import cloudinary from 'cloudinary-core';
import { camelCase, join, startCase, toLower } from 'lodash';
import moment from 'moment-timezone';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';

import { cloudinaryConfig, REFCODE_KEY, Regexps, URL } from 'constants.js';

export const cloudinaryCore = new cloudinary.Cloudinary(cloudinaryConfig);

export function dedupeArray(ids) {
  return [...new Set(ids)];
}

export function camelCaseToSnakeCase(camelCase) {
  return camelCase.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

export const capitalize = someString =>
  someString.replace(/(^|\s)([a-z])/g, (m, p1, p2) => p1 + p2.toUpperCase());

export const humanizeSnakeCase = snakeCase =>
  snakeCase ? capitalize(snakeCase.replace(/_/g, ' ')) : '';

export const titleize = x => startCase(camelCase(x));

export const titleCase = x => startCase(toLower(x));

export function isObject(value) {
  return value === Object(value) && !Array.isArray(value);
}

export function paramsSerializer(params) {
  return qs.stringify(
    Object.keys(params).reduce(
      (p, k) => ({
        ...p,
        [k]:
          typeof params[k] === 'object' ? JSON.stringify(params[k]) : params[k],
      }),
      {},
    ),
  );
}

/**
 * Converts an objects keys from `camelCase` to `snake_case` (recursively)
 */
export function keysToSnakeCase(original) {
  if (!isObject(original)) {
    return original;
  }

  const sanitized = {};

  Object.keys(original).forEach(oldKey => {
    const newKey = camelCaseToSnakeCase(oldKey);
    sanitized[newKey] = keysToSnakeCase(original[oldKey]);
  });

  return sanitized;
}

export function formatStringToDate(formatString) {
  return moment(formatString).toDate();
}

export function dateToFormatString(date, format) {
  return moment(date).format(format);
}

export const formatLongDateWithTimezone = date =>
  `${moment(date).format('MMM D Y @ h:mm A')} ${moment
    .tz(moment.tz.guess())
    .zoneAbbr()}`;

/**
 * Parses a number out of a string or returns 0
 * eg/ "123.22" => 123.22, undefined => 0, NaN => 0
 */
export const parseNumber = param => {
  const number = parseFloat(param);
  return !isNaN(number) ? number : 0;
};

/**
 * Format a number with commas*
 */
// TODO: refactor: this and numberWithCommas() are almost the same
export const formatNumber = x => {
  const number = parseNumber(x);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Attempts to append suffix to string.
 *
 * @param {string|null|undefined} str - The string to append to.
 * @param {string} suffix - The suffix to append to `str`.
 * @return {string} The string with suffix or an empty string if `str` is empty or not a string.
 */
export const withSuffix = (str, suffix) => {
  return str ? str + suffix : '';
};

/**
 * Parse a string date out of a moment
 */
export const processMoment = x =>
  moment.isMoment(x) ? x.format('YYYY-MM-DD') : x;

/**
 *
 * @param {Number|string} value - The value
 * @param {Object} options - Options
 * @param {string} [options.nullDisplay='-'] - The string to display when value is null
 * @param {string} [options.leading='$'] - The string that appears in front of the number
 * @param {boolean} [options.cents=false] - Display cents
 * @param {boolean} [options.commas=true] - Show thousands separator
 * @param {boolean} [options.zeroIfNull=false] - Display 0 if value is null
 * @returns {string} A formatted string
 */
export const formatPrice = (value, options = {}) => {
  const {
    nullDisplay = '-',
    leading = '$',
    cents = false, // 74% of 152 calls exclude cents (as of Jun 2022)
    commas = true,
    zeroIfNull = false,
  } = options;

  let display = nullDisplay;
  let number = parseFloat(value);
  if (isNaN(number)) {
    if (zeroIfNull) {
      number = 0;
      display = cents ? number.toFixed(2) : number.toFixed(0);
      display = `${leading}${display}`;
    }
  } else {
    // legit number
    display = cents ? number.toFixed(2) : number.toFixed(0);
    display = commas ? display.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : display;
    display = `${leading}${display}`;
  }

  return display;
};

export const formatOdometer = x =>
  x !== null ? `${formatNumber(String(x))} km` : '';

export const oneOrNull = someArray =>
  someArray.length === 1 ? someArray[0] : null;

export const oneIntOrNull = someArray =>
  someArray.length === 1 ? parseInt(someArray[0], 10) : null;

export const toIntNullOrUndefined = x =>
  x === undefined || x === null ? x : parseInt(x, 10);

export const toIntOrUndefined = x =>
  x === undefined || x === null || x === '' ? undefined : parseInt(x, 10);

export const toFloatNullOrUndefined = (x, options) => {
  let _x = x === undefined || x === null ? x : parseFloat(x);
  if (_x !== undefined && _x !== null && Number.isInteger(options?.round))
    _x =
      Math.round((_x + Number.EPSILON) * Math.pow(10, options.round)) /
      Math.pow(10, options.round);
  return _x;
};

export const toFloatOrUndefined = (x, options) =>
  toFloatNullOrUndefined(x, options) ?? undefined;

export const isValidVinCharacter = charCode => {
  // I, O, Q, i, o, q are invalid
  if ([73, 79, 81, 105, 111, 113].includes(charCode)) {
    return false;
  }

  if (
    (charCode > 47 && charCode < 58) || // numeric (0-9)
    (charCode > 64 && charCode < 91) || // upper alpha (A-Z)
    (charCode > 96 && charCode < 123) // lower alpha (a-z)
  ) {
    return true;
  }

  return false;
};

/**
 * Format a number as a percent
 * eg/ 4.99 => 4.99%
 */
export const formatPercent = (x, trailing = '%', options = {}) => {
  const number = parseNumber(x);

  return number.toFixed(options?.places ?? 2).toString() + trailing;
};

/**
 * Get a parameter from URL
 */
export function getUrlParameter(name) {
  const param = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]?${param}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
}

export const generatePhotoPreview = (photo, photoWidth, photoHeight) =>
  photo.cloudinary_public_id
    ? cloudinaryCore.url(photo.cloudinary_public_id, {
        width: parseInt(photoWidth, 10),
        height: photoHeight,
        crop: 'fit',
      })
    : `${URL.photoService}/image/show/${photoWidth}/${photoHeight || '_'}/${
        photo.photo_service_id || ''
      }.jpg`;

export function combineStrings(...strings) {
  const parts = strings.filter(x => x !== null && x !== undefined && x !== '');
  return parts.join(' ');
}

/**
 * Only works for numbers that we know are 2 digits ( or less).
 */
export const pad2 = num => `00${num}`.slice(-2);

/**
 * Formats a duration (input can be string, duration, or ISO duration string)
 */
export const formatDuration = duration => {
  const dur = moment.duration(duration);
  const d = dur.days();
  const h = dur.hours();
  const m = dur.minutes();
  const s = dur.seconds();
  const HMSDiff = `${pad2(h)}:${pad2(m)}:${pad2(s)}`;
  return `${d > 0 ? `${d} day${d > 1 ? 's ' : ' '}` : ''} ${
    !(h === 0 && m === 0 && s === 0) ? HMSDiff : ''
  }`;
};

export const parseGraphQLErrors = ({ graphQLErrors }) =>
  graphQLErrors
    ? graphQLErrors
        .map(
          ({ extensions: { response, exception } }) =>
            `${
              response
                ? response.body.errors.join('\n')
                : exception
                ? exception.message
                : 'An unknown error occurred.'
            }`,
        )
        .join(',')
    : '';

export const parseApolloError = e => `${e.message}:${parseGraphQLErrors(e)}`;

export const stripHTML = stripee =>
  stripee ? stripee.toString().replace(/(<([^>]+)>)/gi, '') : '';

// https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
export function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(
    JSON.parse(sessionStorage.getItem(key)) || defaultValue,
  );
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export const useGlobalPersistedState = (key, defaultValue) => {
  /* eslint-disable eqeqeq */
  const val = localStorage.getItem(key);
  const [state, setStateBase] = useState(
    val != undefined ? JSON.parse(val) : defaultValue,
  );

  const setState = newState => {
    localStorage.setItem(key, JSON.stringify(newState));
    setStateBase(newState);
  };

  return [state, setState];
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 0,
});

// TODO: refactor: this and formatNumber() are almost the same
export const numberWithCommas = x => {
  return x === null || x === undefined
    ? '-'
    : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const escapeRegExp = string =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

export const reverse = s => {
  return s === '' ? '' : reverse(s.substr(1)) + s.charAt(0);
};

export const refCode = card_id => {
  let cardId = parseInt('1' + reverse(String(card_id)) + '3');
  let code = '';
  const n = REFCODE_KEY.length;
  while (cardId > 0) {
    let r = cardId % n;
    cardId = parseInt(cardId / n);
    code = REFCODE_KEY[r] + code;
  }
  return code;
};

/**
 * Format a phone number.
 * Example: "7809876543" => "(780) 987-6543" if `pretty` is `true`
 * Example: "780-987-6543" => "7809876543" if `pretty` is `false`
 *
 * @param {string} str String with phone number.
 * @param {boolean} [pretty=true] If true return in (###) ###-#### format. Just return all the digits otherwise.
 * @param {RegExp} [format=Regexps.PhoneFormat.ALL] Optional phone format regex from {@link Regexps.PhoneFormat}.
 * @return {string} A string with the formatted phone number or null on failure.
 */
export function formatPhoneNumber(
  str,
  pretty = true,
  format = Regexps.PhoneFormat.ALL,
) {
  const m = (str ?? '').match(format);
  if (!m) {
    // Return null if nothing matched
    return null;
  }

  if (
    pretty &&
    (format === Regexps.PhoneFormat.NANP ||
      format === Regexps.PhoneFormat.ALL) &&
    m[1] !== null
  ) {
    // Only return pretty phone number for NANP matches (m[1] is part of NANP in ALL)
    return `(${m[1]}) ${m[2]}-${m[3]}`;
  } else {
    // Just join all the numbers together
    return join(m.slice(1), '');
  }
}

/**
 * Returns a valid VIN
 * @param {string} input String to format
 * @param {boolean} strict If true input should be a VIN with no additional characteres like spaces before/after.
 *                         If false it just does a case-insensitive search for a 'word' that looks like a VIN.
 * @returns The VIN or null
 */
export const formatVin = (input, strict = true) => {
  const regex = strict ? Regexps.Vin.STRICT : Regexps.Vin.RELAXED;
  return input.match(regex)?.[0];
};

// https://stackoverflow.com/a/57941438
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

// https://stackoverflow.com/a/63776262
export const useFirstRender = () => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};

export const memoize = func => {
  var memo = {};
  var slice = Array.prototype.slice;

  return function () {
    var args = slice.call(arguments);

    if (args in memo) return memo[args];
    else return (memo[args] = func.apply(this, args));
  };
};

/**
 * Returns initials using first and last name, ignoring other names. If only one
 * name was found, this returns a single character. Returns empty string if no
 * names were found.
 *
 * @export
 * @param {?string} name String with names.
 * @return {string} Initials or empty string if `name` is empty/null/undefined.
 */
export const initials = name => {
  const allInitials = name?.match(/\w+/g)?.map(s => s.charAt(0)) || [''];
  return allInitials.length > 1
    ? [allInitials[0], allInitials.at(-1)].join('')
    : allInitials[0];
};

export const lengthOrNull = itemsArray =>
  itemsArray?.length ? `(${itemsArray.length})` : null;

export const vehicleTitle = ({ year, make, model, trim }) =>
  [year, make, model, trim].filter(x => x && x !== 'None').join(' ');

export const stringify = rows =>
  rows
    .map(
      row =>
        row
          .map(v => v ?? '') // replace undefined or null with empty string
          .map(String) // convert every value to String
          .map(v => v.replaceAll('"', '""')) // escape double quotes
          .map(v => `"${v}"`) // quote it
          .join(','), // comma-separated
    )
    .join('\r\n'); // rows starting on new line

export const newWindowUrlHandler = url => () =>
  url ? window.open(url, '_blank') : null;

export const normalizePostalCode = (postalCode = '') =>
  postalCode
    ?.match(Regexps.PostalCodeFormat.RELAXED)
    ?.slice(1) // move on to only the capturing groups
    ?.map(x => x.toUpperCase())
    .join(' ') ?? '';

// https://stackoverflow.com/questions/37096367/how-to-convert-seconds-to-minutes-and-hours-in-javascript
export const formatSeconds = seconds => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';

  return hDisplay + mDisplay + sDisplay;
};

export const base64ToInt = b64 => {
  const binary = atob(b64);
  let result = BigInt(0);
  for (let i = 0; i < binary.length; i++) {
    result = (result << BigInt(8)) + BigInt(binary.charCodeAt(i));
  }
  return result;
};

export const urlSafeToBase64 = str =>
  str.replace(/-/g, '+').replace(/_/g, '/') +
  Array(((4 - (str.length % 4)) % 4) + 1).join('=');

export const getItsDangerousTokenAge = token => {
  /*
  Tokens signed with itsdangerous in python have 3 parts separated by '.'
  The second part contains the time it was issued as a base64 encoded integer
  This returns the age of a token in seconds
  */
  const parts = token.split('.');
  const ts = base64ToInt(urlSafeToBase64(parts[1]));
  return Math.floor(Date.now() / 1000) - Number(ts);
};
