import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/react-hooks';

const DECODE_QUERY = gql`
  query decodeVehicle($vin: String) {
    inventory {
      decodeVehicle(vin: $vin) {
        body_type_ids
        code_tables_stale
        drilldowns_stale
        drive_type_ids
        engine_compressor_ids
        engine_config_ids
        engine_cylinders
        engine_litres
        exterior_colours {
          desc
          ids
        }
        fuel_type_ids
        interior_colours {
          desc
          ids
        }
        make_ids
        model_ids
        style_ids
        styles {
          body_type_id
          drive_type_id
          engines {
            engine_config_id
            engine_cylinders
            engine_litres
            engine_compressor_id
            fuel_type_id
          }
          make_id
          make_name
          model_id
          model_name
          name
          stock_image {
            url
          }
          stock_photos {
            results {
              cloudinary_public_id
            }
          }
          style_id
          transmissions {
            id
            name
          }
          trim_variation
          trim
          year
        }
        transmission_ids
        trims
        trim_variations
        years
      }
    }
  }
`;

const useDecode = () => {
  const [decode, { data, loading, error }] = useLazyQuery(DECODE_QUERY, {
    fetchPolicy: 'network-only',
  });

  return {
    decode,
    data: data?.inventory?.decodeVehicle,
    loading,
    error,
  };
};

export default useDecode;
