import React from 'react';

import gql from 'graphql-tag';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import {
  AutocompleteControl,
  DealerPicker,
  SelectControl,
  TextFieldControl,
} from 'components/MaterialUI';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { Role } from 'constants.js';

const TaskStatusType = ['email', 'call', 'other', 'opportunity'];

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
      ]
    ) {
      display_name
      username
      role
      profile {
        image_id
      }
    }
  }
`;

const ADD_TASK = gql`
  mutation createTask($input: TaskInput!) {
    createTask(input: $input) {
      _id
    }
  }
`;

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput) {
    createNote(input: $input) {
      _id
    }
  }
`;

const UsernameAutocomplete = ({ control, dealer_id }) => {
  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [dealer_id],
    },
  });

  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <AutocompleteControl
      control={control}
      forcePopupIcon
      label="Assigned To"
      name="assignee"
      optionLabelKey="display_name"
      options={userData?.users ?? []}
      optionValueKey="username"
      readOnly={!dealer_id}
      required
      style={{ width: desktop ? 300 : 250 }}
    />
  ); // Re-render on dealer_id change
};

const AddTaskModal = ({ customer, handleClose, refetch }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      dealer_id: dealerId,
      status: 0,
      customer_id: customer._id,
    },
  });
  const [addTask] = useMutation(ADD_TASK, {
    onCompleted: () => {
      handleClose();
      refetch();
      enqueueSnackbar('Task added', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not add task error: ${err}`, {
        variant: 'error',
      });
    },
  });
  const [addNote] = useMutation(ADD_NOTE);

  const onSubmit = ({ note, ...input }) =>
    addTask({ variables: { input } }).then(() => {
      if (!isEmpty(note))
        addNote({
          variables: {
            input: {
              body: note,
              visibility: 'store_only',
              customer_id: customer._id,
              dealer_id: dealerId,
            },
          },
        });
    });

  return (
    <>
      <DialogTitle>New Task</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container direction="row" justifyContent="space-evenly">
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
                style={{ marginBottom: '1rem' }}
              >
                <Grid item>
                  <TextFieldControl
                    control={control}
                    defaultValue=""
                    fullWidth
                    label="Task"
                    name="description"
                  />
                </Grid>
                <Grid item>
                  <TextFieldControl
                    control={control}
                    fullWidth
                    label="Notes"
                    minRows={3}
                    multiline
                    name="note"
                  />
                </Grid>
                <Grid item>
                  <SelectControl
                    fullWidth
                    control={control}
                    name="type"
                    label="Type"
                    options={TaskStatusType.map(x => ({
                      name: capitalize(x),
                      value: x,
                    }))}
                    noNull
                    required
                  />
                </Grid>
                <Grid item>
                  <DealerPicker style={{ width: '100%' }} />
                </Grid>
                <Grid item>
                  <UsernameAutocomplete
                    control={control}
                    dealer_id={dealerId}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <DateTimePicker
                    {...field}
                    autoOk
                    variant="static"
                    format="YYYY-MM-DD hh:mm A"
                    required
                  />
                )}
                defaultValue={moment()}
                control={control}
                name="due_date"
                style={{ width: '250px' }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            style={theme.actions.confirm}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AddTaskModal;
