import { startCase, toLower } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { RadioControl, TextFieldControl } from 'components/MaterialUI';

import { CBB_CONDITIONS } from 'modules/inventory/const';

import { formatPrice } from 'utils';

import { usePermissionsContext } from '../../contexts/PermissionsContext';
import {
  CBBConditionRadioGroup,
  CBBStyleRadioGroup,
  CBBTrimRadioGroup,
} from './CBBRadioGroups';

const CBBDetailsDesktop = ({
  equipmentSelections,
  getPriceAdjustments,
  getRetailPrice,
  getRetailTotal,
  getWholesalePrice,
  getWholesaleTotal,
  handleEquipment,
  selectedCondition,
  selectedEquipment,
  styleNames,
  trimNames,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { update: canUpdate } = usePermissionsContext();

  return (
    <Container>
      <Typography style={{ padding: '1rem 1rem 1rem 0' }} variant="h6">
        <div style={{ paddingBottom: '1rem' }}>CBB Pricing</div>
      </Typography>
      <Grid container>
        <Grid item sm>
          <FormLabel style={{ fontWeight: 'bold' }} component="legend">
            Trim
          </FormLabel>
          <RadioControl
            RadioProps={{ style: { color: 'secondary' } }}
            name="cbb.trim"
            as={
              <CBBTrimRadioGroup
                trimNames={trimNames}
                style={{ display: 'flex', flexDirection: 'column' }}
              />
            }
            errors={errors.cbb?.trim}
          />
        </Grid>
        <Grid item sm>
          <FormLabel style={{ fontWeight: 'bold' }} component="legend">
            Style
          </FormLabel>
          <RadioControl
            RadioProps={{ style: { color: 'secondary' } }}
            as={
              <CBBStyleRadioGroup
                styleNames={styleNames}
                style={{ display: 'flex', flexDirection: 'column' }}
              />
            }
            errors={errors.cbb?.style}
            name="cbb.style"
          />
          <TextFieldControl
            control={control}
            name="cbb.vid"
            style={{ display: 'none' }}
          />
        </Grid>
        <Grid item sm>
          <FormLabel style={{ fontWeight: 'bold' }} component="legend">
            Equipment
          </FormLabel>
          <FormGroup>
            {equipmentSelections.map(({ option_code, description }) => (
              <FormControlLabel
                key={`${option_code}`}
                label={description}
                control={
                  <Checkbox
                    checked={selectedEquipment.includes(option_code)}
                    value={option_code}
                    onChange={canUpdate ? handleEquipment : () => {}}
                  />
                }
              />
            ))}
          </FormGroup>
          <TextFieldControl
            control={control}
            name="cbb.equipment"
            style={{ display: 'none' }}
          />
        </Grid>
        <Grid item sm>
          <FormControl component="fieldset">
            <FormLabel style={{ fontWeight: 'bold' }} component="legend">
              Condition
            </FormLabel>

            <RadioControl
              RadioProps={{ style: { color: 'secondary' } }}
              as={
                <CBBConditionRadioGroup
                  style={{ display: 'flex', flexDirection: 'column' }}
                />
              }
              errors={errors.cbb?.condition}
              name="cbb.condition"
              style={{ display: 'flex', flexDirection: 'column' }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Table style={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={1} />
            <TableCell colSpan={3}>WholeSale</TableCell>
            <TableCell colSpan={3}>Retail</TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={1} />
            <TableCell align={'left'}>Price</TableCell>
            <TableCell>Adjustment</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Adjustment</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {CBB_CONDITIONS.map(key => (
            <TableRow selected={key === selectedCondition} key={key}>
              <TableCell
                style={key === selectedCondition ? { fontWeight: 'bold' } : {}}
              >
                {startCase(toLower(key))}
              </TableCell>
              <TableCell>{formatPrice(getWholesalePrice(key))}</TableCell>
              <TableCell>{formatPrice(getPriceAdjustments(key))}</TableCell>
              <TableCell>{formatPrice(getWholesaleTotal(key))}</TableCell>
              <TableCell>{formatPrice(getRetailPrice(key))}</TableCell>
              <TableCell>{formatPrice(getPriceAdjustments(key))}</TableCell>
              <TableCell>{formatPrice(getRetailTotal(key))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default CBBDetailsDesktop;
