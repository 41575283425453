import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { gql } from '@apollo/client';

const AcceptCustomerOfferDialog = ({
  offer,
  open,
  onClose,
  onConfirm,
  ...rest
}) => {
  const [price, setPrice] = useState(offer.appraisal.customerAskingPrice || 0);

  const handleConfirm = () => onConfirm({ price: parseFloat(price) });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>Accept Customer Offer</DialogTitle>
      <DialogContent>
        <TextField
          label="Asking Price"
          value={offer.appraisal.customerAskingPrice}
          type="number"
          inputProps={{ step: '0.01' }}
          InputProps={{ startAdornment: '$' }}
          helperText="The customer's asking price"
          disabled
        />
        <TextField
          label="Purchase Price"
          value={price}
          onChange={e => setPrice(e.target.value)}
          type="number"
          inputProps={{ step: '0.01' }}
          InputProps={{ startAdornment: '$' }}
          helperText="The agreed upon purchase price"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          variant="contained"
          sx={{ color: 'white', bgcolor: 'green' }}
          onClick={handleConfirm}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AcceptCustomerOfferDialog.fragments = {
  offer: gql`
    fragment AcceptCustomerOfferDialogOffer on Offer {
      id
      appraisal {
        customerAskingPrice
      }
    }
  `,
};

export default AcceptCustomerOfferDialog;
