import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useForm } from 'react-hook-form';
import { SelectControl } from 'components/MaterialUI';
import { UPDATE_PERMISSIONS } from './mutations';

const SelectBillingDealer = ({
  billingDealerId,
  gatewayId,
  dealerOptions,
  classes,
  isDesktop,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      billingDealerId,
    },
  });
  const [updatePermissions] = useMutation(UPDATE_PERMISSIONS, {
    onCompleted: () => {
      enqueueSnackbar('Billing Dealer Updated', { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const onSubmit = data => {
    updatePermissions({
      variables: {
        gatewayId,
        data: {
          ...data,
        },
      },
    });
  };
  return (
    <Box mb="30px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item xs={12} sm={4}>
            <SelectControl
              name="billingDealerId"
              label="Billing Dealer"
              control={control}
              options={dealerOptions}
              fullWidth
              noNull
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: isDesktop ? 'left' : 'center' }}
          >
            <Button
              variant="contained"
              type="submit"
              className={classes.button}
            >
              Update Billing Dealer
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default SelectBillingDealer;
