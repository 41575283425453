import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

/* external */
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/* Material UI */
import Alert from '@mui/material/Alert';

import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

/* internal */
import {
  AppraisalStatus,
  PlaceholderVin,
  DECLINE_REASONS,
} from 'modules/used_vehicles/const';

import AppraisalDetails from '../components/AppraisalDetails';
import DashboardCardStatus from '../components/DashboardCardStatus';
import DatePickerDialog from '../components/DatePickerDialog';

const APPRAISAL_FRAGMENT = gql`
  fragment AppraisalFragment on Appraisal {
    appraisalStatus
    arrivedAt
    auctionAttempts
    billOfSaleUrl
    createdAt
    createdByUser {
      display_name
    }
    customerAppraisalSource
    dealerId
    deliveredAt
    declineReason
    inTransit
    isBuyNow
    isCustomerAppraisal
    offers {
      id
    }
    soldAt
    vin
  }
`;
const APPRAISAL_QUERY = gql`
  query getAppraisal($id: Int!) {
    appraisals {
      appraisal(id: $id) {
        id
        ...AppraisalFragment
        ...AppraisalDetailsAppraisal
        ...DashboardCardStatusAppraisal
      }
    }
  }
  ${APPRAISAL_FRAGMENT}
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
`;

const UPDATE_APPRAISAL = gql`
  mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
    appraisals {
      updateAppraisal(id: $id, data: $data) {
        id
        ...AppraisalFragment
        ...AppraisalDetailsAppraisal
        ...DashboardCardStatusAppraisal
      }
    }
  }
  ${APPRAISAL_FRAGMENT}
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
`;

const OWN_APPRAISAL = gql`
  mutation ownAppraisal($id: Int!) {
    appraisals {
      ownAppraisal(id: $id) {
        id
        ...AppraisalFragment
        ...AppraisalDetailsAppraisal
        ...DashboardCardStatusAppraisal
      }
    }
  }
  ${APPRAISAL_FRAGMENT}
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
`;

const DECLINE_CUSTOMER_APPRAISAL = gql`
  mutation declineAppraisal($id: Int!, $declineReason: String!) {
    appraisals {
      declineCustomerAppraisal(id: $id, declineReason: $declineReason) {
        id
        ...AppraisalFragment
        ...AppraisalDetailsAppraisal
        ...DashboardCardStatusAppraisal
      }
    }
  }
  ${APPRAISAL_FRAGMENT}
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
`;

const DELETE_APPRAISAL = gql`
  mutation deleteAppraisal($id: Int!) {
    appraisals {
      deleteAppraisal(id: $id) {
        id
        isDeleted
      }
    }
  }
`;

const RECEIVE_APPRAISAL = gql`
  mutation receiveAppraisal($appraisalId: Int!, $deliveredAt: String) {
    appraisals {
      receiveAppraisal(id: $appraisalId, deliveredAt: $deliveredAt) {
        id
        ...AppraisalFragment
        ...AppraisalDetailsAppraisal
        ...DashboardCardStatusAppraisal
      }
    }
  }
  ${APPRAISAL_FRAGMENT}
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
`;

const CREATE_BILL_OF_SALE_MUTATION = gql`
  mutation createBillOfSale($id: Int!) {
    appraisals {
      createBillOfSale(id: $id) {
        id
        billOfSaleUrl
      }
    }
  }
`;

const SEND_TO_BUY_NOW_MUTATION = gql`
  mutation sendToBuyNow($id: Int!) {
    appraisals {
      sendToBuyNow(id: $id) {
        id
        isBuyNow
      }
    }
  }
`;

const WholesaleAppraisalDetails = ({ id }) => {
  const history = useHistory();
  const { dealers, dealerId, setDealerId } = useDealerContext();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [declineReasonOpen, setDeclineReasonOpen] = useState(false);
  const [declineReasonState, setDeclineReasonState] = useState('');
  const [showArrivalDatePicker, setShowArrivalDatePicker] = useState(false);
  const [showDeliveryDatePicker, setShowDeliveryDatePicker] = useState(false);
  const [showRemoveBuyNowConfirm, setShowRemoveBuyNowConfirm] = useState(false);
  const [showOwnedConfirm, setShowOwnedConfirm] = useState(false);
  const [showDeletedConfirm, setShowDeletedConfirm] = useState(false);
  const [showSendToBuyNowConfirm, setShowSendToBuyNowConfirm] = useState(false);
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const { data, error, loading, refetch } = useQuery(APPRAISAL_QUERY, {
    variables: { id },
  });
  const { currentUser } = useUserContext();
  const timezone =
    currentUser?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [updateAppraisal] = useMutation(UPDATE_APPRAISAL);

  const [deleteAppraisal] = useMutation(DELETE_APPRAISAL);

  const [declineCustomerAppraisal] = useMutation(DECLINE_CUSTOMER_APPRAISAL);

  const [ownAppraisal] = useMutation(OWN_APPRAISAL);

  const [createBillOfSaleUrl, { loading: generatingBOS, error: BOSError }] =
    useMutation(CREATE_BILL_OF_SALE_MUTATION);

  const [receiveAppraisal] = useMutation(RECEIVE_APPRAISAL);

  const [sendToBuyNow] = useMutation(SEND_TO_BUY_NOW_MUTATION);

  const appraisal = data?.appraisals.appraisal || {};
  const {
    appraisalStatus,
    auctionAttempts,
    arrivedAt,
    billOfSaleUrl,
    createdAt,
    createdByUser,
    customerAppraisalSource,
    declineReason,
    deliveredAt,
    inTransit,
    isBuyNow,
    isCustomerAppraisal,
    soldAt,
    vin,
  } = appraisal;

  const handleMenuClick = e => setMenuAnchor(e.currentTarget);

  const handleDeclineReason = event => {
    setDeclineReasonState(event.target.value);
  };

  const handleMenuClose = () => setMenuAnchor(null);

  const handleCloseDialog = () => {
    // Just close everything here.  One handler to handle them all.
    setShowArrivalDatePicker(false);
    handleMenuClose();
    setShowOwnedConfirm(false);
    setShowDeletedConfirm(false);
    setShowDeliveryDatePicker(false);
    setShowSendToBuyNowConfirm(false);
    setShowRemoveBuyNowConfirm(false);
  };

  const notifyComplete = (message, options) => {
    handleCloseDialog();
    snackIt(message, options);
  };

  const handleSendToBuyNow = () =>
    sendToBuyNow({ variables: { id } }).then(
      () =>
        notifyComplete('Sent to buy now list successfully', {
          variant: 'success',
        }),
      err => notifyComplete(`There was an error. ${JSON.stringify(err)}`),
    );

  const handleSetArrivalDate = (dateTime, inTransit) =>
    updateAppraisal({
      variables: { id, data: { arrivedAt: dateTime, inTransit } },
    }).then(
      () =>
        notifyComplete('Updated arrival date and status successfully', {
          variant: 'success',
        }),
      err =>
        notifyComplete(`There was an error. ${JSON.stringify(err)}`, {
          variant: 'error',
        }), // TODO: nicer error message
    );

  const handleSetOwned = () => {
    ownAppraisal({ variables: { id } }).then(
      () =>
        notifyComplete('Updated appraisal to OWNED status.', {
          variant: 'success',
        }),
      err =>
        notifyComplete(`There was an error.  ${JSON.stringify(err)}`, {
          variant: 'error',
        }),
    );
  };

  const handleDeleteAppraisal = () => {
    deleteAppraisal({ variables: { id } }).then(
      () => {
        history.push('/used-vehicles/');
        notifyComplete('Appraisal Deleted.', {
          variant: 'success',
        });
      },
      err =>
        notifyComplete(`There was an error.  ${JSON.stringify(err)}`, {
          variant: 'error',
        }),
    );
  };

  const handleDeclineAppraisal = () => {
    declineCustomerAppraisal({
      variables: { id, declineReason: DECLINE_REASONS[declineReasonState] },
    }).then(
      () => {
        notifyComplete('Declined Offer', {
          variant: 'success',
        });
        setDeclineReasonOpen(false);
      },
      err => {
        notifyComplete(`There was an error.  ${JSON.stringify(err)}`, {
          variant: 'error',
        });
        setDeclineReasonOpen(false);
      },
    );
  };

  const handleCreateBOS = () =>
    createBillOfSaleUrl({ variables: { id } }).then(
      res =>
        window.open(
          res.data.appraisals.createBillOfSale.billOfSaleUrl,
          '_blank',
        ),
      () => {},
    );

  const handleSetDeliveryDate = dateTime =>
    receiveAppraisal({
      variables: { appraisalId: id, deliveredAt: dateTime },
    }).then(
      () =>
        notifyComplete('Updated delivery date successfully.', {
          variant: 'success',
        }),
      err =>
        notifyComplete(`There was an error ${JSON.stringify(err)}`, {
          variant: 'error',
        }),
    );

  const handleRemoveBuyNow = () =>
    updateAppraisal({ variables: { id, data: { isBuyNow: false } } }).then(
      () =>
        notifyComplete('Removed From Buy Now List', {
          variant: 'success',
        }),
      err =>
        notifyComplete(`There was an error.  ${JSON.stringify(err)}`, {
          variant: 'error',
        }),
    );
  // For now, we're restricting creating new offers only to appraisals that don't
  // have a bunch of existing declined offers.  (ie. no re-offers right now).  It was
  // causing some issues.
  const showOwnedActions = appraisalStatus === AppraisalStatus.OWNED;

  const showBiddingActions = [
    AppraisalStatus.BIDDING,
    AppraisalStatus.CUSTOMER_OFFERED,
  ].includes(appraisalStatus);

  const showSetArrivalDate =
    [
      AppraisalStatus.OWNED,
      AppraisalStatus.PENDING_AUCTION,
      AppraisalStatus.PENDING_OFFER,
      AppraisalStatus.AUCTIONED,
      AppraisalStatus.SOLD,
    ].includes(appraisalStatus) && !arrivedAt;

  const showEdit = ![
    AppraisalStatus.SOLD,
    AppraisalStatus.PENDING_OFFER,
    AppraisalStatus.PENDING_AUCTION,
    AppraisalStatus.ABANDONED,
  ].includes(appraisalStatus);

  const showDelete = [
    AppraisalStatus.OWNED,
    AppraisalStatus.BIDDING,
    AppraisalStatus.NEW,
  ].includes(appraisalStatus);

  const showBOSActions =
    appraisalStatus === AppraisalStatus.SOLD ||
    appraisalStatus === AppraisalStatus.AUCTIONED;

  const showSetDeliveryDate =
    appraisalStatus === AppraisalStatus.SOLD ||
    appraisalStatus === AppraisalStatus.AUCTIONED;

  const showSendToBuyNow = !isBuyNow && auctionAttempts > 0;

  const showMenu =
    showOwnedActions ||
    showBiddingActions ||
    showSetArrivalDate ||
    showEdit ||
    showDelete ||
    showBOSActions ||
    showSetDeliveryDate ||
    showSendToBuyNow;

  useEffect(() => {
    if (
      dealerId &&
      appraisal?.dealerId &&
      dealerId !== appraisal?.dealerId &&
      dealers.map(x => x.dealer_id).includes(appraisal?.dealerId)
    ) {
      setDealerId(appraisal.dealerId);
      snackIt(
        `Set current dealer to: ${
          dealers.find(x => x.dealer_id === appraisal.dealerId)?.dealer_name
        } `,
        { variant: 'warning' },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerId, appraisal, dealers]);

  if (error) return <ErrorDisplay error={error} />;
  if (BOSError) return <ErrorDisplay error={BOSError} />;
  if (loading) return <LoadingBackdrop open={loading} />;

  return (
    <>
      <LoadingBackdrop open={generatingBOS}>
        Generating Bill of Sale
      </LoadingBackdrop>
      <Box padding={1}>
        <DealerPicker disabled />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
      >
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
          Summary for Item #{id}
        </Typography>
        <DashboardCardStatus
          appraisal={appraisal}
          dealer={false}
          showIcon={false}
          showChip={true}
        />
        {showMenu && (
          <IconButton onClick={handleMenuClick} size="large">
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
      {(!vin || vin === PlaceholderVin) && (
        <Alert severity="warning">
          There is no VIN for this appraisal. You must enter a VIN to set it to
          OWNED status.
        </Alert>
      )}

      {showMenu && (
        <Menu
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          {showBOSActions && (
            <MenuItem onClick={handleCreateBOS}>
              Create New Bill Of Sale
            </MenuItem>
          )}
          {billOfSaleUrl && showBOSActions && (
            <MenuItem onClick={() => window.open(billOfSaleUrl, '_blank')}>
              Download Bill Of Sale
            </MenuItem>
          )}
          {showEdit && (
            <MenuItem
              onClick={() =>
                history.push(`/used-vehicles/create-vehicle/${id}`)
              }
            >
              Edit
            </MenuItem>
          )}
          {isBuyNow && (
            <MenuItem onClick={() => setShowRemoveBuyNowConfirm(true)}>
              Remove from Buy Now
            </MenuItem>
          )}
          {showSetArrivalDate && (
            <MenuItem onClick={() => setShowArrivalDatePicker(true)}>
              Set Arrival Date
            </MenuItem>
          )}
          {showOwnedActions && (
            <>
              <MenuItem
                onClick={() =>
                  history.push(`/used-vehicles/created/${id}?auction=1`)
                }
              >
                Send to Auction
              </MenuItem>
              <MenuItem
                onClick={() =>
                  history.push(`/used-vehicles/created/${id}?sell=1`)
                }
              >
                Sell to Dealer
              </MenuItem>
            </>
          )}
          {showDelete && (
            <MenuItem onClick={() => setShowDeletedConfirm(true)}>
              Delete Appraisal
            </MenuItem>
          )}
          {showSendToBuyNow && (
            <MenuItem onClick={() => setShowSendToBuyNowConfirm(true)}>
              Send to Buy Now
            </MenuItem>
          )}
          {showBiddingActions && (
            <MenuItem
              disabled={!vin || vin === PlaceholderVin}
              onClick={() => setShowOwnedConfirm(true)}
            >
              Set As Owned
            </MenuItem>
          )}
          {showSetDeliveryDate && (
            <MenuItem onClick={() => setShowDeliveryDatePicker(true)}>
              Set Delivery Date
            </MenuItem>
          )}
        </Menu>
      )}

      {/* Created/offered/arrived/delivered info.  Apparently this is all dynamic
      and will show different items based on the status of the appraisal.  And it's
      different for wholesaler and dealer.  */}
      <Box display="flex" justifyContent="space-between" padding={1}>
        <Box display="flex">
          <Box display="flex" flexDirection="column">
            <Box textAlign="right">Created:</Box>
            {arrivedAt ? (
              <Box textAlign="right">Arrived:</Box>
            ) : appraisalStatus === AppraisalStatus.DECLINED ? (
              <Box textAlign="right">
                <b>Declined:</b>
              </Box>
            ) : (
              'Arrived:'
            )}
          </Box>
          {/* Created  */}
          <Box display="flex" flexDirection="column" paddingLeft="0.5rem">
            <Box textAlign="left">
              {createdAt ? (
                <FormatDateTZ date={createdAt} format="ll" />
              ) : (
                'N/A'
              )}
            </Box>
            <Box textAlign="left">
              {arrivedAt ? (
                <FormatDateTZ date={arrivedAt} format="ll" />
              ) : appraisalStatus === AppraisalStatus.DECLINED ? (
                <Box textAlign="left">
                  <b>{declineReason}</b>
                </Box>
              ) : (
                'N/A'
              )}
            </Box>
          </Box>
        </Box>

        <Box display="flex">
          <Box display="flex" flexDirection="column">
            <Box textAlign="right">{soldAt ? 'Sold:' : <>&nbsp;</>}</Box>
            <Box textAlign="right">
              {' '}
              {appraisalStatus === AppraisalStatus.SOLD ||
              appraisalStatus === AppraisalStatus.AUCTIONED
                ? 'Delivered:'
                : 'Offered by:'}
            </Box>
            {customerAppraisalSource && <Box textAlign="right">Source:</Box>}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            paddingLeft="0.5rem"
            minWidth="5rem"
          >
            <Box textAlign="left">
              {soldAt ? (
                <FormatDateTZ date={soldAt} format="ll" />
              ) : (
                <>&nbsp;</>
              )}
            </Box>
            <Box textAlign="left">
              {appraisalStatus === AppraisalStatus.SOLD ||
              appraisalStatus === AppraisalStatus.AUCTIONED ? (
                <FormatDateTZ date={deliveredAt} format="ll" />
              ) : (
                createdByUser?.display_name
              )}
            </Box>
            {customerAppraisalSource && (
              <Box textAlign="left">{customerAppraisalSource}</Box>
            )}
          </Box>
        </Box>
      </Box>

      <AppraisalDetails appraisal={appraisal} refetch={refetch} />

      <DatePickerDialog
        open={showArrivalDatePicker}
        value={arrivedAt}
        onClose={handleCloseDialog}
        onConfirm={handleSetArrivalDate}
        timezone={timezone}
        inTransit={inTransit}
        arrivedAt={true}
      />

      <DatePickerDialog
        open={showDeliveryDatePicker}
        value={deliveredAt}
        onClose={handleCloseDialog}
        onConfirm={handleSetDeliveryDate}
        timezone={timezone}
      />

      <ConfirmDialog
        text="Are you sure you want to set this appraisal to OWNED status?"
        onConfirm={handleSetOwned}
        isOpen={showOwnedConfirm}
        onClose={handleCloseDialog}
      />

      <ConfirmDialog
        text="Are you sure you want to delete this Appraisal?"
        onConfirm={handleDeleteAppraisal}
        isOpen={showDeletedConfirm}
        onClose={handleCloseDialog}
      />

      <ConfirmDialog
        text="Are you sure you want to send this to the buy-now list?"
        onConfirm={handleSendToBuyNow}
        isOpen={showSendToBuyNowConfirm}
        onClose={handleCloseDialog}
      />
      <ConfirmDialog
        text="Are you sure you want to remove this from the buy-now list?"
        onConfirm={handleRemoveBuyNow}
        isOpen={showRemoveBuyNowConfirm}
        onClose={handleCloseDialog}
      />
      <Dialog
        maxWidth="sm"
        open={declineReasonOpen}
        onClose={() => setDeclineReasonOpen(false)}
        fullWidth
      >
        <DialogTitle>Set Decline Reason</DialogTitle>
        <DialogContent>
          <Select
            value={declineReasonState}
            onChange={handleDeclineReason}
            style={{ width: '250px' }}
            noNull
          >
            {Object.entries(DECLINE_REASONS).map(([key, value]) => {
              return (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          <DialogActions>
            <Button onClick={() => setDeclineReasonOpen(false)}>Cancel</Button>
            <Button
              disabled={!declineReasonState}
              onClick={handleDeclineAppraisal}
            >
              Accept
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {isCustomerAppraisal &&
        appraisalStatus === AppraisalStatus.CUSTOMER_OFFERED && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: '#BF0000',
                color: 'white',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              onClick={() => setDeclineReasonOpen(true)}
            >
              Decline Offer
            </Button>
          </Box>
        )}
    </>
  );
};

export default WholesaleAppraisalDetails;
