/* external */
import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ErrorMessage } from '@hookform/error-message';

/* Material UI */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Step,
  StepButton,
  StepContent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import {
  LoadingBackdrop,
  SelectControl,
  TextFieldControl,
} from 'components/MaterialUI';

/* internal */
import { errorHandler } from '../utils';
import StepActions from './StepActions';

// TODO: ensure unique names?  Or get name + city?
// Find similar names when entering a new seller?
// Don't bother sorting these here, since a) there won't be that many of them,
// and b) we could add new ones here, in which case they'll need to be
// re-sorted anyway.
const GET_SELLERS = gql`
  query getSellers {
    appraisals {
      sellers {
        id
        address
        name
      }
    }
  }
`;

const GET_BUYERS = gql`
  query getBuyers($filters: [QueryFilter]) {
    appraisals {
      buyers(filters: $filters) {
        user {
          username
          display_name
        }
      }
    }
  }
`;

const CREATE_SELLER = gql`
  mutation createSeller($seller: SellerInput!) {
    appraisals {
      createSeller(seller: $seller) {
        id
        address
        name
      }
    }
  }
`;

const alphaAsc = ({ name: a }, { name: b }) => a.localeCompare(b);

const StepSix = ({
  appraisal,
  label,
  onBack,
  onNext,
  setActiveStep,
  setIsDirty,
  stepDirty,
  ...rest
}) => {
  const theme = useTheme();
  const { id } = appraisal;

  const UPDATE_APPRAISAL = gql`
    mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
      appraisals {
        updateAppraisal(id: $id, data: $data) {
          id
          ...StepSixAppraisal
        }
      }
    }
    ${StepSix.fragments.appraisal}
  `;

  const { data: sellersData, loading: loadingSellers } = useQuery(GET_SELLERS);
  const { data: buyersData, loading: loadingBuyers } = useQuery(GET_BUYERS);

  const [createSeller, { loading: creatingSeller }] = useMutation(
    CREATE_SELLER,
    {
      update(cache, { data }) {
        const cachedQuery = cache.readQuery({ query: GET_SELLERS });
        cachedQuery.appraisals.sellers = [
          ...cachedQuery.appraisals.sellers,
          data.appraisals.createSeller,
        ];
        cache.writeQuery({ query: GET_SELLERS, data: cachedQuery });
      },
    },
  );

  const [updateAppraisal, { loading: updating }] =
    useMutation(UPDATE_APPRAISAL);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setError,
    setValue,
  } = useForm({
    defaultValues: appraisal,
  });

  useEffect(() => {
    reset(appraisal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisal]);

  useEffect(() => {
    if (isDirty && !stepDirty) setIsDirty();
    else {
      if (!isDirty && stepDirty) setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, setIsDirty]);

  const { enqueueSnackbar } = useSnackbar();

  const sellerForm = useForm({ defaultValues: { name: '', address: '' } });

  // Not sure why the user resolver is returning the input username even though
  // the user doesn't exist.  Oh well, filter by display_name instead I guess?
  // Update: Checked the user loader, and it's the one returning { username } if
  // there's no user found.  Strange.
  const buyers =
    buyersData?.appraisals.buyers
      .filter(x => x?.user?.display_name)
      .map(x => x.user) || [];
  const sellers = sellersData?.appraisals.sellers || [];

  const onSubmitSeller = ({ name, address }) => {
    const value = sellers.findIndex(
      seller => seller.name.toLowerCase() === name.toLowerCase(),
    );

    if (value === -1) {
      createSeller({ variables: { seller: { name, address } } }).then(
        ({ data }) => {
          sellerForm.reset();
          setValue('sellerId', data.appraisals.createSeller.id, {
            shouldDirty: true,
          });
        },
        err => errorHandler(enqueueSnackbar, sellerForm.setError)(err),
      );
    } else {
      enqueueSnackbar(
        `That seller already exists. Please select it from the dropdown.`,
      );
    }
  };

  const processData = data => ({
    buyer:
      buyers.find(({ username }) => username === data.buyerUsername)
        ?.display_name || '',
    sellerId: data.sellerId,
  });

  const onSubmit = data => {
    if (!appraisal.value) {
      enqueueSnackbar(
        `Appraisal must have an appraisal value to be created. Please return to Step 5 and set an appraisal value.`,
      );
    } else if (isDirty) {
      updateAppraisal({ variables: { id, data: processData(data) } }).then(
        () => onNext(),
        err => errorHandler(enqueueSnackbar, setError)(err),
      );
    } else onNext();
  };

  const findSeller = id => {
    const matchedSeller = sellers.filter(seller => seller.id === id);
    return matchedSeller.length > 0 ? String(matchedSeller[0].name) : null;
  };

  const loading = loadingBuyers || loadingSellers || creatingSeller || updating;

  return (
    <Step key={label} {...rest}>
      <StepButton disabled={!appraisal.id} onClick={() => setActiveStep(5)}>
        {label}
      </StepButton>
      <StepContent>
        <LoadingBackdrop open={loading} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormControl style={{ width: '65%' }}>
              <InputLabel shrink>Seller</InputLabel>
              <Controller
                control={control}
                defaultValue={null}
                name="sellerId"
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => onChange(data)}
                    options={sellers.sort(alphaAsc).map(seller => seller.id)}
                    getOptionLabel={option =>
                      option ? findSeller(option) : 'null'
                    }
                    renderOption={(props, option) => (
                      <li {...props}>
                        <span>{findSeller(option)}</span>
                      </li>
                    )}
                    renderInput={params => <TextField {...params} />}
                    style={{ paddingTop: '1rem' }}
                  />
                )}
              />
              <ErrorMessage as="div" errors={errors} name="sellerId" />
            </FormControl>
          </div>
          <div style={{ paddingTop: '1rem' }}>
            <Typography variant="h5">OR</Typography>
          </div>
          <div>
            <TextFieldControl
              name="name"
              control={sellerForm.control}
              label="Seller Name"
              style={{ width: '65%' }}
            />
            <TextFieldControl
              name="address"
              control={sellerForm.control}
              label="Seller Address"
              style={{ width: '65%' }}
            />
            <div style={{ padding: '1rem 0' }}>
              <Button
                disabled={!sellerForm.formState.isDirty}
                variant="contained"
                style={theme.actions.confirm}
                onClick={sellerForm.handleSubmit(onSubmitSeller)}
              >
                Add Seller
              </Button>
            </div>
          </div>
          <div>
            <Box fontSize="16px">Select the Buyer:</Box>
            <FormControl style={{ width: '65%' }}>
              <SelectControl
                control={control}
                defaultValue={null}
                name="buyerUsername"
                label="Buyer"
                options={buyers.sort((a, b) =>
                  a.display_name.localeCompare(b.display_name),
                )}
                optionValueKey="username"
                optionNameKey="display_name"
              />
            </FormControl>
          </div>

          <StepActions
            onBack={onBack}
            onNext={() => {}}
            nextButtonProps={{ disabled: loading, type: 'submit' }}
            nextLabel="Finish!"
          />
        </form>
      </StepContent>
    </Step>
  );
};

StepSix.fragments = {
  appraisal: gql`
    fragment StepSixAppraisal on Appraisal {
      id
      buyer
      buyerUsername
      sellerId
    }
  `,
};
export default StepSix;
