import React from 'react';
import { useSnackbar } from 'notistack';

import { Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  ACCOUNTING_LIST,
  ADDS_CHECKLIST,
  CAR_POLICY,
  GST_EXEMPT_LIST,
  DOCUMENTS_CHECKLIST,
  STANDARD_CHECKLIST,
  STIPS_CHECKLIST,
  GENERATE_TRADE_LIST,
  US_DISCLAIMER,
  USED_ONLY_LIST,
  LUXURY_TAX_LIST,
  BC_IZEV_CEV_CHECKLIST,
  BC_PST_CHECKLIST,
  ON_DISCLOSURE_CHECKLIST,
  DEAL_AUDIT_CHECKLIST,
  AUDI_CHECKLIST,
  BMW_CHECKLIST,
  FORD_CHECKLIST,
  GENESIS_CHECKLIST,
  GM_CHECKLIST,
  HONDA_CHECKLIST,
  HYUNDAI_CHECKLIST,
  INFINITI_CHECKLIST,
  JLR_CHECKLIST,
  KIA_CHECKLIST,
  MAZDA_CHECKLIST,
  MITSUBISHI_CHECKLIST,
  NISSAN_CHECKLIST,
  PORSCHE_CHECKLIST,
  STELLANTIS_CHECKLIST,
  SUBARU_CHECKLIST,
  TOYOTA_CHECKLIST,
  VOLKSWAGEN_CHECKLIST,
} from './const';

import { Status } from 'constants.js';

import {
  AFCheckboxGroup,
  SingleCheckboxGroup,
  YesNoCheckboxGroup,
} from './CheckboxGroups';

const useStyles = makeStyles(theme => ({
  checkbox: {
    textAlign: 'center',
  },
  text: {
    paddingTop: '9px',
  },
  clickable: {
    paddingTop: '9px',
    textDecoration: 'underline',
    color: 'blue',
  },
  customerInfo: {
    backgroundColor: 'lightgrey',
    padding: '5px',
  },
  subtitle: {
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  subtitleBkg: {
    backgroundColor: theme.colours.greys.grey2,
    color: 'white',
    padding: '5px',
    marginTop: '5px',
  },
  alignRight: {
    textAlign: 'right',
  },
  indentCheckbox: {
    textAlign: 'center',
    paddingLeft: '15px',
  },
  indentText: {
    paddingTop: '9px',
    paddingLeft: '15px',
  },
  titleGridText: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
}));

const TitleGridAF = () => {
  const classes = useStyles();
  return (
    <Grid container direction="row" style={{ marginTop: '5px' }}>
      <Grid item xs={1} className={classes.checkbox}>
        <Typography className={classes.titleGridText}>A</Typography>
      </Grid>
      <Grid item xs={2} className={classes.checkbox}>
        <Typography className={classes.titleGridText}>F</Typography>
      </Grid>
    </Grid>
  );
};

const TitleGridYesNo = () => {
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <Grid item xs={2} className={classes.checkbox}>
        <Typography className={classes.titleGridText}>Yes</Typography>
      </Grid>
      <Grid item xs={1} className={classes.checkbox}>
        <Typography className={classes.titleGridText}>No</Typography>
      </Grid>
      <Grid item xs={2} className={classes.checkbox}>
        <Typography className={classes.titleGridText}>N/A</Typography>
      </Grid>
    </Grid>
  );
};

const Checklists = ({
  opportunity,
  trades,
  generatePDF,
  fields,
  setFields,
  programName,
  setProgramName,
  fordPlan,
  setFordPlan,
  dealer,
  generate2CarDealPDF,
  generateFirstNationsPDF,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const trade1Fields = trades?.length > 0 ? GENERATE_TRADE_LIST(1) : [];
  const trade2Fields = trades?.length > 1 ? GENERATE_TRADE_LIST(2) : [];

  const trade1Name = trades
    ? [trades[0]?.year, trades[0]?.make_name, trades[0]?.model_name]
        .filter(x => x)
        .join(' ')
    : '';

  const trade2Name =
    trades && trades.length > 1
      ? [trades[1]?.year, trades[1]?.make_name, trades[1]?.model_name]
          .filter(x => x)
          .join(' ')
      : '';

  const generatePrintablePDF = (id, pdf_type) => {
    generatePDF({ variables: { id, pdf_type } }).then(
      ({ data }) => window.open(data.generateDeallogPDF.key, '_blank'),
      error =>
        enqueueSnackbar(`Error generating PDF: ${JSON.stringify(error)}`, {
          variant: 'error',
        }),
    );
  };
  const handleCheck = event => {
    let updateFields = [...fields, event.target.name];
    if (fields.includes(event.target.name)) {
      updateFields = updateFields.filter(field => field !== event.target.name);
    }
    setFields(updateFields);
  };

  const isChecked = field => {
    return fields.includes(field);
  };

  const isLocked = Status.DEALLOG_LOCKED.includes(opportunity.status);

  const oemChecklist = oem => {
    switch (oem) {
      case 'Audi':
        return AUDI_CHECKLIST;
      case 'BMW':
        return BMW_CHECKLIST;
      case 'Ford':
        return FORD_CHECKLIST;
      case 'Genesis':
        return GENESIS_CHECKLIST;
      case 'GM':
        return GM_CHECKLIST;
      case 'Honda':
        return HONDA_CHECKLIST;
      case 'Hyundai':
        return HYUNDAI_CHECKLIST;
      case 'Infiniti':
        return INFINITI_CHECKLIST;
      case 'JLR':
        return JLR_CHECKLIST;
      case 'Kia':
        return KIA_CHECKLIST;
      case 'Mazda':
        return MAZDA_CHECKLIST;
      case 'Mitsubishi':
        return MITSUBISHI_CHECKLIST;
      case 'Nissan':
        return NISSAN_CHECKLIST;
      case 'Porsche':
        return PORSCHE_CHECKLIST;
      case 'Stellantis':
        return STELLANTIS_CHECKLIST;
      case 'Subaru':
        return SUBARU_CHECKLIST;
      case 'Toyota':
        return TOYOTA_CHECKLIST;
      case 'Volkswagen':
        return VOLKSWAGEN_CHECKLIST;
      default:
        return [];
    }
  };

  const programNameDescText = ACCOUNTING_LIST[3].desc;
  const fordPlanDescText = FORD_CHECKLIST[0].desc;

  return (
    <>
      {/* COLUMN 1 */}
      <Grid container item xs={4} direction="column">
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>
            Accounting Checklist
          </Typography>
        </Grid>
        <TitleGridAF />
        {ACCOUNTING_LIST.map(item => (
          <Grid container key={item.desc}>
            <AFCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              isLocked={isLocked}
              opportunity={opportunity}
            />
            {item.desc === programNameDescText && (
              <Grid item xs={12}>
                <TextField
                  label="Program Name"
                  value={programName}
                  onChange={e => setProgramName(e.target.value)}
                  variant="outlined"
                  style={{ width: '100%', margin: '10px 0' }}
                />
              </Grid>
            )}
          </Grid>
        ))}
        {trades?.length > 0 && (
          <>
            <Grid className={classes.subtitleBkg}>
              <Typography className={classes.subtitle}>
                Trade 1 - {trade1Name}
              </Typography>
            </Grid>
            <TitleGridAF />
            {trade1Fields.map(item => (
              <Grid container key={item.desc}>
                <AFCheckboxGroup
                  item={item}
                  isChecked={isChecked}
                  handleCheck={handleCheck}
                  generatePrintablePDF={generatePrintablePDF}
                  isLocked={isLocked}
                  opportunity={opportunity}
                />
              </Grid>
            ))}
          </>
        )}
        {trades?.length > 1 && (
          <>
            <Grid className={classes.subtitleBkg}>
              <Typography className={classes.subtitle}>
                Trade 2 - {trade2Name}
              </Typography>
            </Grid>
            <TitleGridAF />
            {trade2Fields.map(item => (
              <Grid container key={item.desc}>
                <AFCheckboxGroup
                  item={item}
                  isChecked={isChecked}
                  handleCheck={handleCheck}
                  generatePrintablePDF={generatePrintablePDF}
                  isLocked={isLocked}
                  opportunity={opportunity}
                />
              </Grid>
            ))}
          </>
        )}
        {opportunity.deal_type === 'Used' && (
          <>
            <Grid className={classes.subtitleBkg}>
              <Typography className={classes.subtitle}>
                Used Deals Only
              </Typography>
            </Grid>
            <TitleGridAF />
            {USED_ONLY_LIST.map(item => (
              <Grid container key={item.desc}>
                <AFCheckboxGroup
                  item={item}
                  isChecked={isChecked}
                  handleCheck={handleCheck}
                  generatePrintablePDF={generatePrintablePDF}
                  isLocked={isLocked}
                  opportunity={opportunity}
                />
              </Grid>
            ))}
          </>
        )}
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>
            2 Car Deal Policy
          </Typography>
        </Grid>
        <TitleGridAF />
        {CAR_POLICY.map(item => (
          <Grid container key={item.desc}>
            <AFCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              generatePrintablePDF={generatePrintablePDF}
              isLocked={isLocked}
              opportunity={opportunity}
              generate2CarDealPDF={generate2CarDealPDF}
              generateFirstNationsPDF={generateFirstNationsPDF}
            />
          </Grid>
        ))}
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>US Disclaimer</Typography>
        </Grid>
        <TitleGridAF />
        {US_DISCLAIMER.map(item => (
          <Grid container key={item.desc}>
            <AFCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              generatePrintablePDF={generatePrintablePDF}
              isLocked={isLocked}
              opportunity={opportunity}
            />
          </Grid>
        ))}
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>
            First Nations GST Exempt
          </Typography>
        </Grid>
        <TitleGridAF />
        {GST_EXEMPT_LIST.map(item => (
          <Grid container key={item.desc}>
            <AFCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              isLocked={isLocked}
              opportunity={opportunity}
              generate2CarDealPDF={generate2CarDealPDF}
              generateFirstNationsPDF={generateFirstNationsPDF}
            />
          </Grid>
        ))}
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>
            Federal Luxury Tax
          </Typography>
        </Grid>
        <TitleGridAF />
        {LUXURY_TAX_LIST.map(item => (
          <Grid container key={item.desc}>
            <AFCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              isLocked={isLocked}
              opportunity={opportunity}
            />
          </Grid>
        ))}
      </Grid>
      {/* COLUMN 2 */}
      <Grid container item xs={4} direction="column">
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>Documents</Typography>
        </Grid>
        {DOCUMENTS_CHECKLIST.map(item => (
          <Grid container direction="row" key={item.desc}>
            <SingleCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              isLocked={isLocked}
            />
          </Grid>
        ))}
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>Standard</Typography>
        </Grid>
        {STANDARD_CHECKLIST.map(item => (
          <>
            <Grid container direction="row" key={item.desc}>
              <SingleCheckboxGroup
                item={item}
                isChecked={isChecked}
                handleCheck={handleCheck}
                isLocked={isLocked}
              />
            </Grid>
          </>
        ))}
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>Finance Adds</Typography>
        </Grid>
        {ADDS_CHECKLIST.map(item => (
          <Grid container direction="row" key={item.desc}>
            <SingleCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              isLocked={isLocked}
            />
          </Grid>
        ))}
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>Stips</Typography>
        </Grid>
        {STIPS_CHECKLIST.map(item => (
          <Grid container direction="row" key={item.desc}>
            <SingleCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              isLocked={isLocked}
            />
          </Grid>
        ))}
      </Grid>
      {/* COLUMN 3 */}
      <Grid container item xs={4} direction="column">
        {/* BC DEALERS ONLY */}
        {dealer.province === 'BC' && (
          <>
            <Grid className={classes.subtitleBkg}>
              <Typography className={classes.subtitle}>IZEV & CEV</Typography>
            </Grid>
            {BC_IZEV_CEV_CHECKLIST.map(item => (
              <Grid container direction="row" key={item.desc}>
                <SingleCheckboxGroup
                  item={item}
                  isChecked={isChecked}
                  handleCheck={handleCheck}
                  isLocked={isLocked}
                />
              </Grid>
            ))}
            <Grid className={classes.subtitleBkg}>
              <Typography className={classes.subtitle}>PST</Typography>
            </Grid>
            {BC_PST_CHECKLIST.map(item => (
              <Grid container direction="row" key={item.desc}>
                <SingleCheckboxGroup
                  item={item}
                  isChecked={isChecked}
                  handleCheck={handleCheck}
                  isLocked={isLocked}
                />
              </Grid>
            ))}
          </>
        )}
        {/* ON DEALERS ONLY */}
        {dealer.province === 'ON' && (
          <>
            <Grid className={classes.subtitleBkg}>
              <Typography className={classes.subtitle}>
                Ontario Disclosure
              </Typography>
            </Grid>
            {ON_DISCLOSURE_CHECKLIST.map(item => (
              <Grid container direction="row" key={item.desc}>
                <SingleCheckboxGroup
                  item={item}
                  isChecked={isChecked}
                  handleCheck={handleCheck}
                  isLocked={isLocked}
                />
              </Grid>
            ))}
          </>
        )}
        <Grid className={classes.subtitleBkg}>
          <Typography className={classes.subtitle}>GM/VP Deal Audit</Typography>
        </Grid>
        <TitleGridYesNo />
        {DEAL_AUDIT_CHECKLIST.map(item => (
          <Grid container direction="row" key={item.desc}>
            <YesNoCheckboxGroup
              item={item}
              isChecked={isChecked}
              handleCheck={handleCheck}
              isLocked={isLocked}
            />
          </Grid>
        ))}
        {oemChecklist(dealer.oem).length > 0 && (
          <>
            <Grid className={classes.subtitleBkg}>
              <Typography
                className={classes.subtitle}
              >{`${dealer.oem} Specific`}</Typography>
            </Grid>
            <TitleGridYesNo />
            {oemChecklist(dealer.oem).map(item => (
              <Grid container direction="row" key={item.desc}>
                <YesNoCheckboxGroup
                  item={item}
                  isChecked={isChecked}
                  handleCheck={handleCheck}
                  isLocked={isLocked}
                />
                {dealer.oem === 'Ford' && item.desc === fordPlanDescText && (
                  <Grid item xs={12}>
                    <TextField
                      label="Which plan was used? Example: 'D Plan'"
                      value={fordPlan}
                      onChange={e => setFordPlan(e.target.value)}
                      variant="outlined"
                      style={{ width: '100%', margin: '10px 0' }}
                    />
                  </Grid>
                )}
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default Checklists;
