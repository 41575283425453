import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import { DatePicker } from '@mui/x-date-pickers';

import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import { SelectControl, TextFieldControl } from 'components/MaterialUI';

import { VinValidator } from 'utils';

import { usePermissionsContext } from '../../contexts/PermissionsContext';
import Controller from '../../contexts/PermissionsController';
import TrimSelector from './TrimSelector';

const YEARS_QUERY = gql`
  query getYears {
    inventory {
      years: getYearsDrilldown
    }
  }
`;

const MAKES_QUERY = gql`
  query getMakes($selectedYear: Int!) {
    inventory {
      makes: getMakesDrilldown(year: $selectedYear) {
        name
        id
      }
    }
  }
`;

const MODELS_QUERY = gql`
  query getModels($selectedYear: Int!, $selectedMakeId: Int!) {
    inventory {
      models: getModelsDrilldown(
        year: $selectedYear
        make_id: $selectedMakeId
      ) {
        name
        id
      }
    }
  }
`;

const VehicleDetails = ({
  decodeVehicle,
  decodeVehicleQuery,
  vehicle: {
    displayName,
    vin,
    year,
    make_id,
    model_id,
    style_id,
    is_decoded,
    date_in_stock,
    date_user_modified,
    stock_number,
    options,
    cdk_options,
    stock_type,
  },
}) => {
  const {
    codeTables: { stockStatuses = [], bodyTypes = [], certifiedLines = [] },
    loading: codeTablesLoading,
    error: codeTablesError,
  } = useCodeTablesContext();

  const {
    formState: { errors },
    register,
    setValue,
    watch,
    control,
  } = useFormContext();
  const { update: canUpdate } = usePermissionsContext();

  const currentVin = watch('vin', vin);
  const selectedYear = watch('year', year);
  const selectedMakeId = watch('make_id', make_id);
  const selectedModelId = watch('model_id', model_id);
  const selectedStyleId = watch('style_id', style_id);
  const currentOptions = watch('options', options);
  register('style_id');
  register('manufacturer_code');
  register('trim_variation');
  register('make_name'); // Convenience for CBBDetails.  Remove from data before submit.
  register('model_name');
  register('default_down_payment');

  const yearsQuery = useQuery(YEARS_QUERY);
  const years = yearsQuery.data ? yearsQuery.data.inventory.years : [];

  const [getMakes, makesQuery] = useLazyQuery(MAKES_QUERY, {
    onCompleted: ({ inventory: { makes } }) => {
      if (!makes.map(x => x.id).includes(selectedMakeId))
        setValue('make_id', null);
    },
  });
  const makes = makesQuery.data ? makesQuery.data.inventory.makes : [];
  const [getModels, modelsQuery] = useLazyQuery(MODELS_QUERY, {
    onCompleted: ({ inventory: { models } }) => {
      if (!models.map(x => x.id).includes(selectedModelId))
        setValue('model_id', null);
    },
  });
  const models = modelsQuery.data ? modelsQuery.data.inventory.models : [];

  const [showTrimSelect, setShowTrimSelect] = useState(false);

  useEffect(() => {
    if (selectedYear) getMakes({ variables: { selectedYear } });
  }, [getMakes, selectedYear]);

  useEffect(() => {
    if (selectedYear && selectedMakeId)
      getModels({ variables: { selectedYear, selectedMakeId } });
  }, [getModels, selectedYear, selectedMakeId]);

  useEffect(() => {
    if (selectedMakeId && makes.map(x => x.id).includes(selectedMakeId))
      setValue('make_name', makes.find(x => x.id === selectedMakeId).name);
    else setValue('make_name', '');
  }, [makes, selectedMakeId, setValue]);

  useEffect(() => {
    if (selectedModelId && models.map(x => x.id).includes(selectedModelId))
      setValue('model_name', models.find(x => x.id === selectedModelId).name);
    else setValue('model_name', '');
  }, [models, selectedModelId, setValue]);

  if (codeTablesLoading) return <>Loading Code Tables...</>;
  if (codeTablesError) return <>Error{JSON.stringify(codeTablesError)}</>;

  const decodeResults = decodeVehicleQuery.data?.inventory?.decodeVehicle;
  const decodeStyles = decodeResults ? decodeResults.styles : [];

  const handleClickDecode = () => {
    const validator = new VinValidator(currentVin);
    let optionCodes = [];
    currentOptions.forEach(({ option_code }) => {
      if (option_code) optionCodes.push(option_code);
    });
    if (optionCodes.length === 0 && cdk_options !== null) {
      let tempOptions = cdk_options.split(' ');
      tempOptions.forEach(item => {
        optionCodes.push(item.trim());
      });
    }
    if (!validator.isValid) alert(validator.error);
    else {
      decodeVehicle({
        variables: { vin: currentVin, filters: { OEMOptionCode: optionCodes } },
        notifyOnNetworkStatusChange: true,
      });
    }
  };

  const handleStyleSelect = style => {
    const shouldDirty = true;
    setValue('acode', style.acode, { shouldDirty });
    setValue('body_type_id', style.body_type_id, { shouldDirty });
    setValue('cab_type_id', style.cab_type_id, { shouldDirty });
    setValue('doors', style.doors, { shouldDirty });
    setValue('drive_type_id', style.drive_type_id, { shouldDirty });
    if (style.engines?.length === 1) {
      const engine = style.engines[0];
      setValue('engine_compressor_id', engine.engine_compressor_id, {
        shouldDirty,
      });
      setValue('engine_config_id', engine.engine_config_id, { shouldDirty });
      setValue('engine_cylinders', engine.engine_cylinders, { shouldDirty });
      setValue('engine_litres', engine.engine_litres, { shouldDirty });
      setValue('fuel_type_id', engine.fuel_type_id, { shouldDirty });
    } else; // TODO: show engine selector?  Not sure if we ever get multiple engines per style

    setValue('make_id', style.make_id, { shouldDirty });
    setValue('manufacturer_code', style.manufacturer_code, { shouldDirty });
    setValue('model_id', style.model_id, { shouldDirty });
    setValue('passengers', style.passengers[0], { shouldDirty });
    setValue('style_id', style.style_id, { shouldDirty });
    if (style.transmissions?.length === 1)
      setValue('transmission_id', style.transmissions[0].id, { shouldDirty });
    else; // TODO: show tranny selector?

    setValue('trim_variation', style.trim_variation, { shouldDirty });
    setValue('trim', style.trim || style.trim_variation, {
      shouldDirty,
    });
    setValue('published_trim', style.trim, { shouldDirty });
    if (style.upholstery_ids?.length === 1)
      setValue('upholstery_id', style.upholster_ids[0], { shouldDirty });
    else; // TODO: show upholstery selector?
    setValue('year', style.year, { shouldDirty });
    setValue(
      'equipment',
      style.options.filter(x => !x.option_code),
      { shouldDirty },
    );
    setShowTrimSelect(false);
  };

  return (
    <Paper>
      <Container style={{ marginLeft: '0' }}>
        <Typography style={{ padding: '15px 15px 15px 0' }} variant="h6">
          Vehicle Details
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '45%' }}>
            <FormControl style={{ width: '45%', padding: '0 25px 20px 0' }}>
              <SelectControl
                control={control}
                label="Status"
                error={errors.stock_status_id}
                name="stock_status_id"
                options={stockStatuses}
                optionValueKey="id"
                disabled={!canUpdate}
              />
            </FormControl>
            <TextField
              disabled
              label="Stock Number"
              style={{ width: '45%' }}
              value={stock_number}
            />
            <div style={{ display: 'flex' }}>
              <div style={{ width: '45%', padding: '0 25px 20px 0' }}>
                <DatePicker
                  disabled
                  disableToolbar
                  format="YYYY-MM-DD"
                  id="date-in-stock-picker"
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  label="Date In Stock"
                  margin="normal"
                  style={{
                    width: '100%',
                    marginRight: '0px',
                    marginTop: '0px',
                  }}
                  value={moment(date_in_stock)}
                  variant="inline"
                />
              </div>
              <div style={{ width: '45%' }}>
                <DatePicker
                  disabled
                  disableToolbar
                  format="YYYY-MM-DD"
                  id="date-user-modified-picker"
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  label="Date Modified"
                  margin="normal"
                  style={{
                    width: '100%',
                    marginRight: '0px',
                    marginTop: '0px',
                  }}
                  value={moment(date_user_modified)}
                  variant="inline"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px 20%',
                textAlign: 'left',
              }}
            >
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <FormControlLabel
                    checked={value}
                    control={<Checkbox color="secondary" />}
                    disabled={!canUpdate}
                    label="Loaner"
                    name={name}
                    onChange={e => onChange(e.target.checked)}
                    error={errors.is_loaner}
                    style={{ width: '25%' }}
                  />
                )}
                name="is_loaner"
              />
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <FormControlLabel
                    checked={value}
                    control={<Checkbox color="secondary" />}
                    disabled={!canUpdate}
                    label="Demo"
                    name={name}
                    onChange={e => onChange(e.target.checked)}
                    error={errors.is_demo_unit}
                    style={{ width: '25%' }}
                  />
                )}
                name="is_demo_unit"
              />
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <FormControlLabel
                    checked={value}
                    control={<Checkbox color="secondary" />}
                    disabled={!canUpdate}
                    label="In Transit"
                    name={name}
                    onChange={e => onChange(e.target.checked)}
                    error={errors.is_transit}
                    style={{ width: '25%' }}
                  />
                )}
                name="in_transit"
              />
              {stock_type === 'USED' && (
                <Controller
                  render={({ field: { name, value, onChange } }) => (
                    <FormControlLabel
                      checked={value}
                      control={<Checkbox color="secondary" />}
                      disabled={!canUpdate}
                      label="Recon Complete"
                      name={name}
                      onChange={e => onChange(e.target.checked)}
                      style={{ width: '25%' }}
                    />
                  )}
                  name="recon_complete"
                />
              )}
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <FormControlLabel
                    checked={value}
                    control={<Checkbox color="secondary" />}
                    disabled={!canUpdate}
                    label="Is Featured"
                    name={name}
                    onChange={e => onChange(e.target.checked)}
                    error={errors.is_featured}
                    style={{ width: '25%' }}
                  />
                )}
                name="is_featured"
              />
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <FormControlLabel
                    checked={value}
                    control={<Checkbox color="secondary" />}
                    disabled={!canUpdate}
                    label="Is Certified"
                    name={name}
                    onChange={e => onChange(e.target.checked)}
                    error={errors.is_certified}
                    style={{ width: '25%' }}
                  />
                )}
                name="is_certified"
              />
            </div>
            <span style={{ display: 'flex', padding: '5px 15px 0 0' }}>
              <SelectControl
                control={control}
                displayEmpty
                error={errors.certified_line_id}
                name="certified_line_id"
                label="Certified Line"
                options={certifiedLines}
                nullDisplay="None"
                optionValueKey="id"
                disabled={!canUpdate}
                style={{ width: '90%' }}
              />
            </span>

            <span style={{ display: 'flex', padding: '5px 15px 1.5rem 0' }}>
              <TextFieldControl
                control={control}
                error={errors.video_url}
                label="Youtube URL"
                name="video_url"
                style={{ width: '90%' }}
                disabled={!canUpdate}
              />
            </span>
          </div>
          <div style={{ width: '45%' }}>
            <span
              style={{
                display: 'flex',
                padding: '0 15px 15px 0',
              }}
            >
              <TextFieldControl
                control={control}
                error={errors.vin}
                label="VIN"
                name="vin"
                style={{ width: '65%' }}
                disabled={!canUpdate}
              />
              <Button
                color="secondary"
                onClick={handleClickDecode}
                style={{
                  marginLeft: '7%',
                  height: '100%',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
                variant="contained"
                size="small"
                disabled={!canUpdate || decodeVehicleQuery.loading}
              >
                {decodeVehicleQuery.loading ? (
                  <div
                    style={{
                      marginLeft: '4px',
                      marginRight: '9px',
                      marginTop: '4px',
                      marginBottom: '0px',
                    }}
                  >
                    <CircularProgress size={18} />
                  </div>
                ) : (
                  <>
                    {!is_decoded ? (
                      <StarIcon style={{ marginRight: '5px' }} />
                    ) : (
                      ''
                    )}
                  </>
                )}
                {is_decoded ? <>Re-Decode</> : <>Decode</>}
              </Button>
            </span>
            <span
              style={{
                display: 'flex',
                padding: '0 15px 20px 0',
              }}
            >
              <FormControl style={{ width: '45%' }}>
                {years && years.length > 0 && !yearsQuery.loading && (
                  <SelectControl
                    control={control}
                    error={errors.year}
                    name="year"
                    label="Year"
                    style={{ marginRight: '20px' }}
                    disabled={!canUpdate}
                    options={years.map(year => ({
                      name: year,
                      value: year,
                    }))}
                  />
                )}
              </FormControl>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 15px 20px 0',
              }}
            >
              <FormControl style={{ width: '45%' }}>
                {makes && makes.length > 0 && !makesQuery.loading && (
                  <SelectControl
                    label="Make"
                    control={control}
                    displayEmpty
                    error={errors.make_id}
                    name="make_id"
                    style={{ marginRight: '20px' }}
                    options={makes}
                    optionValueKey="id"
                    disabled={!canUpdate}
                  />
                )}
              </FormControl>
              <FormControl style={{ width: '45%' }}>
                {models && models.length > 0 && !modelsQuery.loading && (
                  <SelectControl
                    control={control}
                    displayEmpty
                    error={errors.model_id}
                    label="Model"
                    name="model_id"
                    style={{ marginRight: '20px' }}
                    options={models}
                    optionValueKey="id"
                    disabled={!canUpdate}
                  />
                )}
              </FormControl>
            </span>
            <span style={{ display: 'flex', padding: '0 15px 20px 0' }}>
              <TextFieldControl
                control={control}
                disabled
                label="Decoded Trim"
                name="trim"
                readOnly
                style={{ width: '40%' }}
                InputLabelProps={{ shrink: true }}
              />
              <Button
                color="grey"
                size="small"
                style={{
                  marginLeft: '7%',
                  height: '30px',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
                variant="contained"
                disabled={decodeStyles.length === 0}
                onClick={() => setShowTrimSelect(true)}
              >
                Change
              </Button>
            </span>
            <span style={{ display: 'flex', padding: '0 15px 20px 0' }}>
              <TextFieldControl
                control={control}
                error={errors.published_trim}
                label="Published Trim"
                name="published_trim"
                style={{ width: '100%' }}
                disabled={!canUpdate}
              />
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 15px 20px 0',
              }}
            >
              <FormControl style={{ width: '45%' }}>
                <SelectControl
                  control={control}
                  displayEmpty
                  error={errors.body_type_id}
                  name="body_type_id"
                  label="Body Type"
                  style={{ marginRight: '20px' }}
                  options={bodyTypes}
                  optionValueKey="id"
                  disabled={!canUpdate}
                />
              </FormControl>
              <TextFieldControl
                control={control}
                error={errors.odometer}
                label="Odometer"
                name={'odometer'}
                style={{ width: '45%' }}
                disabled={!canUpdate}
              />
            </span>
          </div>
        </div>
      </Container>
      <Dialog
        open={showTrimSelect}
        onClose={() => {
          setShowTrimSelect(false);
        }}
      >
        <TrimSelector
          decodeStyles={decodeStyles}
          selectedStyleId={selectedStyleId}
          displayName={displayName}
          handleStyleSelect={handleStyleSelect}
        />
      </Dialog>
    </Paper>
  );
};

// TODO: add fragment for styles here (remove from VehicleInfo)
VehicleDetails.fragments = {
  vehicle: gql`
    fragment VehicleDetailsVehicle on GreaseInventoryVehicle {
      certified_line_id
      cdk_options
      body_type_id
      date_in_stock
      date_user_modified
      default_down_payment
      dealer_id
      displayName
      in_transit
      is_decoded
      is_demo_unit
      is_featured
      is_loaner
      manufacturer_code
      make_id
      model_id
      odometer
      published_trim
      recon_complete
      stock_number
      stock_status_id
      stock_type
      style_id
      trim
      trim_variation
      video_url
      vin
      year
    }
  `,
};

export default VehicleDetails;
