// External
import React, { useState } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import moment from 'moment';

// Material UI
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers';

// Internal
import { ConfirmDialog, SelectControl } from 'components/MaterialUI';

const BlackoutPeriod = ({ canUpdate, timeOptions }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'blackouts',
    keyName: 'key',
  });

  const [confirmRemoveItemIndex, setConfirmRemoveItemIndex] = useState(null);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>Blackouts</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={isMobile ? 3 : 1}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              onClick={() =>
                append({
                  start_date: moment().format('YYYY-MM-DD'),
                  end_date: moment().format('YYYY-MM-DD'),
                  start_time: timeOptions[0],
                  end_time: timeOptions.slice(-1)[0],
                })
              }
              style={canUpdate ? theme.actions.confirm : theme.actions.disabled}
              disabled={!canUpdate}
            >
              Add Blackouts
            </Button>
          </Grid>
          {fields.map((blackout, index) => (
            <Grid item xs={12} key={blackout.key}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={10} md={11}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} md={3}>
                      <Controller
                        control={control}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <DatePicker
                            label="Start Date"
                            autoOk
                            variant="inline"
                            format="YYYY-MM-DD"
                            disabled={!canUpdate}
                            value={moment(value)}
                            {...field}
                            onChange={data =>
                              onChange(data.format('YYYY-MM-DD'))
                            }
                          />
                        )}
                        name={`blackouts.${index}.start_date`} // react-hook-form prefers dot notation instead of []
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SelectControl
                        defaultValue={timeOptions[0]}
                        label="Start Time"
                        name={`blackouts.${index}.start_time`}
                        nullDisplay=""
                        options={timeOptions.map(x => ({ name: x, value: x }))}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Controller
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <DatePicker
                            label="End Date"
                            autoOk
                            variant="inline"
                            format="YYYY-MM-DD"
                            value={moment(value)}
                            disabled={!canUpdate}
                            {...field}
                            onChange={data =>
                              onChange(data.format('YYYY-MM-DD'))
                            }
                          />
                        )}
                        name={`blackouts.${index}.end_date`}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SelectControl
                        defaultValue={timeOptions[0]}
                        label="End Time"
                        name={`blackouts.${index}.end_time`}
                        nullDisplay=""
                        options={timeOptions.map(x => ({ name: x, value: x }))}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} md={1} style={{ margin: 'auto' }}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      blackout.id
                        ? setConfirmRemoveItemIndex(index)
                        : remove(index)
                    }
                    style={{
                      backgroundColor: 'red',
                      minWidth: '36px',
                      padding: '10px',
                    }}
                    disabled={!canUpdate}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <ConfirmDialog
          isOpen={confirmRemoveItemIndex !== null}
          onConfirm={() => {
            remove(confirmRemoveItemIndex);
          }}
          onClose={() => setConfirmRemoveItemIndex(null)}
          text="Are you sure you want to remove that blackout period?"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default BlackoutPeriod;
