import React from 'react';

/* external */
import { camelCase, isEmpty, startCase } from 'lodash';

/* Material UI */
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ascSorter = ({ value: a }, { value: b }) => (a || '').localeCompare(b);
const descSorter = (a, b) => -ascSorter(a, b);
const titleize = str => startCase(camelCase(str));

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    paddingBottom: '10px',
    outlineColor: 'black',
  },
  subPanel: {
    width: '100%',
  },
}));

const FilterDropDown = ({
  createdByMapping,
  createdAtFromFilter,
  createdAtToFilter,
  facetResults,
  selectedFacets,
  setCreatedAtFromFilter,
  setCreatedAtToFilter,
  setSelectedFacets,
  loading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const getLabel = str => createdByMapping[str];

  const FACET_OPTIONS = [
    {
      field: 'appraisalStatus',
      model: 'Appraisal',
      options: {
        format: titleize,
        sort: ascSorter,
      },
      title: 'Status',
    },
    {
      field: 'isPaveSessionComplete',
      model: 'Appraisal',
      title: 'Pave Inspection Completed',
    },
    {
      field: 'year',
      model: 'Appraisal',
      options: {
        nullLabel: 'No Year',
        sort: descSorter,
      },
      title: 'Year',
    },
    {
      field: 'make',
      model: 'Appraisal',
      options: {
        nullLabel: 'No Make',
        sort: ascSorter,
      },
      title: 'Make',
    },
    {
      field: 'model',
      model: 'Appraisal',
      options: {
        nullLabel: 'No Model',
        sort: ascSorter,
      },
      title: 'Model',
    },
    {
      field: 'bodyType',
      model: 'Appraisal',
      options: {
        nullLabel: 'No Body',
        sort: ascSorter,
      },
      title: 'Body Type',
    },
    {
      field: 'buyer',
      model: 'Appraisal',
      options: {
        nullLabel: 'No Buyer',
        sort: ascSorter,
      },
      title: 'Buyer',
    },
    {
      field: 'name',
      model: 'Seller',
      options: {
        nullLabel: 'No Seller',
      },
      title: 'Seller',
    },
    {
      field: 'locatedProvince',
      model: 'Appraisal',
      options: {
        nullLabel: 'Unknown',
      },
      title: 'Located Province',
    },
    {
      field: 'createdBy',
      model: 'Appraisal',
      options: {
        format: getLabel,
        sort: ascSorter,
      },
      title: 'Created By',
    },
    {
      field: 'customerAppraisalSource',
      model: 'Appraisal',
      options: {
        sort: ascSorter,
      },
      title: 'Source',
    },
  ];

  const deleteAllFacets = () => {
    setSelectedFacets([]);
    setCreatedAtFromFilter(null);
    setCreatedAtToFilter(null);
  };

  const getFacetResults = (model, field, options = {}) =>
    facetResults
      .find(x => x.field === field && (!model || x.model === model))
      ?.data.sort(options.sort || (_ => 0))
      .map(({ value, ...rest }) => ({
        value,
        ...rest,
      })) || [];

  const isSelected = (_model, _field, _value) =>
    selectedFacets.findIndex(
      ({ model, field, value }) =>
        model === _model && field === _field && value === _value,
    ) !== -1;

  const deleteFacet = (_model, _field, _value) =>
    setSelectedFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const setFacet = (model, field, value, options) =>
    setSelectedFacets(prev => [...prev, { model, field, value, options }]);

  const toggleFacet = (model, field, value, options) => {
    if (isSelected(model, field, value)) deleteFacet(model, field, value);
    else setFacet(model, field, value, options);
  };

  const showReset =
    !isEmpty(selectedFacets) || createdAtFromFilter || createdAtToFilter;

  return (
    <List className={classes.panel}>
      <Box className={classes.title}>Filters</Box>
      {showReset && (
        <Box style={{ paddingBottom: '10px' }}>
          <Button style={{ color: 'red' }} onClick={deleteAllFacets}>
            <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
          </Button>
        </Box>
      )}
      {FACET_OPTIONS.map(({ field, model, title, options = {} }) => (
        <Box style={{ paddingBottom: '10px' }} key={`${field}${model}${title}`}>
          <Accordion key={title}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {title}
            </AccordionSummary>
            <AccordionDetails>
              <List component="div" disablePadding className={classes.subPanel}>
                {getFacetResults(model, field, options).map(
                  ({ count, value }) => (
                    <>
                      <ListItem
                        disabled={loading}
                        dense
                        button
                        onClick={() =>
                          toggleFacet(model, field, value, options)
                        }
                        key={`${model}${field}${value}`}
                        selected={isSelected(model, field, value)}
                      >
                        {isSelected(model, field, value) ? (
                          <DoneIcon
                            style={{
                              fontSize: 'small',
                              paddingRight: '10px',
                              width: '25px',
                            }}
                          />
                        ) : (
                          <div style={{ paddingRight: '10px', width: '25px' }}>
                            {' '}
                          </div>
                        )}
                        <ListItemText
                          primary={`${
                            (options.format || (x => x))(value) || 'Unknown'
                          } (${count})`}
                        />
                      </ListItem>
                      {model === 'Appraisal' &&
                        field === 'appraisalStatus' &&
                        value === 'OWNED' && (
                          <ListItem
                            dense
                            disabled={loading}
                            style={{ paddingLeft: theme.spacing(5) }}
                            onClick={() =>
                              toggleFacet('Appraisal', 'isBuyNow', 'True', {
                                label: 'Buy Now',
                              })
                            }
                            selected={isSelected(
                              'Appraisal',
                              'isBuyNow',
                              'True',
                            )}
                          >
                            <Checkbox
                              checked={isSelected(
                                'Appraisal',
                                'isBuyNow',
                                'True',
                              )}
                            />
                            <ListItemText
                              primary={`Buy Now (${
                                getFacetResults('Appraisal', 'isBuyNow').find(
                                  ({ value }) => value === 'True',
                                )?.count ?? 0
                              })`}
                            />
                          </ListItem>
                        )}
                    </>
                  ),
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
      <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Created Date
          </AccordionSummary>
          <AccordionDetails>
            <Box flexDirection="column" alignItems="stretch">
              <Box>From</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setCreatedAtFromFilter(date)}
                  value={createdAtFromFilter}
                  variant="dialog"
                />
              </Box>
              <Box>To</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setCreatedAtToFilter(date)}
                  value={createdAtToFilter}
                  variant="dialog"
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </List>
  );
};

export default FilterDropDown;
