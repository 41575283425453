import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import {
  GENERATE_2_CAR_DEAL_PDF,
  GENERATE_FIRST_NATIONS_PDF,
} from './PrevAccountingChecklistModal';

import CustomerInfo from './CustomerInfo';
import Checklists from './Checklists';

import { DEALERS_LIST } from './const';

const GET_CHECKLIST_DETAILS = gql`
  query getChecklistData($_id: ID!) {
    opportunity(_id: $_id) {
      _id
      accounting_checklist_fields {
        checked_fields
        ford_plan
        program_name
      }
    }
  }
`;

const GENERATE_PRINTABLE_PDF = gql`
  mutation generateDeallogPDF($id: ID!, $pdf_type: String!) {
    generateDeallogPDF(_id: $id, pdf_type: $pdf_type) {
      key
    }
  }
`;

const GENERATE_ACCOUNTING_PDF = gql`
  mutation generateAccountingPDFV2(
    $id: ID!
    $fields: AccountingChecklistV2!
    $dealerInfo: DealerInfo!
  ) {
    generateAccountingPDFV2(
      _id: $id
      fields: $fields
      dealerInfo: $dealerInfo
    ) {
      key
    }
  }
`;

const AccountingChecklistModal = ({ opportunity, onClose, isOpen, trades }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [fields, setFields] = useState([]);
  // All checklists have a program name textfield
  const [programName, setProgramName] = useState('');
  // Ford checklists have a textfield
  const [fordPlan, setFordPlan] = useState('');

  const [fetchData, { data, loading }] = useLazyQuery(GET_CHECKLIST_DETAILS, {
    variables: { _id: opportunity._id },
    onCompleted: d => {
      setFields(
        d?.opportunity?.accounting_checklist_fields.checked_fields ?? [],
      );
      setProgramName(
        d?.opportunity?.accounting_checklist_fields.program_name ?? '',
      );
      setFordPlan(d?.opportunity?.accounting_checklist_fields.ford_plan ?? '');
    },
  });

  const [generatePDF, generatePDFMutation] = useMutation(
    GENERATE_PRINTABLE_PDF,
  );

  const [accountingPDF, accountPDFMutation] = useMutation(
    GENERATE_ACCOUNTING_PDF,
  );

  const [generate2CarDealPDFMutation] = useMutation(GENERATE_2_CAR_DEAL_PDF);

  const [generateFirstNationsPDFMutation] = useMutation(
    GENERATE_FIRST_NATIONS_PDF,
  );

  const dealerInfo = DEALERS_LIST[opportunity.dealer_id] || {};

  const generateAccountingPDF = (id, fields) => {
    const formFields = {
      checked_fields: fields,
      program_name: programName,
      ford_plan: fordPlan,
    };
    accountingPDF({ variables: { id, fields: formFields, dealerInfo } }).then(
      ({ data }) => window.open(data.generateAccountingPDFV2.key, '_blank'),
      error =>
        enqueueSnackbar(`Error generating PDF: ${JSON.stringify(error)}`, {
          variant: 'error',
        }),
    );
  };

  const generate2CarDealPDF = () => {
    generate2CarDealPDFMutation().then(
      ({ data }) => window.open(data.generate2CarDealPolicyPDF.key, '_blank'),
      error =>
        enqueueSnackbar(`Error generating PDF: ${JSON.stringify(error)}`, {
          variant: 'error',
        }),
    );
  };

  const generateFirstNationsPDF = () => {
    generateFirstNationsPDFMutation().then(
      ({ data }) =>
        window.open(data.generateFirstNationsGSTExemptPDF.key, '_blank'),
      error =>
        enqueueSnackbar(`Error generating PDF: ${JSON.stringify(error)}`, {
          variant: 'error',
        }),
    );
  };

  // Only fetch if we don't have data
  if (isOpen && data === undefined && !loading) {
    fetchData();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle>
        <Typography variant="h6">
          Deal #{opportunity.dms_deal?.deal_number || 'N/A'}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          // TODO: This is a hack to fix the padding issue
          // when upgrading to MUI v5, it broke the padding. This is a temporary fix
          paddingTop: '24px !important',
        }}
      >
        <LoadingBackdrop
          open={
            generatePDFMutation.loading || accountPDFMutation.loading || loading
          }
        >
          Generating PDF
        </LoadingBackdrop>
        <Grid container spacing={3}>
          <CustomerInfo opportunity={opportunity} />
          <Checklists
            opportunity={opportunity}
            trades={trades}
            generatePDF={generatePDF}
            fields={fields}
            setFields={setFields}
            programName={programName}
            setProgramName={setProgramName}
            fordPlan={fordPlan}
            setFordPlan={setFordPlan}
            dealer={dealerInfo}
            generate2CarDealPDF={generate2CarDealPDF}
            generateFirstNationsPDF={generateFirstNationsPDF}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Close
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={() => generateAccountingPDF(opportunity._id, fields)}
          variant="contained"
          color="primary"
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountingChecklistModal;
