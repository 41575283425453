import React, { useEffect } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import gql from 'graphql-tag';

/* Material UI */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';

/* internal */
import { errorHandler } from 'modules/pitches/utils';
import { INSPECTION_TYPE_OPTIONS } from 'modules/pitches/const';
import { CheckboxControl, SelectControl } from 'components/MaterialUI';

const DEALER_SETTINGS_FRAGMENT = gql`
  fragment DealerSettingsFormDialogDealerSettings on PitchDealerSetting {
    dealerId
    defaultURL
    docusignBrandId
    includeHistoryReport
    inspectionType
    smsGatewayId
    smsGatewaySecretKey
  }
`;

const CREATE_DEALER_SETTINGS = gql`
  mutation createDealerSettings($dealerSettings: PitchDealerSettingInput!) {
    pitches {
      createDealerSettings(dealerSettings: $dealerSettings) {
        id
        ...DealerSettingsFormDialogDealerSettings
      }
    }
  }
  ${DEALER_SETTINGS_FRAGMENT}
`;

const UPDATE_DEALER_SETTINGS = gql`
  mutation updateDealerSettings(
    $dealerId: Int!
    $data: PitchDealerSettingUpdate!
  ) {
    pitches {
      updateDealerSettings(dealerId: $dealerId, data: $data) {
        id
        ...DealerSettingsFormDialogDealerSettings
      }
    }
  }
  ${DEALER_SETTINGS_FRAGMENT}
`;

const DealerSettingsFormDialog = ({
  dealerSettings,
  onClose,
  onCreate,
  gatewayOptions,
  ...rest
}) => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset, setError } = useForm();
  const [createDealerSettings] = useMutation(CREATE_DEALER_SETTINGS);
  const [updateDealerSettings] = useMutation(UPDATE_DEALER_SETTINGS);

  useEffect(() =>
    // reset form when dialog opens
    {
      if (dealerSettings) {
        const { id, __typename, dealerId, ..._dealerSettings } = dealerSettings;
        reset(_dealerSettings);
      }
    }, [reset, dealerSettings]);

  const onSubmit = data =>
    dealerSettings?.id
      ? updateDealerSettings({ variables: { dealerId, data } }).then(
          onClose,
          errorHandler(enqueueSnackbar, setError),
        )
      : createDealerSettings({
          variables: { dealerSettings: { dealerId, ...data } },
        }).then(onCreate, errorHandler(enqueueSnackbar, setError));

  return (
    <Dialog open={Boolean(dealerSettings)} {...rest}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Dealer Settings</DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            defaultValue={null}
            name="defaultURL"
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={error?.message ?? 'Including http://'}
                style={{ width: '350px' }}
                label="Default URL"
                value={value ?? ''}
                onChange={e =>
                  onChange(e.target.value === '' ? null : e.target.value)
                }
              />
            )}
          />
          <br />

          <Controller
            control={control}
            defaultValue={null}
            name="docusignBrandId"
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                style={{ width: '350px' }}
                label="Docusign Brand ID"
                value={value ?? ''}
                onChange={e =>
                  onChange(e.target.value === '' ? null : e.target.value)
                }
              />
            )}
          />
          <br />

          <Controller
            control={control}
            defaultValue={null}
            name="smsGatewayId"
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <TextField
                {...field}
                select
                error={Boolean(error)}
                helperText={error?.message}
                label="SMS Gateway"
                style={{ width: '350px' }}
                value={value ?? ''}
                onChange={e =>
                  onChange(e.target.value === '' ? null : e.target.value)
                }
              >
                <MenuItem key={0} value="">
                  &nbsp;
                </MenuItem>
                {gatewayOptions.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <br />

          <Controller
            control={control}
            defaultValue={null}
            name="smsGatewaySecretKey"
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                style={{ width: '350px' }}
                label="Gateway Secret Key"
                value={value ?? ''}
                onChange={e =>
                  onChange(e.target.value === '' ? null : e.target.value)
                }
              />
            )}
          />
          <br />

          <SelectControl
            style={{ width: '350px' }}
            options={INSPECTION_TYPE_OPTIONS}
            name="inspectionType"
            control={control}
            nullDisplay="Use Default"
            label="Inspection Type"
          />

          <br />
          <CheckboxControl
            control={control}
            style={{ width: '350px' }}
            name="includeHistoryReport"
            label="Include VHR"
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DealerSettingsFormDialog.fragments = {
  dealerSettings: DEALER_SETTINGS_FRAGMENT,
};

export default DealerSettingsFormDialog;
