import gql from 'graphql-tag';
import React from 'react';

/* external */
import { useFormContext, useWatch } from 'react-hook-form';

/* Material UI */
import Box from '@mui/material/Box';

import { SelectControl, TextFieldControl } from 'components/MaterialUI';

/* internal */
import { ISO_DAYS_OF_WEEK } from 'modules/auctions/const';

const WeeklyAuctionForm = () => {
  const { control, register } = useFormContext();

  const isWeekly = useWatch({ control, name: 'isWeekly' });
  register('weekly', { shouldUnregister: true });

  if (!isWeekly) return null;

  const required = 'This is required'; // Default error message

  return (
    <Box display="flex" flexDirection="column">
      <Box margin={1}>
        <TextFieldControl
          label="Must Auction Days"
          name="weekly.mustAuctionDays"
          defaultValue={120}
          rules={{ required }}
          required
          type="number"
        />
      </Box>
      <Box margin={1}>
        <SelectControl
          defaultValue={ISO_DAYS_OF_WEEK[0].value}
          label="Start Day"
          name="weekly.startDay"
          options={ISO_DAYS_OF_WEEK}
          noNull
        />
      </Box>

      <Box margin={1}>
        <TextFieldControl
          defaultValue="12:00"
          inputProps={{ step: 60 }}
          label="Start Time"
          name="weekly.startTime"
          required
          rules={{ required }}
          type="time"
        />
      </Box>
      {/* TODO: MPAUC-94: Would be nice to be able to set these as a 
      time + number of days.  If that's too difficult, we should at least display
      the resulting times (with + how many days) from whatever these settings are */}
      <Box margin={1}>
        <TextFieldControl
          defaultValue=""
          inputProps={{ min: 2 }}
          label="Add Items Minutes"
          name="weekly.addItemsMinutes"
          required
          rules={{ required }}
          type="number"
        />
      </Box>
      <Box margin={1}>
        <TextFieldControl
          defaultValue=""
          inputProps={{ min: 1 }}
          label="Blind Bid Minutes"
          name="weekly.blindBidMinutes"
          required
          rules={{ required }}
          type="number"
        />
      </Box>
      <Box margin={1}>
        <TextFieldControl
          defaultValue=""
          inputProps={{ min: 1 }}
          label="Duration Minutes"
          name="weekly.durationMinutes"
          required
          rules={{ required }}
          type="number"
        />
      </Box>
    </Box>
  );
};

WeeklyAuctionForm.fragments = {
  auction: gql`
    fragment WeeklyAuctionFormAuction on Auction {
      weekly {
        id
        addItemsMinutes
        blindBidMinutes
        durationMinutes
        mustAuctionDays
        startDay
        startTime
      }
    }
  `,
};
export default WeeklyAuctionForm;
