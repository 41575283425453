/* external */
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { map } from 'lodash';
import moment from 'moment';

/* MUI */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers';

/* internal */
import {
  RadioControl,
  SelectControl,
  TextFieldControl,
} from 'components/MaterialUI';

const UPDATE_BULK_DETAILED_PRICING = gql`
  mutation createBulkDetailedPricing(
    $filters: [QueryFilter!]!
    $detailedPricing: [NpvBulkDetailedPricingInput!]!
  ) {
    npv {
      updateBulkDetailedPricing(
        filters: $filters
        detailedPricing: $detailedPricing
      ) {
        id
      }
    }
  }
`;

const NO_REGULAR_PRICE_QUERY = gql`
  query noRegularPrice($filters: [QueryFilter], $sort: [QuerySortElement]) {
    npv {
      getVehicles(filters: $filters, sort: $sort) {
        results {
          stockNumber
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: '10px',
    overflowY: 'visible',
    '& .MuiDialogContent-root': {
      overflowY: 'visible',
    },
    '& .MuiDialog-paper': {
      overflowY: 'visible',
      padding: '10px',
    },
  },
  greenButton: {
    color: 'white',
    backgroundColor: theme.actions.confirm.backgroundColor,
  },
  disabledButton: {
    color: theme.colours.greys.greytext2,
    backgroundColor: theme.colours.greys.grey2,
  },
}));

const BulkNpvMultiLinePricing = ({
  ids,
  open,
  onClose,
  selectByFilter,
  vehicleFilters,
  refetch,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogWidth, setDialogWidth] = useState(false);

  // FORM STUFF
  const methods = useForm({
    defaultValues: {},
    shouldUnregister: true,
  });

  const { handleSubmit, control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bulkDetailedPricing',
    shouldUnregister: true,
  });

  // FILTERS
  const baseFilters = selectByFilter
    ? vehicleFilters
    : [
        {
          model: 'Vehicle',
          field: 'id',
          op: 'in',
          value: ids,
        },
      ];

  const noRegPriceFilters = [
    ...baseFilters,
    {
      or: [
        {
          model: 'Vehicle',
          field: 'regularPrice',
          op: 'is_null',
        },
        {
          model: 'Vehicle',
          field: 'regularPrice',
          op: '<=',
          value: 0,
        },
      ],
    },
  ];

  // MUTATION & QUERY
  const [update, updateStatus] = useMutation(UPDATE_BULK_DETAILED_PRICING, {
    onCompleted: data => {
      onClose();
      refetch();
      enqueueSnackbar(
        `Successfully updated ${data.npv.updateBulkDetailedPricing.length} vehicles`,
        { variant: 'success' },
      );
    },
    onError: error =>
      enqueueSnackbar(`Error updating vehicles: ${error.message}`, {
        variant: 'error',
      }),
  });

  const { data: noRegPriceData, loading: checkingForRegPrice } = useQuery(
    NO_REGULAR_PRICE_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      skip: !open,
      variables: {
        filters: [...noRegPriceFilters],
        sort: [
          {
            model: 'Vehicle',
            field: 'stockNumber',
            direction: 'asc',
          },
        ],
      },
    },
  );

  const vehiclesNoRegPrice = noRegPriceData?.npv?.getVehicles?.results || [];

  const filters = [...baseFilters];

  const onSubmit = data => {
    const detailedPricing = data.bulkDetailedPricing.map(
      ({ paymentType, expiry, showOnWebsite, ...rest }) => ({
        ...rest,
        expiry: expiry ? moment(expiry).format('YYYY-MM-DD') : null,
        allowCash: paymentType === 'BOTH' || paymentType === 'CASH',
        allowFinance: paymentType === 'BOTH' || paymentType === 'FINANCE',
        showOnWebsite: showOnWebsite === 'true' || showOnWebsite === true,
      }),
    );
    update({
      variables: {
        filters,
        detailedPricing,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={dialogWidth === false ? 'sm' : 'md'}
      className={classes.dialog}
    >
      <DialogTitle>{'Set Bulk Vehicle Pricing'}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {checkingForRegPrice && (
            <>
              <CircularProgress size={20} />
              Verifying selected vehicles' regular prices.
            </>
          )}
          {vehiclesNoRegPrice.length > 0 && (
            <Alert severity="warning" style={{ marginBottom: '10px' }}>
              The following vehicles are missing a regular price and will not be
              updated. Stock numbers:{' '}
              {map(vehiclesNoRegPrice, 'stockNumber').join(', ')}
            </Alert>
          )}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              Multi-Line Pricing
            </Typography>
            <Fab
              size="small"
              onClick={() => {
                setDialogWidth(true);
                append({
                  priceModifierTypeName: 'addon',
                  priceDescription: '',
                  value: 0,
                  expiry: null,
                  paymentType: 'BOTH',
                  showOnWebsite: 'true',
                });
              }}
              className={classes.greenButton}
              style={{
                display: 'flex',
                margin: '0 0 0 20px',
              }}
            >
              <AddIcon />
            </Fab>
          </Box>
          {fields.map((item, index) => (
            <Grid
              container
              key={item.id}
              alignItems="center"
              style={{ marginBottom: '20px' }}
            >
              <Grid container item xs={11} spacing={2}>
                {/* ROW 1 */}
                <Grid container item xs={12} spacing={2} alignItems="flex-end">
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <SelectControl
                        control={control}
                        noNull
                        variant="outlined"
                        name={`bulkDetailedPricing.${index}.priceModifierTypeName`}
                        options={[
                          { name: 'Add-on', value: 'addon' },
                          { name: 'Discount', value: 'discount' },
                        ]}
                        label="Type"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name={`bulkDetailedPricing.${index}.expiry`}
                      render={({ field: { ref, ...field } }) => (
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="YYYY-MM-DD"
                          label="Expiry"
                          inputVariant="outlined"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldControl
                      control={control}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      label="Value"
                      name={`bulkDetailedPricing.${index}.value`}
                      type="number"
                      inputProps={{ min: 0, step: '0.01' }}
                      defaultValue={item.value}
                      muiVariant="outlined"
                    />
                  </Grid>
                </Grid>
                {/* ROW 2 */}
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <TextFieldControl
                      control={control}
                      defaultValue={item.priceDescription}
                      label="Name"
                      name={`bulkDetailedPricing.${index}.priceDescription`}
                      muiVariant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <RadioControl
                      control={control}
                      label="Payment Type"
                      name={`bulkDetailedPricing.${index}.paymentType`}
                      as={
                        <RadioGroup row>
                          <FormControlLabel
                            value="BOTH"
                            control={<Radio color="secondary" />}
                            label="Both"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="CASH"
                            control={<Radio color="secondary" />}
                            label="Cash"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="FINANCE"
                            control={<Radio color="secondary" />}
                            label="Finance"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <RadioControl
                      control={control}
                      label="Show on Website?"
                      name={`bulkDetailedPricing.${index}.showOnWebsite`}
                      as={
                        <RadioGroup row>
                          <FormControlLabel
                            value="true"
                            control={<Radio color="secondary" />}
                            label="Yes"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={1}>
                <Fab
                  size="small"
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    marginLeft: '20px',
                  }}
                  onClick={() => {
                    remove(index);
                    if (fields.length <= 1) {
                      setDialogWidth(false);
                    }
                  }}
                >
                  <DeleteIcon />
                </Fab>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            Cancel
          </Button>
          {updateStatus.loading ? (
            <CircularProgress
              size={20}
              style={{ marginLeft: '40px', marginRight: '30px' }}
            />
          ) : (
            <Button
              autoFocus
              disabled={fields.length <= 0}
              variant="contained"
              type="submit"
              className={
                fields.length === 0
                  ? classes.disabledButton
                  : classes.greenButton
              }
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BulkNpvMultiLinePricing;
