/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { every } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { formatPrice } from 'utils';

import {
  GOCARD_GIFT_CODES,
  GOCARD_REDEEM_CODES,
  RV_DEALER_IDS,
} from 'constants.js';

const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: createTransactionInput!) {
    gocard {
      createTransaction(input: $input) {
        transactionId
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  dropDown: {
    width: '45%',
  },
  mobileDropDown: {
    width: '70%',
  },
}));

const GiftPointsModal = ({ gocard, isOpen, onClose, locations, refetch }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [amount, setAmount] = useState(0);
  const [desc, setDesc] = useState('');
  const [code, setCode] = useState(GOCARD_GIFT_CODES.gift.code);
  const [errors, setErrors] = useState({
    dealerSelect: true,
    amountSelect: true,
    descSelect: true,
  });

  const LARGE_GIFT_THRESHOLD = 499;

  // State for confirming large gift amounts
  const [confirmText, setConfirmText] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [createTransaction, { loading }] = useMutation(CREATE_TRANSACTION, {
    onCompleted: e => {
      enqueueSnackbar('Transaction completed successfully!', {
        variant: 'success',
      });
      onClose();
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Error completing transaction: ${e}`, {
        variant: 'error',
      }),
  });

  const amountCheck = input => input < 0;

  const dealerChange = event => {
    setErrors({ ...errors, dealerSelect: event.target.value === null });
    setSelectedIndex(event.target.value);
  };

  const amountChange = event => {
    const value = event.target.value;
    setErrors({ ...errors, amountSelect: amountCheck(value) });
    setAmount(value);

    // Reset confirmation if amount is adjusted below LARGE_GIFT_THRESHOLD
    if (value <= LARGE_GIFT_THRESHOLD) {
      setConfirmText('');
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
  };

  const descChange = event => {
    setErrors({ ...errors, descSelect: event.target.value === '' });
    setDesc(event.target.value);
  };

  const codeChange = event => setCode(event.target.value);

  const handleConfirmTextChange = event => {
    const text = event.target.value;
    setConfirmText(text);

    const requiredText = 'confirm';

    // Dynamic confirmation phrase check
    if (text === requiredText) {
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
  };

  const selectedLocation = selectedIndex ? locations[selectedIndex] : {};
  const isRVDealer = RV_DEALER_IDS.includes(selectedLocation?.dealerId);

  const isFalse = bool => {
    return bool === false;
  };

  const onSubmit = () => {
    // Validation before submitting
    const isValid = every(errors, isFalse);
    const payload = {
      cardId: gocard.cardId,
      locationId: locations[selectedIndex].locationId,
      amount: Number(amount),
      code: isRVDealer ? code : GOCARD_GIFT_CODES.gift.code,
      description: desc,
    };
    if (!isValid || (amount > LARGE_GIFT_THRESHOLD && !isConfirmed)) {
      enqueueSnackbar('Missing required fields or confirmation', {
        variant: 'error',
      });
    } else {
      createTransaction({
        variables: { input: payload },
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Gift Points</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          This will add points to the customer's account
        </Alert>
      </DialogContent>
      <DialogContent>
        <FormControl
          className={desktop ? classes.dropDown : classes.mobileDropDown}
          error={errors.dealerSelect}
        >
          <InputLabel>Location</InputLabel>
          <Select value={selectedIndex} onChange={dealerChange}>
            {locations.map((location, index) => (
              <MenuItem key={location.locationId} value={index}>
                {location.locationName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isRVDealer && (
          <FormControl
            className={desktop ? classes.dropDown : classes.mobileDropDown}
            style={{ marginLeft: '5px' }}
          >
            <InputLabel>Gift Code</InputLabel>
            <Select value={code} onChange={codeChange}>
              {Object.values(GOCARD_REDEEM_CODES).map(gift => (
                <MenuItem value={gift.code}>{gift.text}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogContent>
        <FormControl
          className={desktop ? classes.dropDown : classes.mobileDropDown}
          error={errors.amountSelect}
        >
          <InputLabel>Amount</InputLabel>
          <Input
            value={amount}
            type="number"
            onChange={amountChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        {amount > LARGE_GIFT_THRESHOLD && (
          <FormControl fullWidth>
            <TextField
              label="Large gift confirmation"
              placeholder={`Type 'confirm' if you are sure you want to gift this customer ${formatPrice(
                amount,
                { cents: true },
              )}`}
              value={confirmText}
              onChange={handleConfirmTextChange}
              error={!isConfirmed}
              helperText={
                !isConfirmed
                  ? `You must type 'confirm' to gift this customer ${formatPrice(
                      amount,
                      { cents: true },
                    )}`
                  : 'Looks good!'
              }
            />
          </FormControl>
        )}
        <FormControl fullWidth>
          <TextField
            label="Description"
            value={desc}
            onChange={descChange}
            multiline
            maxRows={2}
            error={errors.descSelect}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={theme.actions.close} onClick={onClose} variant="text">
          Close
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
          disabled={amount > LARGE_GIFT_THRESHOLD && !isConfirmed} // Disable based on confirmation
        >
          Save
        </Button>
      </DialogActions>
      <LoadingBackdrop open={loading}>Gifting Points...</LoadingBackdrop>
    </Dialog>
  );
};

export default GiftPointsModal;
